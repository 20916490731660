import { db } from "@/common/services/firebase";
import { VEICULO_COLLECTION } from "@/common/defs/collectionNames";
import { DocumentReference, doc } from "firebase/firestore";
import { IfirestoreObject, Imeta } from "@/common/services/IfirestoreObject";

export const classes = ["Táxi", "A", "T", "Turismo"] as const;
export const tipoDeVeiculo = ["Sedan", "Van", "Minibus", "Bus"] as const;

export type TipoDeVeiculo = (typeof tipoDeVeiculo)[number];
type Classes = (typeof classes)[number];

interface IveiculoInfo {
  marca: string;
  modelo: string;
  matricula: string;
  dataMatricula: string;
  tipo: TipoDeVeiculo;
}

export default class Veiculo implements IfirestoreObject {
  id?: string;

  //=============== BASE ======================\\
  info: IveiculoInfo = {
    marca: null,
    modelo: null,
    matricula: null,
    dataMatricula: null,
    tipo: null,
  };

  status: {
    active: boolean;
  } = { active: true };

  //=============== LvL 1======================\\
  tipo: {
    nome: string;
    classe: Classes;
  } = { nome: null, classe: null };

  stats: {
    kms: number;
    bandeirada: number;
  } = {
    kms: 0,
    bandeirada: 0,
  };

  //=============== Lvl 2======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  hasTaximetro() {
    if (this.tipo.classe === "Táxi" || this.tipo.classe === "A") return true;
    else return false;
  }

  setData(json: any) {
    this.info = {
      marca: json?.info?.marca ?? null,
      modelo: json?.info?.modelo ?? null,
      matricula: json?.info?.matricula ?? null,
      dataMatricula: json?.info?.dataMatricula ?? null,
      tipo: json?.info?.tipo ?? null,
    };
    this.status = json.status;
  }

  setLvl1(json: any) {
    this.tipo = json.tipo;
    this.stats = json.stats ?? { kms: 0, bandeirada: 0 };
  }

  setLvl2(json: any) {
    this.meta = {
      createdAt: json.meta.createdAt ? json.meta.createdAt.toDate() : null,
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      info: this.info,
      status: this.status,
      tipo: this.tipo,
      stats: this.stats,
      meta: this.meta,
    };
    return res;
  }

  /**
   * Minimiza o objecto / retorna só a info a duplicar noutro objecto na Firestore
   */
  minimizedMini() {
    return {
      id: this.id,
      info: {
        matricula: this.info.matricula,
        tipo: this.info.tipo,
      },
      tipo: {
        nome: this.tipo.nome,
        classe: this.tipo.classe,
      },
    };
  }

  getdocRef(): DocumentReference {
    return doc(db, VEICULO_COLLECTION, this.id);
  }
}

import { SERVICO_LVL2, SERVICO_LVL1, HISTORICO_COLLECTION, SERVICE_STATS } from "@/common/defs/collectionNames";
import { Query, DocumentReference, collection, getDocs, query, orderBy, addDoc } from "firebase/firestore";
import { db } from "@/common/services/firebase";

import Servico from "@/modules/servicos/types/Servico";
import { SERVICO_COLLECTION } from "@/common/defs/collectionNames";

import { getMeta } from "@/common/services/IfirestoreObject";
import HistoricoItem from "@/common/components/historico/HistoricoItem";

import DBOperations from "@/common/services/DBOperations";

const state: any = {};

export const dbOperations = new DBOperations("Servico", {
  metaCollection: SERVICO_LVL2,
  lvl1Name: SERVICO_LVL1,
  lvl2Name: SERVICO_LVL2,
  statsName: SERVICE_STATS,
});

const getters = {};

const mutations = {
  clearStore(state: any): void {
    state.servicos = [];
  },
};

interface IGetPayload {
  query: Query | DocumentReference;
  ref?: { item: Servico } | Servico[];
}

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Servico } | { item: Servico[] };
}

async function processLvlListener(context: any, payload: IListenerPayload, lvl: string) {
  const listener = await dbOperations.getListenerLvl(payload.ref, payload.query, lvl);
  context.commit("addListener", listener, { root: true });
  return listener;
}

const actions = {
  /**
   * Get de uma só vez os documentos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsBase(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsBase(payload.ref, payload.query);
  },

  /**
   * Get de uma só vez os LVL1 dos documetos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsLvl1(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsLvl(payload.ref, payload.query, "lvl1");
  },

  /**
   * Get de uma só vez os LVL2 dos documetos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsLvl2(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsLvl(payload.ref, payload.query, "lvl2");
  },

  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerBase(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerLvl1(context: any, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl1");
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerLvl2(context: any, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl2");
  },

  /**
   * Get um listener para a query fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerStats(context: any, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "stats");
  },

  /**
   * Loga quando alguem vê o contacto do passageiro
   */
  async logContactoView(context: any, payload: { idServico: string }): Promise<void> {
    const historicoItem = new HistoricoItem();
    historicoItem.setLog("O contacto do passageiro foi visualizado");
    historicoItem.meta = getMeta(context.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    addDoc(collection(db, SERVICO_COLLECTION, payload.idServico, HISTORICO_COLLECTION), historicoItem.minimized());
  },

  /**
   * Loga quando alguem partilha o serviço com o clipboard
   */
  async logShareDB(context: any, payload: { idServico: string }): Promise<void> {
    const historicoItem = new HistoricoItem();
    historicoItem.setLog("O serviço foi partilhado pelo clipboard");
    historicoItem.meta = getMeta(context.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    addDoc(collection(db, SERVICO_COLLECTION, payload.idServico, HISTORICO_COLLECTION), historicoItem.minimized());
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, id: string) {
    const res: HistoricoItem[] = [];
    const q = collection(db, SERVICO_COLLECTION, id, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

import { db } from "@/common/services/firebase";
import { doc, DocumentReference } from "firebase/firestore";
import { MORADA_COLLECTION } from "@/common/defs/collectionNames";
import { IfirestoreObject, Imeta, covertTimestamp } from "@/common/services/IfirestoreObject";

export interface MoradaMapa {
  linha1: string;
  linha2: string;
  linha3: string;
  codigoPostal: string;
  localidade: string;
  pais: string;
  pos: { lat: number; lng: number };
}

export default class Morada implements IfirestoreObject {
  id?: string;

  //=============== BASE ======================\\
  info: MoradaMapa = {
    linha1: null,
    linha2: null,
    linha3: null,
    codigoPostal: null,
    localidade: null,
    pais: "Portugal",
    pos: { lat: null, lng: null },
  };

  correspondencia?: {
    isMoradaFaturacao: boolean;
    isMoradaCorrespondencia: boolean;
  } = { isMoradaCorrespondencia: false, isMoradaFaturacao: false };

  status: {
    active: boolean;
  } = { active: true };

  stats: {
    nUsos: number;
    idsFreq: { [key: string]: number };
  } = {
    nUsos: 0,
    idsFreq: {},
  };

  //=============== Lvl1 ======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  setData(json: any) {
    this.info = {
      linha1: json.info.linha1,
      linha2: json.info.linha2,
      linha3: json.info.linha3,
      codigoPostal: json.info.codigoPostal,
      localidade: json.info.localidade,
      pais: json.info.pais,
      pos: json.info.pos,
    };

    this.correspondencia = json.correspondencia
      ? json.correspondencia
      : { isMoradaCorrespondencia: false, isMoradaFaturacao: false };

    this.status = json.status ? json.status : { active: true };
    this.stats = json.stats
      ? json.stats
      : {
          nUsos: 0,
          idsFreq: {},
        };
  }

  setLvl1(json: any) {
    this.meta = {
      createdAt: covertTimestamp(json.meta.createdAt),
      createdBy: json.meta.createdBy,
    };
  }

  toString() {
    let str = "";
    if (this.info.linha1) str += `${this.info.linha1}, `;
    if (this.info.linha2) str += `${this.info.linha2}, `;
    if (this.info.linha3) str += `${this.info.linha3}, `;
    if (this.info.localidade) str += `${this.info.localidade}`;
    return str;
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      info: {
        linha1: this.info.linha1,
        linha2: this.info.linha2,
        linha3: this.info.linha3,
        codigoPostal: this.info.codigoPostal,
        localidade: this.info.localidade,
        pais: this.info.pais,
        pos: this.info.pos,
      },

      correspondencia: this.correspondencia,
      status: this.status,
      stats: this.stats,
      meta: this.meta,
    };
    return res;
  }

  /**
   * Minimiza o objecto / retorna só a info a duplicar noutro objecto na Firestore
   */
  minimizedMini() {
    return {
      id: this.id ? this.id : null,
      info: {
        linha1: this.info.linha1,
        linha2: this.info.linha2,
        linha3: this.info.linha3,
        codigoPostal: this.info.codigoPostal,
        localidade: this.info.localidade,
        pais: this.info.pais,
        pos: this.info.pos,
      },
      correspondencia: this.correspondencia,
    };
  }

  getdocRef(): DocumentReference {
    return doc(db, MORADA_COLLECTION, this.id);
  }
}

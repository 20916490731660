//Collection names from firestore
export const LOG_COLLECTION = "logs";

//SHARED
export const HISTORICO_COLLECTION = "historico";
export const PRIVATE_COLLECTION = "dados";

//Parceiro
export const PARCEIRO_COLLECTION = "parceiros";
export const PARCEIRO_LVL1 = "parceiroLvl1";

//Serviço
export const SERVICO_COLLECTION = "servicos";
export const SERVICO_LVL1 = "servicoLvl1";
export const SERVICO_LVL2 = "servicoLvl2";
export const SERVICE_STATS = "serviceStats";

//Grupos
export const GRUPO_COLLECTION = "grupos";
export const GRUPO_LVL1 = "grupoLvl1";

//Clientes
export const CLIENTE_COLLECTION = "clientes";
export const CLIENTE_LVL1 = "clienteLvl1";
export const CLIENTE_LVL2 = "clienteLvl2";
export const PAGAMENTO_COLLECTION = "pagamentos";
export const PAGAMENTO_LVL1 = "pagamentoLvl1";
export const CONSULTA_COLLECTION = "consultas";
export const CONSULTA_LVL1 = "consultaLvl1";

//Utilizadores
export const UTILIZADOR_COLLECTION = "utilizadores";
export const UTILIZADOR_LVL1 = "utilizadorLvl1";
export const UTILIZADOR_LVL2 = "utilizadorLvl2";

export const TURNO_COLLECTION = "turnos";
export const TURNO_LVL1 = "turnoLvl1";
export const TURNO_STATS = "turnoStats";
export const TURNO_POSITIONS = "positions";

export const CLOSING_TILL_COLLECTION = "closingtill";
export const CLOSING_TILL_LVL1 = "closingtillLvl1";

//Veículo
export const VEICULO_COLLECTION = "veiculos";
export const VEICULO_LVL1 = "veiculoLvl1";
export const VEICULO_LVL2 = "veiculoLvl2";

//Moradas
export const MORADA_COLLECTION = "moradas";
export const MORADA_LVL1 = "moradaLvl1";

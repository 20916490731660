export const tiposDeContactos = ["Telefone", "Email", "Website"] as const;
type TiposDeContactos = (typeof tiposDeContactos)[number];

export default class Contacto {
  tipo: TiposDeContactos = "Telefone";
  designacao: string = null;
  contacto: string = null;
  responsavel: string = null;
  contactoCorrespondencia = false;
  observacao: string = null;

  constructor(json?: any) {
    if (json) this.setData(json);
  }

  setData(json?: any) {
    this.tipo = json.tipo;
    this.designacao = json.designacao;
    this.contacto = json.contacto;
    this.responsavel = json.responsavel;
    this.contactoCorrespondencia = json.contactoCorrespondencia;
    this.observacao = json.observacao;
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    return {
      tipo: this.tipo,
      designacao: this.designacao,
      contacto: this.contacto,
      responsavel: this.responsavel,
      contactoCorrespondencia: this.contactoCorrespondencia,
      observacao: this.observacao,
    };
  }
}

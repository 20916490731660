import Vue from "vue";
import Router, { NavigationGuardNext, Route } from "vue-router";
import { auth } from "../services/firebase";
import moment from "moment";
import { get } from "lodash";
import { App as Cap, URLOpenListenerEvent } from "@capacitor/app";

import store from "@/common/store/store";
import Login from "@/common/views/Login.vue";
import NotFound from "@/common/views/NotFound.vue";
import ControlCenter from "@/common/views/ControlCenter.vue";
import Grupo from "@/modules/grupos/types/Grupo";

import { servicosRoutes } from "@/modules/servicos/routes/servicosRoutes";
import { clientesRoutes } from "@/modules/clientes/routes/clientesRoutes";
import { moradasRoutes } from "@/modules/moradas/routes/moradasRoutes";
import { utilizadoresRoutes } from "@/modules/utilizadores/routes/utilizadoresRoutes";
import { parceirosRoutes } from "@/modules/parceiros/routes/parceirosRoutes";
import { gruposRoutes } from "@/modules/grupos/routes/gruposRoutes";
import { veiculosRoutes } from "@/modules/veiculos/routes/veiculosRoutes";
import { turnosRoutes } from "@/modules/turnos/routes/turnosRoutes";
import { creditosRoutes } from "@/modules/creditos/routes/creditosRoutes";
import { relatoriosRoutes } from "@/modules/relatorios/routes/relatoriosRoutes";
import { adminRoutes } from "@/modules/admin/routes/adminRoutes";

Vue.use(Router);
const router = new Router({
  routes: [
    //========== LOGIN =================\\
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresGuest: true },
    },

    //========== 404 =================\\
    {
      path: "/404",
      name: "NotFound",
      component: NotFound,
      meta: { requiresAuth: true },
    },

    //========== Control Center =================\\
    {
      path: "/",
      name: "Home",
      component: ControlCenter,
      redirect: {
        name: "Agenda",
        params: { data: moment().format("DD-MM-YYYY") },
      },
      meta: { requiresAuth: true },
      children: [
        ...veiculosRoutes,
        ...utilizadoresRoutes,
        ...parceirosRoutes,
        ...moradasRoutes,
        ...clientesRoutes,
        ...creditosRoutes,
        ...servicosRoutes,
        ...gruposRoutes,
        ...relatoriosRoutes,
        ...turnosRoutes,
        ...adminRoutes,
      ],
    },
  ],
  mode: "history",
});

/**
 * It's only called on NATIVE apps when a notification is clicked
 * If the cliente is open uses the router,
 * if is closed it has to save the link in the linkRecived variable and use it on the router beforeEach
 */
let linkRecived: string = null;
Cap.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
  const slug = event.url.split(".pt").pop();
  if (slug) {
    //If there is already a route it means that the app is open, so i can just route to the new page
    if (router.currentRoute.name) {
      router.push({ path: slug });
    } else {
      linkRecived = slug;
    }
  }
});

router.beforeEach(async (to, from, next) => {
  //se não houver nenhuma match, vai para o not found
  if (!to.matched.length) {
    next({ name: "NotFound" });
    return;
  }

  //if there is a match, check if it requires Auntenticated user
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    authUserRouteHandler(to, from, next);
    return;
  }

  //if there is a match, check if it requires guest
  if (to.matched.some((record) => record.meta.requiresGuest)) {
    //If the user is logged in and goes to a route that requires guest, redirect to HOME
    if (auth.currentUser) {
      next({ name: "Home" });
    } else {
      next();
    }
  }
});

/**
 * Handles Auntenticated routes
 */
function authUserRouteHandler(to: Route, from: Route, next: NavigationGuardNext) {
  //If no user is logedin, goes to the lgin page
  if (!auth.currentUser) {
    next({ name: "Login" });
    return;
  }

  const routeRequiresPermission = to.matched.some((record) => record.meta.requires);
  //if the route does NOT require permission goes ahead
  if (!routeRequiresPermission) {
    next();
    return;
  }

  //If some match requires permission checks if the user has them.
  const grupo: Grupo = store.getters["utilizadorStore/getGrupoUtilizadorLoggedIn"];
  const temPermissao = to.matched.every((record) => {
    if (record.meta.requires) {
      return record.meta.requires.every((element: string) => get(grupo.permissoes, element));
    } else return true;
  });

  //If the does NOT have the appropriate permissions goes to NOT FOUND
  if (!temPermissao) {
    next({ name: "NotFound" });
    return;
  }

  //If the name is null and linkRecived exists then mean the user click a NATIVE notification with a link
  //Instead os going to the default page goes to the link instead
  if (from.name === null && linkRecived) {
    const path = { path: linkRecived };
    linkRecived = null;
    next(path);
  } else {
    next();
  }
}

export default router;

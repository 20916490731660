const ListaParceiros = () =>
  import(/* webpackChunkName: "chunk-parceiros" */ "@/modules/parceiros/views/ListaParceiros.vue");
const NewParceiro = () =>
  import(/* webpackChunkName: "chunk-parceiros" */ "@/modules/parceiros/views/EditParceiroPlain.vue");
const ViewParceiro = () =>
  import(/* webpackChunkName: "chunk-parceiros" */ "@/modules/parceiros/views/ViewParceiro.vue");
const ParceiroInfo = () =>
  import(/* webpackChunkName: "chunk-parceiros" */ "@/modules/parceiros/views/ParceiroInfo.vue");
const ParceiroServicos = () =>
  import(/* webpackChunkName: "chunk-parceiros" */ "@/modules/parceiros/views/ParceiroServicos.vue");
const ParceiroHistorico = () =>
  import(/* webpackChunkName: "chunk-parceiros" */ "@/modules/parceiros/views/ParceiroHistorico.vue");

export const parceirosRoutes = [
  {
    path: "/parceiros",
    name: "ListaParceiros",
    component: ListaParceiros,
    meta: {
      requiresAuth: true,
      requires: ["parceiros.viewLista"],
    },
  },
  {
    path: "/parceiros/novo",
    name: "NewParceiro",
    component: NewParceiro,
    meta: {
      requiresAuth: true,
      requires: ["parceiros.create"],
    },
  },
  {
    path: "/parceiros/:id",
    name: "ViewParceiro",
    redirect: { name: "ParceiroInfo" },
    component: ViewParceiro,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["parceiros.viewParceiro"],
    },
    children: [
      {
        path: "informacao",
        name: "ParceiroInfo",
        component: ParceiroInfo,
        meta: {
          appBarTitle: "Parceiro",
          requiresAuth: true,
          requires: ["parceiros.viewParceiro"],
        },
      },
      {
        path: "servicos",
        name: "ParceiroServicos",
        component: ParceiroServicos,
        meta: {
          appBarTitle: "Parceiro",
          requiresAuth: true,
          requires: ["parceiros.viewServicos"],
        },
      },
      {
        path: "historico",
        name: "ParceiroHistorico",
        component: ParceiroHistorico,
        meta: {
          appBarTitle: "Parceiro",
          requiresAuth: true,
          requires: ["parceiros.viewHistorico"],
        },
      },
    ],
  },
];

import { cloneDeep } from "lodash";
import {
  UTILIZADOR_COLLECTION,
  HISTORICO_COLLECTION,
  CLOSING_TILL_LVL1,
  CLOSING_TILL_COLLECTION,
  SERVICO_COLLECTION,
} from "@/common/defs/collectionNames";
import {
  query,
  writeBatch,
  doc,
  collection,
  orderBy,
  Query,
  DocumentReference,
  getDocs,
  where,
  WriteBatch,
} from "firebase/firestore";
import Turno from "../types/Turno";
import { db } from "@/common/services/firebase";
import DBOperations from "@/common/services/DBOperations";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import { getMeta } from "@/common/services/IfirestoreObject";
import { dbOperations as dbOperationsShift } from "@/modules/turnos/store/turnoStore";
import { dbOperations as dbOperationsPayments } from "@/modules/creditos/store/pagamentoStore";
import { dbOperations as dbOperationsService } from "@/modules/servicos/store/servicoStore";
import Servico from "@/modules/servicos/types/Servico";
import ClosingTill from "../types/ClosingTill";

export const dbOperations = new DBOperations("ClosingTill", {
  metaCollection: CLOSING_TILL_LVL1,
  lvl1Name: CLOSING_TILL_LVL1,
});

const getters = {};

const mutations = {};

interface IGetPayload {
  query: Query | DocumentReference;
  ref?: { item: Turno } | Turno[];
}

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Turno } | { item: Turno[] } | Turno[];
}

async function lockAssociatedServices(state: any, turno: Turno, isToLock: boolean, batch: WriteBatch) {
  const associatedServices: Servico[] = [];
  const q = query(
    collection(db, SERVICO_COLLECTION),
    where("status.turnoId", "==", turno.id),
    where("motoristaId", "==", turno.info.motorista.id),
    where("status.situacao", "in", ["enviado", "confirmado", "iniciado", "em curso", "concluido"]),
    where("status.active", "==", true)
  );
  await state.dispatch("servicoStore/getDocsBase", { ref: associatedServices, query: q }, { root: true });

  for (const service of associatedServices) {
    const serviceToSave = cloneDeep(service);
    serviceToSave.status.locked = isToLock;
    dbOperationsService
      .setOp(serviceToSave, batch)
      .update({ base: ["status.locked"] })
      .logLog(
        `O serviço foi ${isToLock ? "bloquado" : "desbloquado"} ao ${isToLock ? "criar" : "cancelar"} a folha de caixa`
      )
      .update({ lvl1: ["status.locked"], lvl2: ["status.locked"], stats: ["status.locked"] })
      .setMeta();
  }
}

const actions = {
  /**
   * Get de uma só vez os documentos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsBase(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsBase(payload.ref, payload.query);
  },

  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListener(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerLvl1(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref, payload.query, "lvl1");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, payload: { userId: string; shiftId: string }) {
    const res: HistoricoItem[] = [];
    const docBaseCliente = doc(db, UTILIZADOR_COLLECTION, payload.userId);
    const q = collection(docBaseCliente, CLOSING_TILL_COLLECTION, payload.shiftId, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  async newClosingTill(state: any, closingTill: ClosingTill) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
    const batchArray: WriteBatch[] = [];
    const batchDoc = writeBatch(db);

    //Set Meta
    closingTill.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const closingTillMini = closingTill.minimized();
    const base = {
      info: closingTillMini.info,
      shifts: closingTillMini.shifts,
      payments: closingTillMini.payments,
      stats: closingTillMini.stats,
      status: closingTillMini.status,
    };
    const lvl1 = {
      meta: closingTillMini.meta,
      status: closingTillMini.status,
      data: closingTillMini.info.startDate,
      driverId: closingTillMini.info.driver.id,
    };

    //Set o doc
    const driverId = closingTill.info.driver.id;
    const docBaseUtilizador = doc(db, UTILIZADOR_COLLECTION, driverId);
    const docClosingTill = doc(collection(docBaseUtilizador, CLOSING_TILL_COLLECTION));
    batchDoc.set(docClosingTill, base);
    batchDoc.set(doc(docClosingTill, CLOSING_TILL_LVL1, docClosingTill.id), lvl1);

    //Set histórico
    const historicoItem = new HistoricoItem();
    historicoItem.setCreated();
    historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    batchDoc.set(doc(collection(docClosingTill, HISTORICO_COLLECTION)), historicoItem.minimized());

    //Set lock on all shifts
    const batchShift = writeBatch(db);
    for (const shift of closingTill.shifts) {
      //if it's already locked doesn't need to change
      const shiftToSave = cloneDeep(shift);
      shiftToSave.status.locked = true;
      dbOperationsShift
        .setOp(shiftToSave, batchShift)
        .update({ base: ["status.locked"] })
        .logLog(`O turno foi bloquado ao criar a folha de caixa`)
        .update({ lvl1: ["status.locked"], stats: ["status.locked"] })
        .setMeta();

      const batchServices = writeBatch(db);
      await lockAssociatedServices(state, shift, true, batchServices);
      batchArray.push(batchServices);
    }

    //Set lock on all the payments
    const batchPayments = writeBatch(db);
    for (const payment of closingTill.payments) {
      //if it's already locked doesn't need to change
      if (!payment.status.locked) {
        const paymentToSave = cloneDeep(payment);
        paymentToSave.status.locked = true;
        dbOperationsPayments
          .setOp(paymentToSave, batchPayments)
          .update({ base: ["status.locked"] })
          .logLog(`O pagamento foi bloquado ao criar a folha de caixa`)
          .update({ lvl1: ["status.locked"] })
          .setMeta();
      }
    }

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    //Uses multiple batches because of the 500 writes limit
    batchArray.unshift(batchDoc, batchShift, batchPayments);
    for (const batch of batchArray) {
      await batch.commit();
    }
    return docClosingTill.id;
  },

  /**
   * Update observações
   */
  async updateObservacaoDB(_: any, payload: { closingtill: ClosingTill; historico: ClosingTill }) {
    dbOperations
      .setOp(payload.closingtill)
      .update({ base: ["comments"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  async updateStatusDB(state: any, payload: { closingtill: ClosingTill; historico: ClosingTill }) {
    const batchArray: WriteBatch[] = [];

    const batchDoc = dbOperations
      .setOp(payload.closingtill)
      .update({ base: ["status.active", "status.canceled"] })
      .log(payload.historico)
      .update({ lvl1: ["status.active", "status.canceled"] })
      .setMeta()
      .getBatch();

    const isToLock = !payload.closingtill.status.canceled && payload.closingtill.status.active;

    //Set lock on all shifts
    const batchShift = writeBatch(db);
    for (const shift of payload.closingtill.shifts) {
      const shiftToSave = cloneDeep(shift);
      shiftToSave.status.locked = isToLock;
      dbOperationsShift
        .setOp(shiftToSave, batchShift)
        .update({ base: ["status.locked"] })
        .logLog(
          `O turno foi ${isToLock ? "bloquado" : "desbloquado"} ao ${isToLock ? "criar" : "cancelar"} a folha de caixa`
        )
        .update({ lvl1: ["status.locked"], stats: ["status.locked"] })
        .setMeta();

      const batchServices = writeBatch(db);
      await lockAssociatedServices(state, shift, isToLock, batchServices);
      batchArray.push(batchServices);
    }

    //Set lock on all the payments
    const batchPayments = writeBatch(db);
    for (const payment of payload.closingtill.payments) {
      const paymentToSave = cloneDeep(payment);
      paymentToSave.status.locked = isToLock;
      dbOperationsPayments
        .setOp(paymentToSave, batchPayments)
        .update({ base: ["status.locked"] })
        .logLog(
          `O pagamento foi ${isToLock ? "bloquado" : "desbloquado"} ao ${
            isToLock ? "criar" : "cancelar"
          } a folha de caixa`
        )
        .update({ lvl1: ["status.locked"] })
        .setMeta();
    }

    //Uses multiple batches because of the 500 writes limit
    batchArray.unshift(batchDoc, batchShift, batchPayments);
    for (const batch of batchArray) {
      await batch.commit();
    }
  },

  /**
   * Elimina permanentemente a consulta na firebase.firestore
   */
  // async deleteConsultaDB(state: any, turno: Turno) {
  //   const docBase = turno.getdocRef();
  //   const batch = writeBatch(db);
  //   batch.delete(docBase);
  //   batch.delete(doc(docBase, TURNO_LVL1, turno.id));

  //   const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
  //   snapshotHistorico.docs.forEach((doc) => {
  //     batch.delete(doc.ref);
  //   });

  //   batch.commit();
  //   return docBase.id;
  // },
};

export default {
  getters,
  mutations,
  actions,
  namespaced: true,
};

import { writeBatch, doc, getDocs, query, collection } from "firebase/firestore";
import { CLIENTE_COLLECTION, CLIENTE_LVL1, CLIENTE_LVL2, HISTORICO_COLLECTION } from "@/common/defs/collectionNames";
import { getMeta } from "@/common/services/IfirestoreObject";
import Cliente from "@/modules/clientes/types/Cliente";
import Morada from "@/modules/moradas/types/Morada";
import Contacto from "../types/Contacto";
import { db } from "@/common/services/firebase";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import DadoFaturacao from "../types/DadoFaturacao";
import { dbOperations as clienteDbOperations } from "./clienteStore";

interface State {
  cliente: Cliente;

  moradaRemovida: Morada;
  contactoRemovido: Contacto;
}

const state: State = {
  cliente: new Cliente(),

  moradaRemovida: null,
  contactoRemovido: null,
};

//Helper com as operações da firestore
const dbOperations = clienteDbOperations;

const getters = {
  cliente(state: State) {
    return state.cliente;
  },

  moradas(state: State) {
    return state.cliente.moradas;
  },

  contactos(state: State) {
    return state.cliente.contactos;
  },
};

const mutations = {
  //---------- Cliente --------------\\
  setCliente(state: State, cliente: Cliente) {
    state.cliente = cliente;
    state.moradaRemovida = null;
    state.contactoRemovido = null;
  },

  resetCliente(state: State) {
    state.cliente = new Cliente();
    state.moradaRemovida = null;
    state.contactoRemovido = null;
  },

  //---------- Moradas --------------\\
  addMorada(state: State, payload: { morada: Morada; index: number }) {
    if (payload.index != null) state.cliente.moradas.splice(payload.index, 1, payload.morada);
    else state.cliente.moradas.push(payload.morada);
  },
  removeMorada(state: State, payload: { morada: Morada; index: number }) {
    state.cliente.moradas.splice(payload.index, 1);
    state.moradaRemovida = payload.morada;
  },
  restoreMorada(state: State, index: number) {
    state.cliente.moradas.splice(index, 0, state.moradaRemovida);
  },

  //---------- Contactos --------------\\
  addContacto(state: State, payload: { contacto: Contacto; index: number }) {
    if (payload.index != null) state.cliente.contactos.splice(payload.index, 1, payload.contacto);
    else state.cliente.contactos.push(payload.contacto);
  },
  removeContacto(state: State, payload: { contacto: Contacto; index: number }) {
    state.cliente.contactos.splice(payload.index, 1);
    state.contactoRemovido = payload.contacto;
  },
  restoreContacto(state: State, index: number) {
    state.cliente.contactos.splice(index, 0, state.contactoRemovido);
  },
};

const actions = {
  //* Guarda um novo serviço na firebase.firestore
  async saveNewCliente(state: any, cliente: Cliente) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
    const batch = writeBatch(db);

    //Guarda as moradas de forma a poder utiliza-las nos serviços, adiciona o ID se guardar
    await saveMoradas(state, cliente.moradas);

    //Set Meta
    cliente.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const clienteMini = cliente.minimized();

    //Divide o serviço nos docs
    const base = {
      info: clienteMini.info,
      dadosFaturacao: clienteMini.dadosFaturacao,
      moradas: clienteMini.moradas,
      contactos: clienteMini.contactos,
      status: clienteMini.status,
      stats: clienteMini.stats,
    };
    //Ponho a data de forma a poder filtar a group query por datas
    const lvl1 = {
      observacoes: clienteMini.observacoes,
      status: clienteMini.status,
    };
    const lvl2 = {
      conta: clienteMini.conta,
      meta: clienteMini.meta,
      status: clienteMini.status,
    };

    //Set o doc
    const docBase = doc(collection(db, CLIENTE_COLLECTION));
    batch.set(docBase, base);
    batch.set(doc(docBase, CLIENTE_LVL1, docBase.id), lvl1);
    batch.set(doc(docBase, CLIENTE_LVL2, docBase.id), lvl2);

    //Set histórico
    const historicoItem = new HistoricoItem();
    historicoItem.setCreated();
    historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    historicoItem.setNotification({
      id: docBase.id,
      title: `Novo cliente criado`,
      textFields: [{ text: `${cliente.info.nome ?? cliente.info.nif}` }],
      link: `/clientes/${docBase.id}`,
    });
    batch.set(doc(collection(docBase, HISTORICO_COLLECTION)), historicoItem.minimized());

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docBase.id;
  },

  /**
   * Update os dados na firebase.firestore
   */
  updateDadosDB(_: any, payload: { cliente: Cliente; historico: Cliente }) {
    dbOperations
      .setOp(payload.cliente)
      .update({
        base: [
          "info.denominacao",
          "info.nome",
          "info.nif",
          "info.aniversario",
          "info.condicaoPagamento",
          "info.idioma",
          "info.vip",
        ],
      })
      .log(payload.historico, {
        title: `Cliente ${payload.historico.info.nome} modificado`,
        link: `/clientes/${payload.cliente.id}`,
      })
      .setMeta()
      .commit();
  },

  /**
   * Update dados de faturação do cliente
   */
  async updateDadosFaturacaoDB(
    _: any,
    payload: {
      cliente: Cliente;
      dadosFaturacaoChanges: { type: string; dadoFaturacao: DadoFaturacao }[];
      historico: Cliente;
    }
  ) {
    const changesToSave = payload.dadosFaturacaoChanges.filter((change) => change.type !== "deleted");
    payload.cliente.dadosFaturacao = changesToSave.map((change) => change.dadoFaturacao);

    dbOperations
      .setOp(payload.cliente)
      .update({ base: ["dadosFaturacao"] })
      .log(payload.historico, {
        title: `Cliente ${payload.historico.info.nome} modificado`,
        link: `/clientes/${payload.cliente.id}`,
      })
      .setMeta()
      .commit();
  },

  /**
   * Update moradas do cliente na firebase.firestore
   */
  async updateMoradasDB(
    state: any,
    payload: { cliente: Cliente; moradasChanges: { type: string; morada: Morada }[]; historico: Cliente }
  ) {
    //Guarda as moradas de forma a poder utiliza-las nos serviços, adiciona o ID se guardar
    const changesToSave = payload.moradasChanges.filter((change) => change.type !== "deleted");
    const moradasToSave = changesToSave.map((change) => change.morada);
    await saveMoradas(state, moradasToSave);

    //Set moradas no cliente
    payload.cliente.moradas = moradasToSave;

    dbOperations
      .setOp(payload.cliente)
      .update({ base: ["moradas"] })
      .log(payload.historico, {
        title: `Cliente ${payload.historico.info.nome} modificado`,
        link: `/clientes/${payload.cliente.id}`,
      })
      .setMeta()
      .commit();
  },

  /**
   * Update os contactos do cliente na firebase.firestore
   */
  async updateContactosDB(
    _: any,
    payload: { cliente: Cliente; contactosChanges: { type: string; contacto: Contacto }[]; historico: Cliente }
  ) {
    //Retira os contactos que foram deleted e coloca os restantes no cliente
    const changesToSave = payload.contactosChanges.filter((change) => change.type !== "deleted");
    payload.cliente.contactos = changesToSave.map((change) => change.contacto);

    dbOperations
      .setOp(payload.cliente)
      .update({ base: ["contactos"] })
      .log(payload.historico, {
        title: `Cliente ${payload.historico.info.nome} modificado`,
        link: `/clientes/${payload.cliente.id}`,
      })
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateStatusDB(_: any, payload: { cliente: Cliente; historico: Cliente }) {
    dbOperations
      .setOp(payload.cliente)
      .update({ base: ["status.active"] })
      .log(payload.historico, {
        title: `Cliente ${payload.historico.info.nome} modificado`,
        link: `/clientes/${payload.cliente.id}`,
      })
      .update({ lvl1: ["status.active"], lvl2: ["status.active"] })
      .setMeta()
      .commit();
  },

  /**
   * Elimina permanentemente a veiculo na firebase.firestore
   */
  async deleteClienteDB(state: any, cliente: Cliente) {
    const docBase = doc(db, CLIENTE_COLLECTION, cliente.id);
    const batch = writeBatch(db);

    batch.delete(docBase);
    batch.delete(doc(docBase, CLIENTE_LVL1, cliente.id));
    batch.delete(doc(docBase, CLIENTE_LVL2, cliente.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

/**
 * Guarda as moradas criadas dentro do cliente caso seja necesssário.
 * Se guardar coloca os ids nas respetiveis moradas.
 */
async function saveMoradas(state: any, moradas: Morada[]) {
  const promiseList = [];

  for (const morada of moradas) {
    if (!morada.id) {
      promiseList.push(state.dispatch("moradaStore/saveNewMorada", morada, { root: true }));
    }
  }

  const resultado = await Promise.all(promiseList);
  let i = 0;
  for (const morada of moradas) {
    if (!morada.id) {
      morada.id = resultado[i];
      i++; //Só aumento o index se não tiver ID, para combinar com o array de moradas original.
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

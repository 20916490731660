import {
  UTILIZADOR_COLLECTION,
  GRUPO_COLLECTION,
  UTILIZADOR_LVL1,
  UTILIZADOR_LVL2,
  HISTORICO_COLLECTION,
} from "@/common/defs/collectionNames";
import {
  Query,
  query,
  getDoc,
  getDocs,
  writeBatch,
  doc,
  collection,
  orderBy,
  DocumentReference,
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

import Compressor from "compressorjs";

import { db, functions, storage } from "@/common/services/firebase";
import Utilizador from "../types/Utilizador";
import Grupo from "@/modules/grupos/types/Grupo";
import { getMeta } from "@/common/services/IfirestoreObject";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import { ActionContext } from "vuex";
import DBOperations from "@/common/services/DBOperations";

export interface State {
  utilizadores: Utilizador[];
  utilizadorLoggedIn: Utilizador;
  grupoUtilizadorLoggedIn: Grupo;
}

const state: State = {
  utilizadores: [],
  utilizadorLoggedIn: null,
  grupoUtilizadorLoggedIn: null,
};

const dbOperations = new DBOperations("Utilizador", {
  metaCollection: UTILIZADOR_LVL2,
  lvl1Name: UTILIZADOR_LVL1,
  lvl2Name: UTILIZADOR_LVL2,
});

const getters = {
  getUtilizadorLoggedIn() {
    return state.utilizadorLoggedIn;
  },
  getGrupoUtilizadorLoggedIn() {
    return state.grupoUtilizadorLoggedIn;
  },

  //* Retorna array dos utilizadores. ! NÃO É IMUTÁVEL !
  getUtilizadores(state: State) {
    return state.utilizadores;
  },

  //* retorna o utilizador com o ID solicitado.
  getUtilizador: (state: State) => (id: string) => {
    return state.utilizadores.find((utilizador) => utilizador.id === id);
  },

  //* Retorna array dos utilizadores. ! NÃO É IMUTÁVEL !
  getUtilizadoresActivos(state: State) {
    return state.utilizadores.filter((value) => value.status.active);
  },

  //* Retorna array dos motoristas activos. ! NÃO É IMUTÁVEL !
  getMotoristas(state: State) {
    return state.utilizadores.filter((value) => value.info.cargo === "Motorista");
  },

  //* Retorna array dos motoristas activos. ! NÃO É IMUTÁVEL !
  getMotoristasActivos(state: State) {
    return state.utilizadores.filter((value) => value.info.cargo === "Motorista" && value.status.active);
  },

  /** verifica se o utillizador é duplicado */
  isUtilizadorDuplicado: (state: State) => (utilizador: Utilizador) => {
    if (utilizador.credenciais.email) {
      const utilizadorFound = state.utilizadores.find((utl) => utl.credenciais.email === utilizador.credenciais.email);
      if (utilizadorFound && utilizadorFound.id !== utilizador.id) return true;
      else return false;
    } else {
      return false;
    }
  },
};

const mutations = {
  setUtilizadorLoggedIn(state: State, utilizador: Utilizador) {
    state.utilizadorLoggedIn = utilizador;
  },
  setGrupoUtilizadorLoggedIn(state: State, grupo: Grupo) {
    state.grupoUtilizadorLoggedIn = grupo;
  },
  clearStore(state: State) {
    state.utilizadores = [];
  },
};

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Utilizador } | { item: Utilizador[] } | Utilizador[];
}

async function processLvlListener(context: ActionContext<any, any>, payload: IListenerPayload, lvl: string) {
  const listener = await dbOperations.getListenerLvl(payload.ref ?? context.state.utilizadores, payload.query, lvl);
  context.commit("addListener", listener, { root: true });
  return listener;
}

const actions = {
  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerBase(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref ?? context.state.utilizadores, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  async getListenerLvl1(context: ActionContext<any, any>, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl1");
  },

  async getListenerLvl2(context: ActionContext<any, any>, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl2");
  },

  //* Set o Utilizador que fez logIn e o seu grupo de permissões
  async setUtilizadorLoggedIn({ commit }: any, user: any) {
    if (user) {
      //Get o doc do User da DB
      const refBase = doc(db, UTILIZADOR_COLLECTION, user.uid);
      const refLv1 = doc(refBase, UTILIZADOR_LVL1, user.uid);

      const idToken = await user.getIdTokenResult();
      const refGrupo = doc(db, GRUPO_COLLECTION, idToken.claims.grupoId);
      const result = await Promise.all([getDoc(refBase), getDoc(refLv1), getDoc(refGrupo)]);

      if (!result[0].exists || !result[1].exists || !result[2].exists)
        throw "O utilizador ou o grupo não existe na base de dados";

      const utilizador = new Utilizador(user.uid, result[0].data());
      utilizador.setLvl1(result[1].data());
      utilizador.getAvatar();

      const docGrupo = result[2];
      const grupo = new Grupo(docGrupo.id, docGrupo.data());

      commit("setUtilizadorLoggedIn", utilizador);
      commit("setGrupoUtilizadorLoggedIn", grupo);
      return utilizador;
    } else {
      commit("setUtilizadorLoggedIn", null);
      commit("setGrupoUtilizadorLoggedIn", null);
      return;
    }
  },

  async saveNewUtilizador(state: any, utilizador: Utilizador) {
    let user: any = null;
    let avatarRef = null;
    let uploadTask = null;

    try {
      //Cria um user no firebase.firestore Auth
      const createAuthUser = httpsCallable(functions, "createAuthUser");
      user = await createAuthUser({
        email: utilizador.credenciais.email,
        password: utilizador.password,
        displayName: utilizador.info.nome,
      });

      //Reduz e upload a imagem do avatar
      if (utilizador.avatar) {
        const imgReduced = await compressImage(utilizador.avatar);
        //Se a imagem convertida for muito grande, throw um erro.
        if (imgReduced.size > 1 * 1000 * 1000) {
          throw `Avatar size is over 1MB, size:${imgReduced.size}`;
        }
        avatarRef = ref(storage, `utilizadores/${user.data.uid}/avatar`);
        uploadTask = await uploadBytes(avatarRef, imgReduced);
        utilizador.info.avatar = avatarRef.fullPath;
      }

      //Guarda o user na DB
      const utilizadorLogedIn = state.getters.getUtilizadorLoggedIn;
      const batch = writeBatch(db);

      //Set Meta
      utilizador.meta = getMeta(utilizadorLogedIn);
      //minimiza dados a guardar na DB
      const utilizadorMini = utilizador.minimized();

      const base = {
        info: utilizadorMini.info,
        contactosEmpresa: utilizadorMini.contactosEmpresa,
        status: utilizadorMini.status,
      };
      const lvl1 = {
        contactosPessoais: utilizadorMini.contactosPessoais,
        credenciais: utilizadorMini.credenciais,
        settings: utilizadorMini.settings,
        status: utilizadorMini.status,
      };
      const lvl2 = {
        salaryConditions: utilizadorMini.salaryConditions,
        meta: utilizadorMini.meta,
        status: utilizadorMini.status,
      };

      //Set o doc
      const docBase = doc(db, UTILIZADOR_COLLECTION, user.data.uid);
      batch.set(docBase, base);
      batch.set(doc(docBase, UTILIZADOR_LVL1, docBase.id), lvl1);
      batch.set(doc(docBase, UTILIZADOR_LVL2, docBase.id), lvl2);

      //Set histórico
      const historicoItem = new HistoricoItem();
      historicoItem.setCreated();
      historicoItem.meta = getMeta(state.getters.getUtilizadorLoggedIn);
      batch.set(doc(collection(docBase, HISTORICO_COLLECTION)), historicoItem.minimized());

      //Eu não espero porque é sempre bem sucedido, mesmo offline.
      //Quando está offline nunca resolve a promessa, até ficar online
      batch.commit();
      return docBase.id;
    } catch (err) {
      //Se ocorreu um erro e o user existir então tem de ser eliminado.
      if (user) httpsCallable(functions, "deleteAuthUser")({ userUid: user.data.uid });
      //Se o avatar for uploaded, tem de ser eliminado.
      if (uploadTask) deleteObject(avatarRef);

      if ((err as any).details && (err as any).details.code === "auth/email-already-exists")
        throw "auth/email-already-exists";
      else throw "Erro ao criar o utilizador";
    }
  },

  /**
   * Update o avatar do utilizador na firebase.firestore
   */
  async updateAvatarDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    //Reduz e upload a imagem do avatar
    const imgReduced = await compressImage(payload.utilizador.avatar);
    //Se a imagem convertida for muito grande, throw um erro.
    if (imgReduced.size > 1 * 1000 * 1000) {
      throw `Avatar size is over 1MB, size:${imgReduced.size}`;
    }
    const avatarRef = ref(storage, `utilizadores/${payload.utilizador.id}/avatar`);
    await uploadBytes(avatarRef, imgReduced);
    payload.utilizador.info.avatar = avatarRef.fullPath;

    //Sava path na DB
    dbOperations
      .setOp(payload.utilizador)
      .update({ base: ["info.avatar"] })
      .logLog("avatar")
      .setMeta()
      .commit();

    //Actualiza o utilizador com o novo avatar
    payload.utilizador.getAvatar();
  },

  /**
   * Update o nome do utilizador na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({ base: ["info.nome"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update a departamento do utilizador na firebase.firestore
   */
  async updateDepartamentoDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({ base: ["info.cargo"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update a departamento do utilizador na firebase.firestore
   */
  async updateGrupoDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    //Update google auth
    await httpsCallable(
      functions,
      "updateUserGroupClaims"
    )({ uid: payload.utilizador.id, groupId: payload.utilizador.credenciais.grupoId });

    dbOperations
      .setOp(payload.utilizador)
      .update({ lvl1: ["credenciais.grupoId"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update os contactos empresariais do utilizador na firebase.firestore
   */
  async updateContactosEmpresariaisDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({ base: ["contactosEmpresa.telemovel", "contactosEmpresa.extensao", "contactosEmpresa.email"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update os contactos pessoais do utilizador na firebase.firestore
   */
  async updateContactosPessoaisDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({ lvl1: ["contactosPessoais.telemovel", "contactosPessoais.telefone", "contactosPessoais.email"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o contacto de emergência do utilizador na firebase.firestore
   */
  async updateContactoEmergenciaDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({ lvl1: ["contactosPessoais.emergencia.nome", "contactosPessoais.emergencia.contacto"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update SAlary Conditons on the DB
   */
  async updateSalaryConditions(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({
        lvl2: [
          "salaryConditions.base",
          "salaryConditions.mealAllowance",
          "salaryConditions.timeWaiver",
          "salaryConditions.hasVariableAllowancesAndExpenses",
          "salaryConditions.fixedAllowancesAndExpenses",
          "salaryConditions.commissionsPercentage",
          "salaryConditions.isComissionIncludedOnSalary",
        ],
      })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update as notificações do utilizador na firebase.firestore
   */
  async updateNotificacoesDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({
        lvl1: [
          "settings.notifications.notifyOnAllServicosChanged",
          "settings.notifications.notifyTaxiServicesChanged",
          "settings.notifications.notifyOnOwnServicosChanged",
          "settings.notifications.servicoFieldsToNotify",
          "settings.notifications.notifyOnAllClientesChanged",
          "settings.notifications.clienteFieldsToNotify",
          "settings.notifications.notifyOnAllPagamentosChanged",
          "settings.notifications.pagamentoFieldsToNotify",
          "settings.notifications.notifyOnAllShiftsChanged",
          "settings.notifications.shiftFieldsToNotify",
        ],
      })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update as notificações do utilizador na firebase.firestore
   */
  async updateVeiculoDefsDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    dbOperations
      .setOp(payload.utilizador)
      .update({
        lvl1: ["settings.veiculoPreferido", "settings.veiculosAutorizados"],
      })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  async updateStatusDB(_: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    //Update google auth
    await httpsCallable(
      functions,
      "setUserAuthStatus"
    )({ userUid: payload.utilizador.id, active: payload.utilizador.status.active });

    dbOperations
      .setOp(payload.utilizador)
      .update({ base: ["status.active"] })
      .log(payload.historico)
      .update({ lvl1: ["status.active"], lvl2: ["status.active"] })
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  async updatePasswordDB(state: any, payload: { utilizador: Utilizador; historico: Utilizador }) {
    //Update google auth
    await httpsCallable(
      functions,
      "setUserPassword"
    )({
      userUid: payload.utilizador.id,
      password: payload.utilizador.password,
    });

    dbOperations.setOp(payload.utilizador).logLog("password atualizada").commit();
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, id: string) {
    const res: HistoricoItem[] = [];
    const q = collection(db, UTILIZADOR_COLLECTION, id, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  /**
   * Elimina permanentemente o utilizador na firebase.firestore
   */
  async deleteUtilizadorDB(state: any, utilizador: Utilizador) {
    //Delete User no google Authentification
    await httpsCallable(functions, "deleteAuthUser")({ userUid: utilizador.id });

    //Delete Avatar do user na storage
    deleteObject(ref(storage, `utilizadores/${utilizador.id}/avatar`));

    //Delete user na DB
    const docBase = doc(db, UTILIZADOR_COLLECTION, utilizador.id);
    const batch = writeBatch(db);

    batch.delete(docBase);
    batch.delete(doc(docBase, UTILIZADOR_LVL1, utilizador.id));
    batch.delete(doc(docBase, UTILIZADOR_LVL2, utilizador.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
  },
};

function compressImage(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxHeight: 512,
      maxWidth: 512,
      convertSize: 1 * 1000 * 1000,
      success: resolve,
      error: reject,
    });
  });
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

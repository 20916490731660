import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider, AppCheck } from "firebase/app-check";
import {
  getFirestore,
  Firestore,
  enableMultiTabIndexedDbPersistence,

  //connectFirestoreEmulator,
} from "firebase/firestore";
import { getFunctions, Functions, httpsCallable } from "firebase/functions";
import { getStorage, FirebaseStorage } from "firebase/storage";
import { getMessaging, Messaging, getToken } from "firebase/messaging";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor as CapacitorCore } from "@capacitor/core";
import { App as capacitorApp } from "@capacitor/app";
import { Device } from "@capacitor/device";

import { CapacitorCustomPushNotification } from "@/plugins/capacitorCustomPushNotification/index";
import logoutAndClear from "./logoutAndClear";
import pushNotificationsHandler from "./pushNotificationsHandler";

const LOCATION = "europe-west1";

const credenciais = {
  apiKey: process.env.VUE_APP_FIRESTORE_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

//logo que a pagina é acedida tenho de ter a APP e o Auth inicializado
let app: FirebaseApp;
export let auth: Auth;

export let db: Firestore;
export let functions: Functions;
export let storage: FirebaseStorage;
export let messaging: Messaging;

export let appcheck: AppCheck;

/**
 * Inicializa a base da firestore
 */
export async function initAppAndAuth() {
  app = initializeApp(credenciais);
  auth = getAuth(app);

  //Todas as calls ao backend do firestore ficam protegidas pelo appcheck
  //Inicializa da mesma forma na web e no mobile, porque no android é uma webview
  appcheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.VUE_APP_APP_CHECK),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
  });
}

/**
 * Inicializa os SDK da firebase
 */
export async function initFirestoreStuff(force = false) {
  //Se a db já exitir então já está inicializado
  if (db && force) return;

  try {
    db = getFirestore(app);
    functions = getFunctions(app, LOCATION);
    storage = getStorage(app);

    //Quando é para usar o emulador
    //connectFirestoreEmulator(db, "localhost", 9090);

    //O tipo login, se o utilizador escolher "manter-se conectado" ou não
    //é retornado LOCAL quando é para "manter-se conectado" ou SESSION quando é para apenas persistir até a tab ser fechada
    const authPersistence = (auth as any).persistenceManager.persistence.type;

    //Só faço enable persistencia off-line dos dados em dispositivos seguros, que o utilizador escolher "manter-se conectado" (LOCAL)
    if (authPersistence === "LOCAL") {
      await enableMultiTabIndexedDbPersistence(db);
    }
  } catch (err) {
    console.log(err);
    throw { err };
  }
}

export async function inicializaNotifications() {
  //Só activa as notificações se o utilizador selecionar login persistente
  const authPersistence = (auth as any).persistenceManager.persistence.type;
  if (authPersistence === "LOCAL") {
    if (CapacitorCore.isNativePlatform()) await initNotificationsMobile();
    else await initNotificationsWeb();
  }
}

async function setNotificationToken(token: string) {
  const setNotificationToken = httpsCallable(functions, "setNotificationToken");
  const deviceId = await Device.getId();
  await setNotificationToken({ deviceId: deviceId.identifier, token });
}

/**
 * Inicializa as notificações na WEB
 */
async function initNotificationsWeb() {
  try {
    messaging = getMessaging(app);

    const currentToken = await getToken(messaging, { vapidKey: process.env.VUE_APP_VAPID_KEY });

    if (!currentToken) console.log("No registration token available. Request permission to generate one.");
    await setNotificationToken(currentToken);

    //Push messages handler from the service worker
    navigator.serviceWorker.addEventListener("message", (event) => {
      //Only accepts push messages if are sent from a legit source, the same domain.
      if (event.origin !== self.location.origin) return;

      //Quando o serviceworker faz o inicialize do messaging, automaticamente manda as FCM para aqui
      //Como não quero essas push messages (só quero as que eu envio manualmente), ignoro-as.
      if (event.data.origin != "my-serviceworker") return;

      console.log("notificationReceived on Javascript");
      console.log(event.data);
      pushNotificationsHandler(event.data);
    });
  } catch (err) {
    console.log("An error occurred on initNotificationsWeb. ", err);
  }
}

/**
 * Inicializa as notificações no android
 */
async function initNotificationsMobile() {
  await PushNotifications.addListener("registration", (token) => {
    setNotificationToken(token.value);
  });

  await PushNotifications.addListener("registrationError", (err) => {
    console.error("Registration error: ", err.error);
  });

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await CapacitorCustomPushNotification.addListener("notificationReceived", (data) => {
    console.log("notificationReceived on Javascript");
    console.log(Object.entries(data).toString());
    pushNotificationsHandler(data);
  });

  await PushNotifications.register();
}

/**
 * Inicizaliza o sersor de presença
 * Este metodo corre sempre que o user volta a tab, é o sitio perfeito para fazer o check do revoked
 */
export async function initPresenceSensor() {
  capacitorApp.addListener("appStateChange", async ({ isActive }: any) => {
    try {
      //Evio a ID token para confirmar que não foi revoked
      //Se foi faz logout
      const idToken = await auth.currentUser.getIdToken();
      const deviceId = await Device.getId();
      const args = { idToken, deviceId: deviceId.identifier, status: "online" };
      if (!isActive) args.status = "away";

      const setUserPresenceStatus = httpsCallable(functions, "setUserPresenceStatus");
      await setUserPresenceStatus(args);
    } catch (err) {
      //Faz logout do user
      if (err == "FirebaseError: auth/id-token-revoked" || err == "FirebaseError: permission-denied") logoutAndClear();
      console.log(err);
    }
  });
}

import router from "@/common/router/router";
import logoutAndClear from "./logoutAndClear";

export default async function pushNotificationsHandler(data: any) {
  //This type of event only occours in the WEB plataform
  if (data.messageType === "NOTIFICATION_CLICKED" && data.link) {
    router.push({ path: data.link });
  }

  if (data.messageType === "ACTION") {
    if (data.action.type === "logout") {
      logoutAndClear();
    }
  }
}

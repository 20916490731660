import { db } from "@/common/services/firebase";
import { doc } from "firebase/firestore";
import { DocumentReference } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "@/common/services/firebase";
import { IfirestoreObject, Imeta, covertTimestamp } from "@/common/services/IfirestoreObject";
import { PARCEIRO_COLLECTION } from "@/common/defs/collectionNames";

export default class Parceiro implements IfirestoreObject {
  id?: string;
  avatar?: File; //Nesta variavel só guardo o FILE quando o user altera-o, não é guardada na DB
  avatarURL: string = null; //O Url do avatar é sempre utilizadores/uid/avatar

  //=============== BASE ======================\\
  info: {
    nome: string;
    avatar: string;
  } = { nome: null, avatar: null };

  contactos: {
    telemovel: string;
    email: string;
  } = { telemovel: null, email: null };

  status: {
    active: boolean;
  } = { active: true };

  //=============== Lvl1 ======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  async getAvatar() {
    if (this.info.avatar) {
      const url = await getDownloadURL(ref(storage, this.info.avatar));
      this.avatarURL = url;
    }
  }

  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  setData(json: Partial<Parceiro>) {
    this.info = {
      nome: json.info.nome,
      avatar: json.info.avatar,
    };

    this.contactos = {
      telemovel: json.contactos?.telemovel ?? null,
      email: json.contactos?.email ?? null,
    };

    this.status = json.status;
  }

  setLvl1(json: any) {
    this.meta = {
      createdAt: covertTimestamp(json.meta.createdAt),
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      info: this.info,
      contactos: this.contactos,
      status: this.status,
      meta: this.meta,
    };
    return res;
  }

  /**
   * Minimiza o objecto / retorna só a info a duplicar noutro objecto na Firestore
   */
  minimizedMini() {
    return {
      id: this.id,
      info: {
        nome: this.info.nome,
        avatar: this.info.avatar,
      },
    };
  }

  getdocRef(): DocumentReference {
    return doc(db, PARCEIRO_COLLECTION, this.id);
  }
}

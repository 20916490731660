const ListaServicos = () =>
  import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/ListaServicos.vue");
const EditServicoFull = () =>
  import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/EditServicoPlain.vue");
const ViewServico = () => import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/ViewServico.vue");
const ServicoInfo = () => import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/ServicoInfo.vue");
const ServicoDocumentos = () =>
  import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/ServicoDocumentos.vue");
const ServiceStats = () => import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/ServiceStats.vue");
const ServicoHistorico = () =>
  import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/ServicoHistorico.vue");
const Agenda = () => import(/* webpackChunkName: "chunk-servicos" */ "@/modules/servicos/views/Agenda.vue");

export const servicosRoutes = [
  {
    path: "/servicos",
    name: "ListaServicos",
    component: ListaServicos,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["servicos.viewLista"],
    },
  },
  {
    path: "/servicos/novo",
    name: "NewServico",
    component: EditServicoFull,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["servicos.createTimeLimited"],
    },
  },
  {
    path: "/servicos/agenda/:data",
    name: "Agenda",
    component: Agenda,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["servicos.viewAgenda"],
    },
  },
  {
    path: "/servicos/:id",
    name: "ViewServico",
    redirect: { name: "ServicoInfo" },
    component: ViewServico,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["servicos.viewServico"],
    },
    children: [
      {
        path: "informacao",
        name: "ServicoInfo",
        component: ServicoInfo,
        meta: {
          appBarTitle: "Serviço",
          requiresAuth: true,
          requires: ["servicos.viewServico"],
        },
      },
      {
        path: "documentos",
        name: "ServicoDocumentos",
        component: ServicoDocumentos,
        meta: {
          appBarTitle: "Serviço",
          requiresAuth: true,
          requires: ["servicos.viewDocumentos"],
        },
      },
      {
        path: "stats",
        name: "ServiceStats",
        component: ServiceStats,
        meta: {
          appBarTitle: "Serviço",
          requiresAuth: true,
          requires: ["servicos.accessStats"],
        },
      },
      {
        path: "historico",
        name: "ServicoHistorico",
        component: ServicoHistorico,
        meta: {
          appBarTitle: "Serviço",
          requiresAuth: true,
          requires: ["servicos.viewHistorico"],
        },
      },
    ],
  },
];

import { Timestamp } from "firebase/firestore";
import { get } from "lodash";
import { IfirestoreObject, Imeta, covertTimestamp } from "@/common/services/IfirestoreObject";

interface Ichanges {
  field?: string;
  previousData?: any;
  afterData?: any;
}

export interface Inotification {
  id?: string;
  title?: string;
  textFields?: { field?: string; text: string }[];
  link?: string;
  textReplacement?: string;
}

export default class HistoricoItem implements IfirestoreObject {
  id?: string;

  type: "created" | "updated" | "log" | "custom";
  title: string = null;
  changes: Ichanges[] = [];
  customData: any = null;
  notification: Inotification = null;

  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  /**
   * Construtor
   */
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  /**
   * Set base data, divido lvls porque caso contrário teria de fazer check a todas as propriedades por undefined.
   */
  setData(json?: any) {
    this.type = json.type;
    this.title = json.title;

    const changesWithDates = json.changes.map((change: Ichanges) => {
      if (change.previousData && change.previousData instanceof Timestamp)
        change.previousData = change.previousData.toDate();
      if (change.afterData && change.afterData instanceof Timestamp) change.afterData = change.afterData.toDate();
      return change;
    });

    this.changes = changesWithDates;
    this.customData = json.customData ?? null;
    this.notification = json.notification ?? null;

    this.meta = {
      createdAt: covertTimestamp(json.meta.createdAt),
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res: any = {
      type: this.type,
      title: this.title,
      changes: this.changes,
      customData: this.customData,
      notification: this.notification,
      meta: this.meta,
    };
    return res;
  }

  setCreated() {
    this.type = "created";
  }

  setLog(field: string, value?: string) {
    this.type = "log";
    this.changes.push({ field: field, previousData: value ? value : null });
  }

  setCustom(title: string, customData: { [key: string]: any }, changes?: { itemA: any; itemH: any; fields: string[] }) {
    this.type = "custom";
    this.title = title;
    this.customData = customData;
    if (changes) this.getChangedFields(changes);
  }

  setUpdate(field: string) {
    this.type = "updated";
    this.changes.push({ field: field });
  }

  setChanges(itemA: any, itemH: any, fields: string[]) {
    this.type = "updated";
    this.getChangedFields({ itemA, itemH, fields });
  }

  setNotification(notification: Inotification) {
    this.notification = {
      id: notification.id ?? null,
      title: notification.title ?? null,
      textFields: notification.textFields ?? null,
      link: notification.link ?? null,
      textReplacement: notification.textReplacement ?? null,
    };
  }

  /**
   * Gera um array com as alterações entre 2 objetos
   */
  getChangedFields(changes: { itemA: any; itemH: any; fields: string[] }) {
    for (const field of changes.fields) {
      const previousData = get(changes.itemH, field);
      const afterData = get(changes.itemA, field);
      const isDates = previousData instanceof Date && afterData instanceof Date;
      const isObject = previousData instanceof Object && afterData instanceof Object;

      if (
        Array.isArray(previousData) ||
        (!isDates && !isObject && previousData !== afterData) ||
        (isDates && previousData.getTime() !== afterData.getTime()) ||
        (!isDates && isObject && previousData.id !== afterData.id)
      ) {
        //Quero que passe se for false, por isso a que uso
        this.changes.push({
          field: field,
          previousData: previousData != null ? previousData : null,
          afterData: typeof afterData != null ? afterData : null,
        });
      }
    }
  }
}


import { Vue, Component } from "vue-property-decorator";
import { browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import {
  auth,
  functions,
  inicializaNotifications,
  initFirestoreStuff,
  initPresenceSensor,
} from "@/common/services/firebase";
import initData from "@/common/services/initData";
import { TurnoNotificationPlugin } from "@/plugins/TurnoNotificationPlugin";
import { Capacitor as CapacitorCore } from "@capacitor/core";

import { mdiEyeOutline, mdiEyeOffOutline, mdiAlert } from "@mdi/js";
import { httpsCallable } from "@firebase/functions";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";

@Component
export default class Login extends Vue {
  isValid = true;
  showPass = false;
  stayConected = false;
  email = "";
  password = "";

  isLoading = false;
  connectionError = false;
  invalidcredencials = "";

  icons = {
    mdiEyeOutline,
    mdiEyeOffOutline,
    mdiAlert,
  };

  emailRules = [
    (v: string) => !!v || "É necessário preencher o email",
    (v: string) => !v || v.length <= 100 || "O email não pode ter mais de 100 caráteres",
  ];
  passRules = [
    (v: string) => !!v || "É necessário preencher a palavra-passe",
    (v: string) => !v || v.length <= 50 || "A palavra-passe não pode ter mais de 50 caráteres",
  ];

  onInputsChanged() {
    this.invalidcredencials = "";
  }

  async logIn() {
    if ((this.$refs.form as any).validate()) {
      try {
        this.isLoading = true;
        //Se o utilizador não escolher stayConected o auth apenas funcina até a janela ser fechada
        if (!this.stayConected) setPersistence(auth, browserSessionPersistence);

        //Faz o signin
        const userCredencial = await signInWithEmailAndPassword(auth, this.email, this.password);

        //Se corre tudo bem inicializa tudo
        if (userCredencial.user != null) {
          //Does the lgin in the android app aswell
          if (CapacitorCore.isNativePlatform()) {
            TurnoNotificationPlugin.login({ email: this.email, password: this.password });
          }

          await initFirestoreStuff(true);
          await this.$store.dispatch("utilizadorStore/setUtilizadorLoggedIn", userCredencial.user);
          userCredencial.user.getIdToken(true);

          //Update log dos logins
          await this.logLogin(this.stayConected);

          initData();
          inicializaNotifications();
          initPresenceSensor();
          this.$router.replace({ name: "Home" });
        }
      } catch (err) {
        if ((err as any).code === "auth/network-request-failed") {
          this.connectionError = true;
        } else {
          this.invalidcredencials = "Email ou password incorretos";
        }
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async logLogin(persistentLogin: boolean) {
    const logLogin = httpsCallable(functions, "logLogin");
    const deviceId = await Device.getId();
    await logLogin({ deviceId: deviceId.identifier, platform: Capacitor.getPlatform(), persistentLogin });
  }
}

import { FieldValue, Timestamp, serverTimestamp, DocumentReference } from "firebase/firestore";
import Utilizador from "@/modules/utilizadores/types/Utilizador";

export interface Imeta {
  createdAt: Date | FieldValue;
  createdBy: { uid: string; nome: string };
}

export interface IfirestoreObject {
  /**
   * Id único do utilizador gerado pelo firestore
   */
  id?: string;

  /**
   * Dados de quem criou/alterou o objecto e o respetivo timestamp
   */
  meta: Imeta;

  /**
   * FriendlyName é o nome a apresentar ao end user nas motificações e histórico
   */
  friendlyName?: { [field: string]: { name: string; changed: string } };

  /**
   * Set base data, divido o setData em lvls porque caso contrário teria de fazer check a todas as propriedades por undefined nos construtores.
   * @param json dados que vieram do firestore
   */
  setData(json: any): void;

  /**
   * Set dados do Lvl 1, os lvls existem para poder controlar o nivel de acesso aos documentos no firestore com as permissões
   * @param json dados que vieram do firestore
   */
  setLvl1?(json: any): void;

  /**
   * Set dados do Lvl 2
   * @param json dados que vieram do firestore
   */
  setLvl2?(json: any): void;

  /**
   * Set dados do Lvl 3
   * @param json dados que vieram do firestore
   */
  setLvl3?(json: any): void;

  /**
   * Set stats data
   * @param json dados que vieram do firestore
   */
  setStats?(json: any): void;

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized(): any;

  /**
   * Quando duplico os dados noutro objecto, em vez de duplicar o objecto todo, duplico só a info relevante.
   */
  minimizedMini?(): any;

  /**
   * Get o ref para a base de dados onde o dicumento está guardado
   */
  getdocRef?(): DocumentReference;
}

export function covertTimestamp(value: null | Timestamp): null | Date {
  return value ? value.toDate() : null;
}

export function getMeta(utilizador: Utilizador) {
  const meta: Imeta = { createdAt: null, createdBy: { uid: null, nome: null } };
  meta.createdAt = serverTimestamp();
  meta.createdBy = {
    uid: utilizador.id,
    nome: utilizador.info.nome,
  };
  return meta;
}

import Vue from "vue";
import Vuetify from "vuetify/lib";
import touch from "vuetify/es5/directives/touch";

Vue.use(Vuetify, {
  directives: {
    touch,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#344955",
        secondary: "#F9AA33",
        tertiary: "#232F34",
        quaternary: "#4A6572",
        accent: "#F9AA33",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});

//export default new Vuetify({ preset });
// export default new Vuetify({
//   icons: {
//     iconfont: "mdi"
//   },
//   theme: {
//     dark: false,
//     themes: {
//       light: {
//         primary: "#344955",
//         secondary: "#F9AA33",
//         tertiary: "#232F34",
//         quaternary: "#4A6572",
//         accent: "#F9AA33",
//         error: "#FF5252",
//         info: "#2196F3",
//         success: "#4CAF50",
//         warning: "#FFC107"
//       }
//     }
//   }
// });

// export default new Vuetify({ ...options });

// export default new Vuetify({
//   theme: {
//     dark: false,
//     themes: {
//       light: {
//         primary: "#007991",
//         secondary: "#4ea8c1",
//         accent: "#ff9800",
//         error: "#FF5252",
//         info: "#2196F3",
//         success: "#4CAF50",
//         warning: "#FFC107"
//       },
//       dark: {}
//     }
//   }
// });

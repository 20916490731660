import { MORADA_COLLECTION, MORADA_LVL1, HISTORICO_COLLECTION } from "@/common/defs/collectionNames";
import {
  Query,
  DocumentReference,
  writeBatch,
  doc,
  getDocs,
  query,
  collection,
  orderBy,
  where,
  collectionGroup,
} from "firebase/firestore";

import { db } from "@/common/services/firebase";
import Morada from "@/modules/moradas/types/Morada";
import { getMeta } from "@/common/services/IfirestoreObject";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import { ActionContext } from "vuex";
import DBOperations from "@/common/services/DBOperations";
import Grupo from "@/modules/grupos/types/Grupo";

interface State {
  moradas: Morada[];
}

const state: State = {
  moradas: [],
};

const dbOperations = new DBOperations("Morada", {
  metaCollection: MORADA_LVL1,
  lvl1Name: MORADA_LVL1,
});

const getters = {
  //* Retorna array das moradas. ! NÃO É IMUTÁVEL !
  getMoradas(state: State) {
    return state.moradas;
  },
  getMoradasActivas(state: State) {
    return state.moradas.filter((morada) => morada.status.active);
  },

  getMorada: (state: State) => (id: string) => {
    return state.moradas.find((morada) => morada.id === id);
  },
};

const mutations = {
  clearStore(state: State) {
    state.moradas = [];
  },
};

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Morada } | { item: Morada[] } | Morada[];
}

const actions = {
  async loadMoradas(context: ActionContext<any, any>) {
    if (context.state.moradas.length === 0) {
      const grupo: Grupo = context.rootGetters["utilizadorStore/getGrupoUtilizadorLoggedIn"];

      const optionsBase = [];
      if (!grupo.permissoes.moradas.accessInactive) optionsBase.push(where("status.active", "==", true));
      const q = query(collection(db, MORADA_COLLECTION), ...optionsBase);
      const listenerBase = await context.dispatch("getListenerBase", { query: q });

      const querysLvls = [];
      if (grupo.permissoes.moradas.accessLvl1) {
        const optionsLvl = [];
        if (!grupo.permissoes.moradas.accessInactive) optionsLvl.push(where("status.active", "==", true));
        querysLvls.push(
          context.dispatch("getListenerLvl1", { query: query(collectionGroup(db, MORADA_LVL1), ...optionsLvl) })
        );
      }

      const res = await Promise.all(querysLvls);
      context.commit("addListener", listenerBase, { root: true });
      context.commit("addListener", res[0], { root: true });
    }
  },

  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerBase(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref ?? context.state.moradas, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerLvl1(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref ?? context.state.moradas, payload.query, "lvl1");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  //* Guarda um novo serviço na firebase.firestore
  async saveNewMorada(state: any, morada: Morada) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
    const batch = writeBatch(db);

    //Set Meta
    morada.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const moradaMini = morada.minimized();

    //Divide o serviço nos docs
    const base = {
      info: moradaMini.info,
      correspondencia: moradaMini.correspondencia,
      status: moradaMini.status,
      stats: moradaMini.stats,
    };

    const lvl1 = {
      meta: moradaMini.meta,
      status: moradaMini.status,
    };

    //Set o doc
    const docBase = doc(collection(db, MORADA_COLLECTION));
    batch.set(docBase, base);
    batch.set(doc(docBase, MORADA_LVL1, docBase.id), lvl1);

    //Set histórico
    const historicoItem = new HistoricoItem();
    historicoItem.setCreated();
    historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    batch.set(doc(collection(docBase, HISTORICO_COLLECTION)), historicoItem.minimized());

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docBase.id;
  },

  /**
   * Update o nome do utilizador na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { morada: Morada; historico: Morada }) {
    dbOperations
      .setOp(payload.morada)
      .update({
        base: [
          "info.linha1",
          "info.linha2",
          "info.linha3",
          "info.codigoPostal",
          "info.localidade",
          "info.pais",
          "info.pos.lat",
          "info.pos.lng",
          "correspondencia.isMoradaFaturacao",
          "correspondencia.isMoradaCorrespondencia",
        ],
      })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateStatusDB(_: any, payload: { morada: Morada; historico: Morada }) {
    dbOperations
      .setOp(payload.morada)
      .update({ base: ["status.active"] })
      .log(payload.historico)
      .update({ lvl1: ["status.active"] })
      .setMeta()
      .commit();
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, id: string) {
    const res: HistoricoItem[] = [];
    const q = collection(db, MORADA_COLLECTION, id, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  /**
   * Elimina permanentemente a morada na firebase.firestore
   */
  async deleteMoradaDB(state: any, morada: Morada) {
    const docBase = doc(db, MORADA_COLLECTION, morada.id);
    const batch = writeBatch(db);

    batch.delete(docBase);
    batch.delete(doc(docBase, MORADA_LVL1, morada.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

import { UTILIZADOR_COLLECTION, CLOSING_TILL_COLLECTION } from "../../../common/defs/collectionNames";
import Utilizador from "@/modules/utilizadores/types/Utilizador";
import { IfirestoreObject, covertTimestamp, Imeta } from "@/common/services/IfirestoreObject";
import { doc, DocumentReference } from "firebase/firestore";
import { db } from "@/common/services/firebase";
import Turno, { iShiftStats } from "./Turno";
import Pagamento from "@/modules/creditos/types/Pagamento";

interface InfoClosingTill {
  startDate: Date;
  endDate: Date;

  driver: Utilizador;
}

export default class ClosingTill implements IfirestoreObject {
  id?: string;

  //=============== BASE ======================\\
  info: InfoClosingTill = {
    startDate: null,
    endDate: null,

    driver: null,
  };

  shifts: Turno[] = [];
  payments: Pagamento[] = [];

  comments: string = null;

  stats: {
    shiftStats: iShiftStats;
    paymentStats: {
      total: {
        number: number;
        value: number;
      };
    };
  } = null;

  status: {
    canceled: boolean;
    active: boolean;
  } = { canceled: false, active: true };

  //=============== LvL 1======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  setData(json?: any) {
    this.info = {
      startDate: covertTimestamp(json.info.startDate),
      endDate: covertTimestamp(json.info.endDate),
      driver: json.info.driver ? new Utilizador(json.info.driver.id, json.info.driver) : null,
    };

    this.shifts = [];
    if (json.shifts) {
      for (const shift of json.shifts) this.shifts.push(new Turno(shift.id, shift));
    }

    this.payments = [];
    if (json.payments) {
      for (const payment of json.payments) this.payments.push(new Pagamento(payment.id, payment));
    }

    this.comments = json.comments ?? null;
    this.stats = json.stats ?? null;
    this.status = json.status;
  }

  setLvl1(json: any) {
    this.meta = {
      createdAt: json.meta.createdAt ? json.meta.createdAt.toDate() : null,
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no firebase.firestore
   */
  minimized() {
    const res: any = {
      id: this.id,

      info: {
        startDate: this.info.startDate,
        endDate: this.info.endDate,

        driver: this.info.driver ? this.info.driver.minimizedMini() : null,
      },

      shifts: this.shifts.map((shift) => shift.minimizedMini()),
      payments: this.payments.map((payment) => payment.minimizedMini()),

      comments: this.comments,
      stats: this.stats,
      status: this.status,
      meta: this.meta,
    };

    return res;
  }

  /**
   * Heldper - Get a localização do documento na firestore
   */
  getdocRef(): DocumentReference {
    const docBaseCliente = doc(db, UTILIZADOR_COLLECTION, this.info.driver.id);
    return doc(docBaseCliente, CLOSING_TILL_COLLECTION, this.id);
  }
}

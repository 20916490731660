const ViewPagamento = () =>
  import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/ViewPagamento.vue");
const PagamentoInfo = () =>
  import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/PagamentoInfo.vue");
const PagamentoConsultas = () =>
  import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/PagamentoConsultas.vue");
const PagamentoHistorico = () =>
  import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/PagamentoHistorico.vue");

const ViewConsulta = () => import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/ViewConsulta.vue");
const ConsultaInfo = () => import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/ConsultaInfo.vue");
const ConsultaServicos = () =>
  import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/ConsultaServicos.vue");
const ConsultaPagamentos = () =>
  import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/ConsultaPagamentos.vue");
const ConsultaHistorico = () =>
  import(/* webpackChunkName: "chunk-creditos" */ "@/modules/creditos/views/ConsultaHistorico.vue");

export const creditosRoutes = [
  {
    path: "/pagamentos/:id",
    name: "ViewPagamento",
    redirect: { name: "PagamentoInfo" },
    component: ViewPagamento,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["pagamentos.viewPagamento"],
    },
    children: [
      {
        path: "informacao",
        name: "PagamentoInfo",
        component: PagamentoInfo,
        meta: {
          appBarTitle: "Pagamento",
          requiresAuth: true,
          requires: ["pagamentos.viewPagamento"],
        },
      },
      {
        path: "servicos",
        name: "PagamentoConsultas",
        component: PagamentoConsultas,
        meta: {
          appBarTitle: "Pagamento",
          requiresAuth: true,
          requires: ["pagamentos.viewConsultas"],
        },
      },
      {
        path: "historico",
        name: "PagamentoHistorico",
        component: PagamentoHistorico,
        meta: {
          appBarTitle: "Pagamento",
          requiresAuth: true,
          requires: ["pagamentos.viewHistorico"],
        },
      },
    ],
  },

  {
    path: "/consultas/:id",
    name: "ViewConsulta",
    redirect: { name: "ConsultaInfo" },
    component: ViewConsulta,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["consultas.viewConsulta"],
    },
    children: [
      {
        path: "informacao",
        name: "ConsultaInfo",
        component: ConsultaInfo,
        meta: {
          appBarTitle: "Consulta",
          requiresAuth: true,
          requires: ["consultas.viewConsulta"],
        },
      },
      {
        path: "servicos",
        name: "ConsultaServicos",
        component: ConsultaServicos,
        meta: {
          appBarTitle: "Consulta",
          requiresAuth: true,
          requires: ["consultas.viewServicos"],
        },
      },
      {
        path: "pagamentos",
        name: "ConsultaPagamentos",
        component: ConsultaPagamentos,
        meta: {
          appBarTitle: "Consulta",
          requiresAuth: true,
          requires: ["consultas.viewPagamentos"],
        },
      },
      {
        path: "historico",
        name: "ConsultaHistorico",
        component: ConsultaHistorico,
        meta: {
          appBarTitle: "Consulta",
          requiresAuth: true,
          requires: ["consultas.viewHistorico"],
        },
      },
    ],
  },
];

import Utilizador from "@/modules/utilizadores/types/Utilizador";
import {
  UTILIZADOR_COLLECTION,
  UTILIZADOR_LVL1,
  UTILIZADOR_LVL2,
  VEICULO_COLLECTION,
  VEICULO_LVL1,
  VEICULO_LVL2,
  GRUPO_COLLECTION,
  GRUPO_LVL1,
  PARCEIRO_COLLECTION,
  PARCEIRO_LVL1,
} from "@/common/defs/collectionNames";
import { db } from "@/common/services/firebase";
import { collection, collectionGroup, where } from "firebase/firestore";
import { query } from "firebase/firestore";
import store from "@/common/store/store";
import Grupo from "@/modules/grupos/types/Grupo";

export default async function initData() {
  const listeners = [];
  const userLogedIn: Utilizador = await store.getters["utilizadorStore/getUtilizadorLoggedIn"];
  const grupo: Grupo = await store.getters["utilizadorStore/getGrupoUtilizadorLoggedIn"];
  const permissoes = grupo.permissoes;

  //GRUPOS
  if (permissoes.grupos.accessActive) {
    const accessInactive = permissoes.grupos.accessInactive;

    const baseQuery = getQuery(GRUPO_COLLECTION, accessInactive);
    listeners.push(store.dispatch("grupoStore/getListenerBase", { query: baseQuery }));

    if (permissoes.grupos.accessLvl1) {
      const lvl1Query = getLvlQuery(GRUPO_LVL1, accessInactive);
      listeners.push(store.dispatch("grupoStore/getListenerLvl1", { query: lvl1Query }));
    }
  }

  //UTILIZADORES
  if (permissoes.utilizadores.accessActive) {
    const accessInactive = permissoes.utilizadores.accessInactive;

    const baseQuery = getQuery(UTILIZADOR_COLLECTION, accessInactive);
    listeners.push(store.dispatch("utilizadorStore/getListenerBase", { query: baseQuery }));

    if (permissoes.utilizadores.accessLvl1) {
      const lvl1Query = getLvlQuery(UTILIZADOR_LVL1, accessInactive);
      listeners.push(store.dispatch("utilizadorStore/getListenerLvl1", { query: lvl1Query }));
    }
    if (permissoes.utilizadores.accessLvl2) {
      const lvl2Query = getLvlQuery(UTILIZADOR_LVL2, accessInactive);
      listeners.push(store.dispatch("utilizadorStore/getListenerLvl2", { query: lvl2Query }));
    }
  } else {
    //HACK Change this to get the document directly
    //Have to change DBoperations for the getListener to work with a single document

    //name is the value of FieldPath.documentId() that for some reason ew can't access
    //https://github.com/prescottprue/react-redux-firebase/issues/1117
    const baseQuery = query(
      collection(db, UTILIZADOR_COLLECTION),
      where("status.active", "==", true),
      where("__name__", "==", userLogedIn.id)
    );
    listeners.push(store.dispatch("utilizadorStore/getListenerBase", { query: baseQuery }));

    const lvl1Query = query(
      collection(db, UTILIZADOR_COLLECTION, userLogedIn.id, UTILIZADOR_LVL1),
      where("status.active", "==", true),
      where("__name__", "==", userLogedIn.id)
    );
    listeners.push(store.dispatch("utilizadorStore/getListenerLvl1", { query: lvl1Query }));
  }

  //Último turno
  if (permissoes.turnos.accessOwn || permissoes.turnos.accessOthers) {
    listeners.push(store.dispatch("turnoStore/getUltimoTurnoListener", userLogedIn.id));
  }

  //Parceiros
  if (permissoes.parceiros.accessActive) {
    const accessInactive = permissoes.parceiros.accessInactive;

    const baseQuery = getQuery(PARCEIRO_COLLECTION, accessInactive);
    listeners.push(store.dispatch("parceiroStore/getListenerBase", { query: baseQuery }));

    if (permissoes.parceiros.accessLvl1) {
      const lvl1Query = getLvlQuery(PARCEIRO_LVL1, accessInactive);
      listeners.push(store.dispatch("parceiroStore/getListenerLvl1", { query: lvl1Query }));
    }
  }

  //VEÍCULOS
  if (permissoes.veiculos.accessActive) {
    const accessInactive = permissoes.veiculos.accessInactive;

    const baseQuery = getQuery(VEICULO_COLLECTION, accessInactive);
    listeners.push(store.dispatch("veiculoStore/getListenerBase", { query: baseQuery }));

    if (permissoes.veiculos.accessLvl1) {
      const lvl1Query = getLvlQuery(VEICULO_LVL1, accessInactive);
      listeners.push(store.dispatch("veiculoStore/getListenerLvl1", { query: lvl1Query }));
    }
    if (permissoes.veiculos.accessLvl2) {
      const lvl2Query = getLvlQuery(VEICULO_LVL2, accessInactive);
      listeners.push(store.dispatch("veiculoStore/getListenerLvl2", { query: lvl2Query }));
    }
  }

  await Promise.all(listeners);
}

function getQuery(collectionName: string, accessInactive: boolean) {
  if (accessInactive) return query(collection(db, collectionName));
  else return query(collection(db, collectionName), where("status.active", "==", true));
}

function getLvlQuery(collectionName: string, accessInactive: boolean) {
  if (accessInactive) return query(collectionGroup(db, collectionName));
  else return query(collectionGroup(db, collectionName), where("status.active", "==", true));
}

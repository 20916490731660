import { Vue } from "vue-property-decorator";
import moment from "moment";
import {
  UTILIZADOR_COLLECTION,
  TURNO_COLLECTION,
  HISTORICO_COLLECTION,
  TURNO_LVL1,
  TURNO_POSITIONS,
  TURNO_STATS,
} from "@/common/defs/collectionNames";
import {
  query,
  writeBatch,
  doc,
  collection,
  orderBy,
  Query,
  DocumentReference,
  getDocs,
  limit,
  where,
} from "firebase/firestore";
import Turno from "../types/Turno";
import { db } from "@/common/services/firebase";
import DBOperations from "@/common/services/DBOperations";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import { getMeta } from "@/common/services/IfirestoreObject";
import { dbOperations as dbOperationsVeiculos } from "@/modules/veiculos/store/veiculoStore";
import { dbOperations as dbOperationsServicos } from "@/modules/servicos/store/servicoStore";
import Servico from "@/modules/servicos/types/Servico";
import { Capacitor as CapacitorCore } from "@capacitor/core";
import { TurnoNotificationPlugin } from "@/plugins/TurnoNotificationPlugin";
import { Position } from "../types/Position";
import ShiftEvent from "../types/ShiftEvent";

export const dbOperations = new DBOperations("Turno", {
  metaCollection: TURNO_LVL1,
  lvl1Name: TURNO_LVL1,
  statsName: TURNO_STATS,
});

export const dbPositionOperations = new DBOperations("Position", {
  metaCollection: TURNO_POSITIONS,
  lvl1Name: TURNO_POSITIONS,
});

interface State {
  //Só tem 1 sempre, mas como a query é limit(0) é tratada como um array
  ultimoTurno: Turno[];
}

const state: State = {
  ultimoTurno: [],
};

const getters = {
  getUltimoTurnoActivo(state: any) {
    const turno = state.ultimoTurno[0];
    if (turno && turno.info.estado !== "Fechado") return turno;
    else return null;
  },
};

const mutations = {};

interface IGetPayload {
  query: Query | DocumentReference;
  ref?: { item: Turno } | Turno[];
}

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Turno } | { item: Turno[] } | Turno[];
}

const actions = {
  /**
   * Get de uma só vez os documentos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsBase(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsBase(payload.ref, payload.query);
  },

  /**
   * Get de uma só vez os LVL1 dos documetos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsStats(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsLvl(payload.ref, payload.query, "stats");
  },

  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListener(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerLvl1(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref, payload.query, "lvl1");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerStats(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref, payload.query, "stats");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /** Gets the last shift that is active for the provided user, listens to changes and saves it on the state  */
  async getUltimoTurnoListener(context: any, motoristaId: string) {
    const queryTurno = query(
      collection(db, UTILIZADOR_COLLECTION, motoristaId, TURNO_COLLECTION),
      where("info.motorista.id", "==", motoristaId),
      where("status.active", "==", true),
      orderBy("info.dataInicio", "desc"),
      limit(1)
    );
    await context.dispatch("getListener", { query: queryTurno, ref: context.state.ultimoTurno });

    //Activate the listener on the mobile
    if (CapacitorCore.isNativePlatform()) {
      await TurnoNotificationPlugin.startTurnoListener({ userId: motoristaId });
    }
  },

  /** Gets positons of the shift */
  async getTurnoPositions(
    context: any,
    payload: { userId: string; turnoId: string; dateRange?: { start: Date; end: Date } }
  ) {
    const res: Position[] = [];
    const shiftRef = collection(db, UTILIZADOR_COLLECTION, payload.userId, TURNO_COLLECTION);
    const positionsRef = collection(shiftRef, payload.turnoId, TURNO_POSITIONS);

    const options: any[] = [];
    if (payload.dateRange) {
      options.push(where("timestamp", ">=", payload.dateRange.start), where("timestamp", "<=", payload.dateRange.end));
    }
    const docs = await getDocs(query(positionsRef, ...options, orderBy("timestamp", "asc")));
    docs.forEach((doc: any) => {
      res.push(new Position(doc.id, doc.data()));
    });
    return res;
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, payload: { userId: string; shiftId: string }) {
    const res: HistoricoItem[] = [];
    const docBaseCliente = doc(db, UTILIZADOR_COLLECTION, payload.userId);
    const q = collection(docBaseCliente, TURNO_COLLECTION, payload.shiftId, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  async iniciaTurno(state: any, turno: Turno) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
    const batch = writeBatch(db);

    //Set Meta
    turno.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const turnoMini = turno.minimized();
    const base = {
      info: turnoMini.info,
      shiftEvents: turnoMini.shiftEvents,
      activities: turnoMini.activities,
      expenses: turnoMini.expenses,
      status: turnoMini.status,
    };
    const lvl1 = {
      meta: turnoMini.meta,
      status: turnoMini.status,
      data: turnoMini.info.dataInicio,
      driverId: turnoMini.info.motorista.id,
      vehicleId: turnoMini.info.veiculo.id,
    };

    //Set o doc
    const utilizadorId = turno.info.motorista.id;
    const docBaseUtilizador = doc(db, UTILIZADOR_COLLECTION, utilizadorId);
    const docTurno = doc(collection(docBaseUtilizador, TURNO_COLLECTION));
    batch.set(docTurno, base);
    batch.set(doc(docTurno, TURNO_LVL1, docTurno.id), lvl1);

    //Update odómetro do veículo
    dbOperationsVeiculos
      .setOp(turno.info.veiculo, batch)
      .updateWithValues({
        lvl1: {
          "stats.kms": turno.info.kmsVeiculoIniciais,
          "stats.bandeirada": turno.info.bandeiradaInicial,
        },
      })
      .logCustom("Odometer updated", { kms: turno.info.kmsVeiculoIniciais, bandeirada: turno.info.bandeiradaInicial });

    //Set histórico
    const historicoItem = new HistoricoItem();
    historicoItem.setCreated();
    historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    historicoItem.setNotification({
      id: docTurno.id,
      title: `${turno.info.motorista.info.nome} iniciou o turno com o ${turno.info.veiculo.tipo.nome}`,
      textFields: [{ text: `às ${moment(turno.info.dataInicio).format("HH:mm")}` }],
      link: `/turnos/${docTurno.id}/?uId=/${utilizadorId}`,
    });
    batch.set(doc(collection(docTurno, HISTORICO_COLLECTION)), historicoItem.minimized());

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docTurno.id;
  },

  /**
   * Termina o turno e atualiza na firebase.firestore
   */
  async terminaTurno(_: any, payload: { turno: Turno; historico: Turno }) {
    const batch = dbOperations
      .setOp(payload.turno)
      .update({
        base: ["info.estado", "info.dataFim", "info.endPosition", "info.kmsVeiculoFinais", "info.bandeiradaFinal"],
      })
      .log(payload.historico, {
        title: `${payload.historico.info.motorista.info.nome} terminou o turno com o ${payload.turno.info.veiculo.tipo.nome}`,
        textFields: [{ text: `${Vue.filter("duration")(payload.turno.duration)} | ${payload.turno.kms} kms` }],
        link: `/turnos/${payload.turno.id}/?cId=/${payload.turno.info.motorista.id}`,
      })
      .setMeta()
      .getBatch();

    //Update veiculos
    dbOperationsVeiculos
      .setOp(payload.turno.info.veiculo, batch)
      .updateWithValues({
        lvl1: {
          "stats.kms": payload.turno.info.kmsVeiculoFinais,
          "stats.bandeirada": payload.turno.info.bandeiradaFinal,
        },
      })
      .logCustom("Odometer updated", {
        kms: payload.turno.info.kmsVeiculoFinais,
        bandeirada: payload.turno.info.bandeiradaFinal,
      });
    await batch.commit();

    //Generate the shift stats
    await payload.turno.generateShiftStats();
  },

  /**
   * Add pickup event to the shift
   */
  async addPickupEvent(state: any, payload: { turno: Turno; servico: Servico; historico: Servico }) {
    const batch = dbOperationsServicos
      .setOp(payload.servico)
      .update({ base: ["status.situacao"] })
      .log(payload.historico, {
        title: `${payload.turno.info.motorista.info.nome} recolheu o passageiro`,
        textFields: [{ text: `às ${moment().format("HH:mm")}` }],
        link: `/servicos/${payload.servico.id}`,
      })
      .update({ lvl1: ["status.situacao"], lvl2: ["status.situacao"], stats: ["status.situacao"] })
      .setMeta()
      .getBatch();

    dbOperations
      .setOp(payload.turno, batch)
      .update({ base: ["shiftEvents"] })
      .setMeta()
      .commit();
  },

  /*
   * Starts a new ongoing service
   */
  async addPracaEvent(state: any, payload: { turno: Turno }) {
    dbOperations
      .setOp(payload.turno)
      .update({ base: ["info.estado", "shiftEvents"] })
      .setMeta()
      .commit();
  },

  /*
   * Starts a new ongoing service
   */
  async iniciaServico(state: any, payload: { turno: Turno; servico: Servico }) {
    //Save serviço
    const serviceId = await state.dispatch("editServicoStore/saveNewServico", payload.servico, { root: true });

    //Add event, it has to be here because i need the service Id
    const newServiceEvent = new ShiftEvent("START_SERVICE");
    newServiceEvent.serviceId = serviceId;
    await newServiceEvent.setCurrentDataAndPosition();
    payload.turno.shiftEvents.push(newServiceEvent);

    //Save alteração ao estado do turno
    dbOperations
      .setOp(payload.turno)
      .update({ base: ["info.estado", "shiftEvents"] })
      .setMeta()
      .commit();
  },

  /*
   * Starts a new ongoing service
   */
  async startServicoAgendado(state: any, payload: { turno: Turno; servico: Servico; historico: Servico }) {
    const batch = dbOperationsServicos
      .setOp(payload.servico)
      .update({
        base: ["info.tipo", "status.situacao", "status.turnoId"],
        lvl1: ["infoMoto.veiculo"],
      })
      .log(payload.historico, {
        title: `${payload.turno.info.motorista.info.nome} iniciou o serviço agendado`,
        textFields: [{ text: `às ${moment().format("HH:mm")}` }],
        link: `/servicos/${payload.servico.id}`,
      })
      .update({
        lvl1: ["status.situacao", "status.turnoId"],
        lvl2: ["status.situacao", "status.turnoId"],
        stats: ["status.situacao", "status.turnoId"],
      })
      .setMeta()
      .getBatch();

    dbOperations
      .setOp(payload.turno, batch)
      .update({ base: ["info.estado", "shiftEvents"] })
      .setMeta()
      .commit();
  },

  /*
   * Starts a new ongoing service
   */
  async endService(state: any, payload: { turno: Turno; servico: Servico; historico: Servico }) {
    const batch = dbOperationsServicos
      .setOp(payload.servico)
      .update({
        base: [
          "info.valor",
          "info.condicaoPagamento",
          "info.metodoPagamento",
          "info.clienteResponsavel",
          "info.dadoFaturacao",
          "status.situacao",
        ],
        lvl1: ["despesas.portagens"],
      })
      .log(payload.historico, {
        title: `${payload.turno.info.motorista.info.nome} terminou o serviço`,
        textFields: [{ text: `às ${moment().format("HH:mm")}` }],
        link: `/servicos/${payload.servico.id}`,
      })
      .update({ lvl1: ["status.situacao"], lvl2: ["status.situacao"], stats: ["status.situacao"] })
      .setMeta()
      .getBatch();

    dbOperations
      .setOp(payload.turno, batch)
      .update({ base: ["info.estado", "shiftEvents"] })
      .setMeta()
      .commit();
  },

  /**
   * Update ao dados da consulta na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { turno: Turno; historico: Turno }) {
    dbOperations
      .setOp(payload.turno)
      .update({
        base: [
          "info.dataInicio",
          "info.dataFim",
          "info.kmsVeiculoIniciais",
          "info.kmsVeiculoFinais",
          "info.bandeiradaInicial",
          "info.bandeiradaFinal",
        ],
      })
      .log(payload.historico)
      .update({ lvl1: [{ data: "info.dataInicio" }], stats: [{ data: "info.dataInicio" }] })
      .setMeta()
      .commit();
  },

  async updateDespesasDB(_: any, payload: { turno: Turno; historico: Turno }) {
    dbOperations
      .setOp(payload.turno)
      .update({ base: ["expenses.realTolls"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update observações
   */
  async updateObservacaoDB(_: any, payload: { turno: Turno; historico: Turno }) {
    dbOperations
      .setOp(payload.turno)
      .update({ base: ["observacao"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o situação na firebase.firestore
   */
  updateSituacaoDB(_: any, payload: { shift: Turno; historico: Turno }) {
    const batch = dbOperations
      .setOp(payload.shift)
      .update({ base: ["info.estado"] })
      .log(payload.historico)
      .setMeta()
      .getBatch();

    batch.commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateStatusDB(_: any, payload: { turno: Turno; historico: Turno }) {
    const batch = dbOperations
      .setOp(payload.turno)
      .update({ base: ["status.active", "status.locked"] })
      .log(payload.historico)
      .update({
        lvl1: ["status.active", "status.locked"],
        stats: ["status.active", "status.locked"],
      })
      .setMeta()
      .getBatch();
    batch.commit();
  },

  /**
   * Elimina permanentemente a consulta na firebase.firestore
   */
  async deleteConsultaDB(state: any, turno: Turno) {
    const docBase = turno.getdocRef();
    const batch = writeBatch(db);
    batch.delete(docBase);
    batch.delete(doc(docBase, TURNO_LVL1, turno.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

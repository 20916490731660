import Veiculo from "@/modules/veiculos/types/Veiculo";
import { UTILIZADOR_COLLECTION } from "@/common/defs/collectionNames";
import { db } from "@/common/services/firebase";
import { DocumentReference, doc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "@/common/services/firebase";
import { IfirestoreObject, Imeta } from "@/common/services/IfirestoreObject";

export const listaCargos = ["Motorista", "RP Manager", "IT Manager", "Gestor Executivo"] as const;
type ListaCargos = (typeof listaCargos)[number];

export interface iDevice {
  id: string;
  ip: string;
  lastActivityAt: Date;
  status: "offline" | "online" | "away";
  platform: string;
  notificationToken: string;
}

export default class Utilizador implements IfirestoreObject {
  id?: string;
  avatar?: File; //Nesta variavel só guardo o FILE quando o user altera-o, não é guardada na DB
  avatarURL: string = null; //O Url do avatar é sempre utilizadores/uid/avatar
  password?: string; //nunca é guardada, só é usada quando o utilizador é criado

  //=============== BASE ======================\\
  info: {
    nome: string;
    avatar: string;
    cargo: ListaCargos;
  } = { nome: null, avatar: null, cargo: null };

  contactosEmpresa: {
    telemovel: string;
    extensao: string;
    email: string;
  } = { telemovel: null, extensao: null, email: null };

  status: {
    active: boolean;
  } = { active: true };

  //=============== Lvl1 ======================\\
  contactosPessoais: {
    telemovel: string;
    telefone: string;
    email: string;
    emergencia: { nome: string; contacto: string };
  } = {
    telemovel: null,
    telefone: null,
    email: null,
    emergencia: { nome: null, contacto: null },
  };

  settings: {
    veiculoPreferido: Veiculo;
    veiculosAutorizados: Veiculo[];
    notifications: {
      notifyOnAllServicosChanged: boolean;
      notifyTaxiServicesChanged: boolean;
      notifyOnOwnServicosChanged: boolean;
      notifyOnAllClientesChanged: boolean;
      notifyOnAllPagamentosChanged: boolean;
      notifyOnAllShiftsChanged: boolean;
      servicoFieldsToNotify: string[];
      clienteFieldsToNotify: string[];
      pagamentoFieldsToNotify: string[];
      shiftFieldsToNotify: string[];
    };
  } = {
    veiculoPreferido: null,
    veiculosAutorizados: [],
    notifications: {
      notifyOnAllServicosChanged: false,
      notifyTaxiServicesChanged: false,
      notifyOnAllClientesChanged: false,
      notifyOnOwnServicosChanged: false,
      notifyOnAllPagamentosChanged: false,
      notifyOnAllShiftsChanged: false,
      servicoFieldsToNotify: [],
      clienteFieldsToNotify: [],
      pagamentoFieldsToNotify: [],
      shiftFieldsToNotify: [],
    },
  };

  credenciais: {
    email: string;
    grupoId: string;
  } = { email: null, grupoId: null };

  presence: {
    devices: { [key: string]: iDevice };
    currentDevice: iDevice;
  } = {
    devices: null,
    currentDevice: null,
  };

  //=============== Lvl2 ======================\\
  salaryConditions: {
    base: number;
    mealAllowance: number;
    timeWaiver: number;
    hasVariableAllowancesAndExpenses: boolean;
    fixedAllowancesAndExpenses: number;
    commissionsPercentage: number;
    isComissionIncludedOnSalary: boolean;
  } = {
    base: 0,
    mealAllowance: 0,
    timeWaiver: 0,
    hasVariableAllowancesAndExpenses: false,
    fixedAllowancesAndExpenses: 0,
    commissionsPercentage: 0,
    isComissionIncludedOnSalary: false,
  };

  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  async getAvatar() {
    if (this.info.avatar) {
      const url = await getDownloadURL(ref(storage, this.info.avatar));
      this.avatarURL = url;
    }
  }

  setData(json: Partial<Utilizador>) {
    this.info = json.info;
    this.contactosEmpresa = json.contactosEmpresa;
    this.status = json.status;
  }

  setLvl1(json: any) {
    this.contactosPessoais = json.contactosPessoais;
    this.settings = {
      veiculoPreferido: null,
      veiculosAutorizados: [],
      notifications: json.settings.notifications ?? {
        notifyOnAllServicosChanged: json.settings?.notifications?.notifyOnAllServicosChanged ?? false,
        notifyTaxiServicesChanged: json.settings?.notifications?.notifyTaxiServicesChanged ?? false,
        notifyOnOwnServicosChanged: json.settings?.notifications?.notifyOnOwnServicosChanged ?? false,
        servicoFieldsToNotify: json.settings?.notifications?.servicoFieldsToNotify ?? [],
      },
    };

    if (json.settings.veiculoPreferido) {
      this.settings.veiculoPreferido = new Veiculo(json.settings.veiculoPreferido.id, json.settings.veiculoPreferido);
    }

    if (json.settings.veiculosAutorizados) {
      for (const veiculoJson of json.settings.veiculosAutorizados)
        this.settings.veiculosAutorizados.push(new Veiculo(veiculoJson.id, veiculoJson));
    }

    this.credenciais = json.credenciais;

    //Set current device
    if (json.presence?.currentDevice) {
      json.presence.currentDevice.lastActivityAt = json.presence.currentDevice.lastActivityAt.toDate();
      this.presence.currentDevice = json.presence.currentDevice;
    }

    //Set devices list
    if (json.presence?.devices) {
      for (const device of Object.values(json.presence.devices)) {
        (device as any).lastActivityAt = (device as any).lastActivityAt.toDate();
      }
      this.presence.devices = json.presence.devices;
    }
  }

  setLvl2(json: any) {
    this.meta = {
      createdAt: json.meta.createdAt ? json.meta.createdAt.toDate() : null,
      createdBy: json.meta.createdBy,
    };

    this.salaryConditions = {
      base: json.salaryConditions?.base ?? 0,
      mealAllowance: json.salaryConditions?.mealAllowance ?? 0,
      timeWaiver: json.salaryConditions?.timeWaiver ?? 0,
      hasVariableAllowancesAndExpenses: json.salaryConditions?.hasVariableAllowancesAndExpenses ? true : false,
      fixedAllowancesAndExpenses: json.salaryConditions?.fixedAllowancesAndExpenses ?? 0,
      commissionsPercentage: json.salaryConditions?.commissionsPercentage ?? 0,
      isComissionIncludedOnSalary: json.salaryConditions?.isComissionIncludedOnSalary ? true : false,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      info: this.info,
      contactosEmpresa: this.contactosEmpresa,
      status: this.status,

      contactosPessoais: this.contactosPessoais,
      settings: {
        veiculoPreferido: this.settings.veiculoPreferido ? this.settings.veiculoPreferido.minimizedMini() : null,
        veiculosAutorizados: this.settings.veiculosAutorizados.map((veiculo) => veiculo.minimizedMini()),
        notifications: this.settings.notifications,
        notifyOnOwnServicosChanged: false,
      },
      credenciais: this.credenciais,

      meta: this.meta,
      presence: this.presence,

      salaryConditions: this.salaryConditions,
    };
    return res;
  }

  /**
   * Minimiza o objecto / retorna só a info a duplicar noutro objecto na Firestore
   */
  minimizedMini() {
    return {
      id: this.id,
      info: {
        nome: this.info.nome,
        avatar: this.info.avatar,
      },
    };
  }

  getdocRef(): DocumentReference {
    return doc(db, UTILIZADOR_COLLECTION, this.id);
  }
}

import router from "@/common/router/router";
import { auth, db } from "./firebase";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/common/services/firebase";
import store from "../store/store";
import { Device } from "@capacitor/device";
import { signOut } from "firebase/auth";
import { clearIndexedDbPersistence, terminate } from "firebase/firestore";

import { TurnoNotificationPlugin } from "@/plugins/TurnoNotificationPlugin";
import { Capacitor as CapacitorCore } from "@capacitor/core";

export default async function logoutAndClear() {
  //Elimina os listeners
  store.commit("clearListeners");
  //Elimina todos os dados das stores
  store.dispatch("clearStores");

  //tem de ser por esta ordem para nao dar erros
  const deviceId = await Device.getId();
  //Faz o log do logout, tem de estar signed in para ter autorização
  //Nºao posso await porque pode dar erro e não faz o logout
  //Dá erro se for forced logout por já não ter o device
  httpsCallable(functions, "logLogout")({ deviceId: deviceId.identifier }); //Faz o log do logout, tem de estar signed in para ter autorização
  await signOut(auth);
  router.push({ name: "Login" });
  await store.dispatch("utilizadorStore/setUtilizadorLoggedIn", null);

  //Logout in the android app aswell
  if (CapacitorCore.isNativePlatform()) {
    TurnoNotificationPlugin.logout();
  }

  //Isto não é seguro por os podos podem ser recuperados mas é o melhor que temos
  //Só temos dados locais quando o user selecionar "manter-se conectado"
  await terminate(db); //Terminate a firestore
  await clearIndexedDbPersistence(db); //Clear local data

  //Tenho de fazer reload a página porque depois de fazer o terminate não consigo voltar a inicializar a firebase
  location.reload();
}

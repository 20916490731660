import { GRUPO_COLLECTION, HISTORICO_COLLECTION, GRUPO_LVL1 } from "@/common/defs/collectionNames";
import { Query, writeBatch, doc, getDocs, query, collection, orderBy, DocumentReference } from "firebase/firestore";

import { db } from "@/common/services/firebase";
import Grupo from "../types/Grupo";
import { getMeta } from "@/common/services/IfirestoreObject";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import DBOperations from "@/common/services/DBOperations";
import { ActionContext } from "vuex";

interface State {
  grupos: Grupo[];
}

const state: State = {
  grupos: [],
};

const dbOperations = new DBOperations("Grupo", {
  metaCollection: GRUPO_LVL1,
  lvl1Name: GRUPO_LVL1,
});

const getters = {
  //* Retorna array dos grupos. ! NÃO É IMUTÁVEL !
  getGrupos(state: State) {
    return state.grupos;
  },

  getGruposActivos(state: State) {
    return state.grupos.filter((grupos) => grupos.status.active);
  },

  //* retorn o grupo com o ID solicitado.
  getGrupo: (state: State) => (id: string) => {
    return state.grupos.find((grupo: Grupo) => grupo.id === id);
  },
};

const mutations = {
  clearStore(state: State) {
    state.grupos = [];
  },
};

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Grupo } | { item: Grupo[] } | Grupo[];
}

const actions = {
  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerBase(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref ?? context.state.grupos, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerLvl1(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref ?? context.state.grupos, payload.query, "lvl1");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  //* Guarda o grupo na firebase.firestore
  async saveNewGrupo(state: any, grupo: Grupo) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];

    const batch = writeBatch(db);

    //Set Meta
    grupo.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const grupoMini = grupo.minimized();

    const base = {
      info: grupoMini.info,
      permissoes: grupoMini.permissoes,
      status: grupoMini.status,
    };
    const lvl1 = { meta: grupoMini.meta, status: grupoMini.status };

    //Set o doc
    const docBase = doc(collection(db, GRUPO_COLLECTION));
    batch.set(docBase, base);
    batch.set(doc(docBase, GRUPO_LVL1, docBase.id), lvl1);

    //Set histórico
    const historicoItem = new HistoricoItem();
    historicoItem.setCreated();
    historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    batch.set(doc(collection(docBase, HISTORICO_COLLECTION)), historicoItem.minimized());

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docBase.id;
  },

  /**
   * Update ao dados do veículo na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { grupo: Grupo; historico: Grupo }) {
    dbOperations
      .setOp(payload.grupo)
      .update({ base: ["info.nome", "info.descricao"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateStatusDB(_: any, payload: { grupo: Grupo; historico: Grupo }) {
    dbOperations
      .setOp(payload.grupo)
      .update({ base: ["status.active"] })
      .log(payload.historico)
      .update({ lvl1: ["status.active"] })
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updatePermissoesDB(_: any, payload: { grupo: Grupo; historico: Grupo }) {
    //itera as permissões e colocas no formato permissoes.categoria.permissao
    const arrayEntriesDasPermissoes: string[] = [];
    const entries = Object.entries(payload.grupo.permissoes);
    entries.forEach((categoria) => {
      const nomeCategoria = categoria[0];
      const listaPermissoesDaCategoria = Object.entries(categoria[1]);
      listaPermissoesDaCategoria.forEach((permissao) => {
        const nomePermissao = permissao[0];
        arrayEntriesDasPermissoes.push(`permissoes.${nomeCategoria}.${nomePermissao}`);
      });
    });

    dbOperations
      .setOp(payload.grupo)
      .update({ base: [...arrayEntriesDasPermissoes] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, id: string) {
    const res: HistoricoItem[] = [];
    const q = collection(db, GRUPO_COLLECTION, id, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  /**
   * Elimina permanentemente a veiculo na firebase.firestore
   */
  async deleteGrupoDB(state: any, grupo: Grupo) {
    const docBase = doc(db, GRUPO_COLLECTION, grupo.id);
    const batch = writeBatch(db);

    batch.delete(docBase);
    batch.delete(doc(docBase, GRUPO_LVL1, grupo.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

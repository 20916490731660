import { VEICULO_COLLECTION, VEICULO_LVL1, VEICULO_LVL2, HISTORICO_COLLECTION } from "@/common/defs/collectionNames";
import { Query, query, getDocs, writeBatch, doc, collection, orderBy, DocumentReference } from "firebase/firestore";

import { db } from "@/common/services/firebase";
import Veiculo from "../types/Veiculo";
import { getMeta } from "@/common/services/IfirestoreObject";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import DBOperations from "@/common/services/DBOperations";
import { ActionContext } from "vuex";

interface State {
  veiculos: Veiculo[];
}

const state: State = {
  veiculos: [],
};

export const dbOperations = new DBOperations("Veiculo", {
  metaCollection: VEICULO_LVL2,
  lvl1Name: VEICULO_LVL1,
  lvl2Name: VEICULO_LVL2,
});

const getters = {
  //* Retorna array dos veículos. ! NÃO É IMUTÁVEL !
  getVeiculos(state: State) {
    return state.veiculos;
  },

  //* Retorna array dos veículos activos. ! NÃO É IMUTÁVEL !
  getVeiculosActivos(state: State) {
    return state.veiculos.filter((veiculo) => veiculo.status.active);
  },

  //* retorna o veículo com o ID solicitado. ! NÃO É IMUTÁVEL !
  getVeiculo: (state: State) => (id: string) => {
    return state.veiculos.find((veiculo) => veiculo.id === id);
  },
};

const mutations = {
  clearStore(state: State) {
    state.veiculos = [];
  },
};

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Veiculo } | { item: Veiculo[] } | Veiculo[];
}

async function processLvlListener(context: ActionContext<any, any>, payload: IListenerPayload, lvl: string) {
  const listener = await dbOperations.getListenerLvl(payload.ref ?? context.state.veiculos, payload.query, lvl);
  context.commit("addListener", listener, { root: true });
  return listener;
}

const actions = {
  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerBase(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref ?? context.state.veiculos, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerLvl1(context: ActionContext<any, any>, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl1");
  },

  async getListenerLvl2(context: ActionContext<any, any>, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl2");
  },

  async saveNewVeiculo(state: any, veiculo: Veiculo) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
    const batch = writeBatch(db);

    //Set Meta
    veiculo.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const veiculoMini = veiculo.minimized();

    const base = { info: veiculoMini.info, status: veiculoMini.status };
    const lvl1 = { tipo: veiculoMini.tipo, status: veiculoMini.status };
    const lvl2 = { meta: veiculoMini.meta, status: veiculoMini.status };

    //Set o doc
    const docBase = doc(collection(db, VEICULO_COLLECTION));
    batch.set(docBase, base);
    batch.set(doc(docBase, VEICULO_LVL1, docBase.id), lvl1);
    batch.set(doc(docBase, VEICULO_LVL2, docBase.id), lvl2);

    //Set histórico
    const historicoItem = new HistoricoItem();
    historicoItem.setCreated();
    historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    batch.set(doc(collection(docBase, HISTORICO_COLLECTION)), historicoItem.minimized());

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docBase.id;
  },

  /**
   * Update ao dados do veículo na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { veiculo: Veiculo; historico: Veiculo }) {
    dbOperations
      .setOp(payload.veiculo)
      .update({
        base: ["info.marca", "info.modelo", "info.matricula", "info.tipo"],
        lvl1: ["tipo.nome", "tipo.classe"],
      })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateStatusDB(_: any, payload: { veiculo: Veiculo; historico: Veiculo }) {
    dbOperations
      .setOp(payload.veiculo)
      .update({ base: ["status.active"] })
      .log(payload.historico)
      .update({ lvl1: ["status.active"], lvl2: ["status.active"] })
      .setMeta()
      .commit();
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, id: string) {
    const res: HistoricoItem[] = [];
    const q = collection(db, VEICULO_COLLECTION, id, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  /**
   * Elimina permanentemente a veiculo na firebase.firestore
   */
  async deleteVeiculoDB(state: any, veiculo: Veiculo) {
    const docBase = doc(db, VEICULO_COLLECTION, veiculo.id);
    const batch = writeBatch(db);

    batch.delete(docBase);
    batch.delete(doc(docBase, VEICULO_LVL1, veiculo.id));
    batch.delete(doc(docBase, VEICULO_LVL2, veiculo.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

import { TipoDeVeiculo } from "@/modules/veiculos/types/Veiculo";
import { SERVICO_COLLECTION } from "@/common/defs/collectionNames";
import { db } from "@/common/services/firebase";
import { doc } from "firebase/firestore";
import { DocumentReference } from "firebase/firestore";
import { IfirestoreObject, Imeta, covertTimestamp } from "@/common/services/IfirestoreObject";

import Morada from "@/modules/moradas/types/Morada";
import Cliente from "@/modules/clientes/types/Cliente";
import Veiculo from "@/modules/veiculos/types/Veiculo";
import Utilizador from "@/modules/utilizadores/types/Utilizador";
import Parceiro from "@/modules/parceiros/types/Parceiro";
import DadoFaturacao from "@/modules/clientes/types/DadoFaturacao";
import ShiftEvent from "@/modules/turnos/types/ShiftEvent";

export const tipoServico = [
  "Serviço de rua",
  "Serviço de praça",
  "Rádio táxis",
  "Serviço interno",
  "Cliente particular",
] as const;

export const condicaoPagamento = ["Pronto pagamento", "Crédito"] as const;
export const metodoPagamento = ["Numerário", "Multibanco", "MB Way"] as const;

export type TipoServico = (typeof tipoServico)[number];
type CondicaoPagamento = (typeof condicaoPagamento)[number];
type MetodoPagamento = (typeof metodoPagamento)[number];

interface Iinfo {
  tipo: TipoServico;
  data: Date;
  clienteResponsavel: Cliente;
  dadoFaturacao: DadoFaturacao;
  passageiro: { nome: string; contacto: string };
  nPax: number;
  numeroOvos: number;
  numeroCadeirasTipo2: number;
  numeroCadeirasTipo3: number;
  tipoDeVeiculo: TipoDeVeiculo;
  voo: string;
  origem: Morada;
  destino: Morada;
  descricao: string;
  valor: number;
  condicaoPagamento: CondicaoPagamento;
  metodoPagamento: MetodoPagamento;
}

export default class Servico implements IfirestoreObject {
  id?: string;

  //======== BASE ==========\\
  info: Iinfo = {
    tipo: "Serviço interno",
    data: null,
    clienteResponsavel: null,
    dadoFaturacao: null,
    passageiro: { nome: null, contacto: null },
    nPax: null,
    numeroOvos: 0,
    numeroCadeirasTipo2: 0,
    numeroCadeirasTipo3: 0,
    tipoDeVeiculo: null,
    voo: null,
    origem: null,
    destino: null,
    descricao: null,
    valor: null,
    condicaoPagamento: null,
    metodoPagamento: null,
  };

  //Uso esta variavel para poder mostrar só os serviços deste motorista.
  //Na firestore rules com este ID não tenho de fazer mais 1 acesso ao LVL1 sempre que for feita uma query aos serviços
  //Guardo no minimized()
  motoristaId: string = null;

  status: {
    turnoId: string;
    consultaId: string;
    situacao: "agendado" | "enviado" | "confirmado" | "iniciado" | "em curso" | "concluido";
    cancelado: boolean;
    locked: boolean;
    active: boolean;
  } = {
    turnoId: null,
    consultaId: null,
    situacao: "agendado",
    cancelado: false,
    locked: false,
    active: true,
  };

  //========= Lvl1 ==========\\
  infoMoto: {
    isParceiro: boolean;
    motorista: Utilizador | Parceiro;
    veiculo: Veiculo;
  } = {
    isParceiro: false,
    motorista: null,
    veiculo: null,
  };

  despesas: {
    portagens: number;
    partners: number;
    commission: number;
  } = {
    portagens: null,
    partners: null,
    commission: null,
  };

  observacoes: string = null;

  //======== Lvl2 ==========\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  stats: {
    duration: {
      total: 0;
      driving: 0;
      waiting: 0;
      withPassengers: 0;
    };
    distance: {
      total: 0;
      withPassengers: 0;
    };
  } = null;
  processedServiceEvents: ShiftEvent[] = null;

  //============================================ Métodos ====================================================\\
  /**
   * Construtor
   */
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  get friendlyName() {
    return {
      "info.data": { name: "Data", changed: "A data foi alterada" },
      "info.clienteResponsavel": { name: "Cliente responsável", changed: "O cliente responsável foi alterado" },
      "info.dadoFaturacao": { name: "Dados de faturação", changed: "Os dados de faturação foram alterados" },

      "info.passageiro.nome": { name: "Nome do passageiro", changed: "O nome do passageiro foi alterado" },
      "info.passageiro.contacto": { name: "Contacto do passageiro", changed: "O contacto do passageiro foi alterado" },
      "info.nPax": { name: "Número de passageiros", changed: "O número de passageiros foi alterado" },
      "info.numeroOvos": { name: "Número de ovos", changed: "O Número de ovos foi alterado" },
      "info.numeroCadeirasTipo2": { name: "Número de cadeiras", changed: "O número de cadeiras foi alterado" },
      "info.numeroCadeirasTipo3": { name: "Número de boosters", changed: "O número de boosters foi alterado" },

      "info.tipoDeVeiculo": { name: "Tipo de veículo", changed: "O tipo de veículo foi alterado" },
      "info.voo": { name: "Voo", changed: "O voo foi alterado" },
      "info.origem": { name: "Morada de origem", changed: "A morada de origem foi alterada" },
      "info.destino": { name: "Morada de destino", changed: "A morada de destino foi alterada" },

      "info.valor": { name: "Preço", changed: "O preço foi alterado" },
      "info.condicaoPagamento": { name: "Condição de pagamento", changed: "A condição de pagamento foi alterada" },
      "info.metodoPagamento": { name: "Método de pagamento", changed: "O método de pagamento foi alterado" },

      "info.descricao": { name: "Descrição", changed: "A descrição foi alterada" },

      "infoMoto.motorista": { name: "Motorista", changed: "O motorista foi alterado" },
      "infoMoto.veiculo": { name: "Veículo", changed: "O veículo foi alterado" },

      "despesas.portagens": { name: "Portagens", changed: "A portagem foi alterada" },
      "despesas.partners": { name: "Valor dos parceiros", changed: "O valor dos parceiros foi alterado" },
      "despesas.commission": { name: "Comissões", changed: "O valor das comissão foi alterada" },

      observacoes: { name: "Observações", changed: "As observações foram alteradas" },

      "status.cancelado": {
        name: "Cancelado",
        changed: `O serviço foi ${this.status.cancelado ? "cancelado" : "restaurado"}`,
      },
      "status.active": {
        name: "Ativo",
        changed: `O serviço foi ${this.status.active ? "ativado" : "desativado"}`,
      },
      "status.situacao": {
        name: "Situação",
        changed: `O serviço ${this.status.situacao === "em curso" ? "está" : "foi"} ${this.status.situacao}`,
      },
    };
  }

  get hasChildSeats() {
    return this.info.numeroOvos > 0 || this.info.numeroCadeirasTipo2 > 0 || this.info.numeroCadeirasTipo3 > 0;
  }

  /**
   * Set base data, divido lvls porque caso contrário teria de fazer check a todas as propriedades por undefined.
   */
  setData(json?: any) {
    this.info = {
      tipo: json.info.tipo,
      data: covertTimestamp(json.info.data),
      clienteResponsavel: null,
      dadoFaturacao: null,
      passageiro: json.info.passageiro,
      nPax: json.info.nPax,
      numeroOvos: json.info.numeroOvos,
      numeroCadeirasTipo2: json.info.numeroCadeirasTipo2,
      numeroCadeirasTipo3: json.info.numeroCadeirasTipo3,
      tipoDeVeiculo: json.info.tipoDeVeiculo ? json.info.tipoDeVeiculo : null,
      voo: json.info.voo,
      origem: null,
      destino: null,
      descricao: json.info.descricao,
      valor: json.info.valor,
      condicaoPagamento: json.info.condicaoPagamento,
      metodoPagamento: json.info.metodoPagamento,
    };

    if (json.info.clienteResponsavel)
      this.info.clienteResponsavel = new Cliente(json.info.clienteResponsavel.id, json.info.clienteResponsavel);
    if (json.info.dadoFaturacao) this.info.dadoFaturacao = new DadoFaturacao(json.info.dadoFaturacao);
    if (json.info.origem) this.info.origem = new Morada(json.info.origem.id, json.info.origem);
    if (json.info.destino) this.info.destino = new Morada(json.info.destino.id, json.info.destino);

    this.motoristaId = json.motoristaId;
    this.status = json.status;
  }

  /**
   * Ser lvl1 Data
   */
  setLvl1(json?: any) {
    this.infoMoto = {
      isParceiro: json.infoMoto.isParceiro,
      motorista: null,
      veiculo: null,
    };

    if (json.infoMoto.motorista)
      if (this.infoMoto.isParceiro)
        this.infoMoto.motorista = new Parceiro(json.infoMoto.motorista.id, json.infoMoto.motorista);
      else this.infoMoto.motorista = new Utilizador(json.infoMoto.motorista.id, json.infoMoto.motorista);

    if (json.infoMoto.veiculo) {
      this.infoMoto.veiculo = new Veiculo(json.infoMoto.veiculo.id, json.infoMoto.veiculo);
      this.infoMoto.veiculo.setLvl1(json.infoMoto.veiculo);
    }

    this.despesas = {
      portagens: json.despesas?.portagens ?? null,
      partners: json.despesas?.partners ?? null,
      commission: json.despesas?.commission ?? null,
    };

    this.observacoes = json.observacoes;
  }

  /**
   * Ser lvl2 Data
   */
  setLvl2(json?: any) {
    this.meta = {
      createdAt: covertTimestamp(json.meta.createdAt),
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Set stats
   */
  setStats(json: any) {
    if (!json) return;
    this.stats = json.stats;
    if (json.events) {
      this.processedServiceEvents = [];
      for (const shiftEvent of json.events) this.processedServiceEvents.push(new ShiftEvent(null, shiftEvent));
    }
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res: any = {
      //--------- Base ----------\\
      id: this.id,

      info: {
        tipo: this.info.tipo,
        data: this.info.data,

        clienteResponsavel: this.info.clienteResponsavel ? this.info.clienteResponsavel.minimizedMini() : null,
        dadoFaturacao: this.info.dadoFaturacao ? this.info.dadoFaturacao.minimized() : null,

        origem: this.info.origem ? this.info.origem.minimizedMini() : null,
        destino: this.info.destino ? this.info.destino.minimizedMini() : null,

        passageiro: this.info.passageiro,
        nPax: this.info.nPax,
        numeroOvos: this.info.numeroOvos,
        numeroCadeirasTipo2: this.info.numeroCadeirasTipo2,
        numeroCadeirasTipo3: this.info.numeroCadeirasTipo3,
        tipoDeVeiculo: this.info.tipoDeVeiculo,
        voo: this.info.voo,
        descricao: this.info.descricao,
        valor: this.info.valor,
        condicaoPagamento: this.info.condicaoPagamento,
        metodoPagamento: this.info.metodoPagamento,
      },

      motoristaId: this.infoMoto.motorista ? this.infoMoto.motorista.id : null,
      status: this.status,

      //--------- Lvl1 ----------\\
      infoMoto: {
        isParceiro: this.infoMoto.isParceiro,
        motorista: this.infoMoto.motorista ? this.infoMoto.motorista.minimizedMini() : null,
        veiculo: this.infoMoto.veiculo ? this.infoMoto.veiculo.minimizedMini() : null,
      },

      despesas: {
        portagens: this.despesas.portagens,
        partners: this.despesas.partners,
        commission: this.despesas.commission,
      },

      observacoes: this.observacoes,

      //--------- Lvl2 ----------\\
      meta: this.meta,
    };
    return res;
  }

  getdocRef(): DocumentReference {
    return doc(db, SERVICO_COLLECTION, this.id);
  }

  /**
   * Retuns a array of strings with the missing data or false otherwise
   */
  missingData() {
    if (this.status.cancelado) return false;

    const res: string[] = [];
    if (!this.infoMoto.veiculo && !this.infoMoto.isParceiro) res.push("Falta o veículo");
    if (!this.info.condicaoPagamento) res.push("Falta a condição de pagamento");
    if (!this.info.valor) res.push("Falta o valor");

    if (res.length > 0) return res;
    else return false;
  }

  missingDataAdmin() {
    if (this.status.cancelado) return false;

    const res: string[] = [];
    if (!this.info.tipoDeVeiculo) res.push("Falta o tipo de veículo pedido");
    if (!this.infoMoto.motorista) res.push("Falta o motorista");
    if (!this.infoMoto.veiculo && !this.infoMoto.isParceiro) res.push("Falta o veículo");
    if (!this.info.condicaoPagamento) res.push("Falta a condição de pagamento");
    if (!this.info.valor) res.push("Falta o valor");
    if (this.infoMoto.isParceiro && this.infoMoto.veiculo) res.push("Não pode ter um veículo se for um parceiro");
    if (this.infoMoto.isParceiro && !this.despesas.partners) res.push("Falta o preço do parceiro");

    if (res.length > 0) return res;
    else return false;
  }
}

const UtilizadorOverview = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/UtilizadorOverview.vue");
const ListaUtilizadores = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/ListaUtilizadores.vue");
const NewUtilizador = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/EditUtilizadorPlain.vue");
const ViewUtilizador = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/ViewUtilizador.vue");
const UtilizadorInfo = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/UtilizadorInfo.vue");
const UtilizadorDefs = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/UtilizadorDefs.vue");
const UtilizadorServicos = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/UtilizadorServicos.vue");
const UserRegister = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/UserRegister.vue");
const UserStats = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/UserStats.vue");
const UtilizadorHistorico = () =>
  import(/* webpackChunkName: "chunk-utilizadores" */ "@/modules/utilizadores/views/UtilizadorHistorico.vue");

export const utilizadoresRoutes = [
  {
    path: "/utilizadores/overview",
    name: "UtilizadorOverview",
    component: UtilizadorOverview,
    meta: {
      requiresAuth: true,
      requires: ["utilizadores.viewOverview"],
    },
  },
  {
    path: "/utilizadores",
    name: "ListaUtilizadores",
    component: ListaUtilizadores,
    meta: {
      requiresAuth: true,
      requires: ["utilizadores.viewLista"],
    },
  },
  {
    path: "/utilizadores/novo",
    name: "NewUtilizador",
    component: NewUtilizador,
    meta: {
      requiresAuth: true,
      requires: ["utilizadores.create"],
    },
  },
  {
    path: "/utilizador/:id",
    name: "ViewUtilizador",
    redirect: { name: "UtilizadorInfo" },
    component: ViewUtilizador,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["utilizadores.viewUtilizador"],
    },
    children: [
      {
        path: "stats",
        name: "UserStats",
        component: UserStats,
        meta: {
          appBarTitle: "Utilizador",
          requiresAuth: true,
          requires: ["utilizadores.viewStats"],
        },
      },
      {
        path: "informacao",
        name: "UtilizadorInfo",
        component: UtilizadorInfo,
        meta: {
          appBarTitle: "Utilizador",
          requiresAuth: true,
          requires: ["utilizadores.viewUtilizador"],
        },
      },
      {
        path: "definicoes",
        name: "UtilizadorDefs",
        component: UtilizadorDefs,
        meta: {
          appBarTitle: "Utilizador",
          requiresAuth: true,
          requires: ["utilizadores.viewDefinicoes"],
        },
      },
      {
        path: "servicos",
        name: "UtilizadorServicos",
        component: UtilizadorServicos,
        meta: {
          appBarTitle: "Utilizador",
          requiresAuth: true,
          requires: ["utilizadores.viewServicos"],
        },
      },
      {
        path: "register",
        name: "UserRegister",
        component: UserRegister,
        meta: {
          appBarTitle: "Utilizador",
          requiresAuth: true,
          requires: ["utilizadores.viewRegister"],
        },
      },
      {
        path: "historico",
        name: "UtilizadorHistorico",
        component: UtilizadorHistorico,
        meta: {
          appBarTitle: "Utilizador",
          requiresAuth: true,
          requires: ["utilizadores.viewHistorico"],
        },
      },
    ],
  },
];

import Vue from "vue";
import Vuex from "vuex";

import servicoStore from "@/modules/servicos/store/servicoStore";
import editServicoStore from "@/modules/servicos/store/editServicoStore";

import clienteStore from "@/modules/clientes/store/clienteStore";
import editClienteStore from "@/modules/clientes/store/editClienteStore";
import consultaStore from "@/modules/creditos/store/consultaStore";
import pagamentoStore from "@/modules/creditos/store/pagamentoStore";

import moradaStore from "@/modules/moradas/store/moradaStore";

import veiculoStore from "@/modules/veiculos/store/veiculoStore";

import utilizadorStore from "@/modules/utilizadores/store/utilizadorStore";
import parceiroStore from "@/modules/parceiros/store/parceiroStore";
import grupoStore from "@/modules/grupos/store/grupoStore";

import turnoStore from "@/modules/turnos/store/turnoStore";
import closingTillStore from "@/modules/turnos/store/closingTillStore";

//Inicializar o Vuex
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    listaListenersActivos: [],
  },

  getters: {},

  mutations: {
    addListener(state: any, listener: any) {
      state.listaListenersActivos.push(listener);
    },
    clearListeners(state: any) {
      state.listaListenersActivos.forEach((listener: any) => {
        listener();
      });
    },
  },

  actions: {
    //Elimina todos os dados das stores
    clearStores({ commit }) {
      commit("grupoStore/clearStore");
      commit("veiculoStore/clearStore");
      commit("utilizadorStore/clearStore");
      commit("parceiroStore/clearStore");
      commit("moradaStore/clearStore");
      commit("clienteStore/clearStore");
      commit("editClienteStore/resetCliente");
      commit("editServicoStore/resetServico");

      //Servico store não tem state
      //Consulta store não tem state
      //Pagamento store não tem state
    },
  },
  modules: {
    closingTillStore,
    turnoStore,

    servicoStore,
    editServicoStore,

    clienteStore,
    editClienteStore,
    consultaStore,
    pagamentoStore,

    moradaStore,
    veiculoStore,
    utilizadorStore,
    grupoStore,
    parceiroStore,
  },
});

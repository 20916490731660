
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

import moment from "moment";
import Utilizador from "@/modules/utilizadores/types/Utilizador";
import Grupo from "@/modules/grupos/types/Grupo";

import {
  mdiMenu,
  mdiBell,
  mdiExitToApp,
  mdiDirectionsFork,
  mdiBriefcaseClockOutline,
  mdiAccountCircleOutline,
  mdiMapMarker,
  mdiBadgeAccountHorizontalOutline,
  mdiHandshakeOutline,
  mdiLockOpenOutline,
  mdiCar,
  mdiAccount,
  mdiShieldCrownOutline,
  mdiChartBoxOutline,
} from "@mdi/js";
import logoutAndClear from "../services/logoutAndClear";

const UtilizadorStore = namespace("utilizadorStore");

@Component
export default class App extends Vue {
  icons = {
    mdiMenu,
    mdiBell,
    mdiExitToApp,
    mdiBadgeAccountHorizontalOutline,
    mdiAccount,
    mdiShieldCrownOutline,
    mdiChartBoxOutline,
  };

  isDrawerOpen = false;
  isDrawerMini = false;

  isUserMenuOpen = false;
  isLogingout = false;

  @UtilizadorStore.Getter getUtilizadorLoggedIn: Utilizador;
  @UtilizadorStore.Getter getGrupoUtilizadorLoggedIn: Grupo;

  onNavDrawerClick() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.isDrawerMini = !this.isDrawerMini;
    } else {
      this.isDrawerMini = false;
      this.isDrawerOpen = !this.isDrawerOpen;
    }
  }

  get version() {
    return process.env.VUE_APP_VERSION;
  }

  goToPerfil() {
    this.isUserMenuOpen = false;
    this.$router.push({ name: "ViewUtilizador", params: { id: this.getUtilizadorLoggedIn.id } });
  }

  get appBarTitle() {
    return this.$route.meta.appBarTitle;
  }

  get menuItems() {
    const grupo = this.getGrupoUtilizadorLoggedIn;
    if (!grupo) return [];
    const permissoes = grupo.permissoes;
    const itemsMenu = [
      {
        action: mdiDirectionsFork,
        title: "Serviços",
        active: false,
        show: permissoes.servicos.viewAgenda || permissoes.servicos.viewLista || permissoes.servicos.create,
        items: [
          {
            title: "Agenda",
            to: {
              name: "Agenda",
              params: { data: moment().format("DD-MM-YYYY") },
            },
            show: permissoes.servicos.viewAgenda,
          },
          {
            title: "Lista de serviços",
            to: { name: "ListaServicos" },
            show: permissoes.servicos.viewLista,
          },
          {
            title: "Novo serviço",
            to: { name: "NewServico" },
            show: permissoes.servicos.create || permissoes.servicos.createTimeLimited,
          },
        ],
      },
      {
        action: mdiBriefcaseClockOutline,
        title: "Turnos",
        active: false,
        show: permissoes.turnos.viewTurno || permissoes.turnos.viewLista,
        items: [
          {
            title: "Turno Actual",
            to: { name: "TurnoMain" },
            show: permissoes.turnos.create && this.getUtilizadorLoggedIn.info.cargo == "Motorista",
          },
          {
            title: "Lista de turnos",
            to: { name: "ListaTurnos" },
            show: permissoes.turnos.viewLista,
          },
          {
            title: "Criar folha de caixa",
            to: { name: "NewClosingTill" },
            show:
              permissoes.turnos.createClosingTill &&
              (this.getUtilizadorLoggedIn.info.cargo == "Motorista" || permissoes.turnos.createClosingTillOthers),
          },
          {
            title: "Lista de folhas de caixa",
            to: { name: "ClosingTillList" },
            show: permissoes.turnos.createClosingTill,
          },
        ],
      },
      {
        action: mdiAccountCircleOutline,
        title: "Clientes",
        active: false,
        show: permissoes.clientes.viewLista || permissoes.clientes.create,
        items: [
          {
            title: "Lista de clientes",
            to: { name: "ListaClientes" },
            show: permissoes.clientes.viewLista,
          },
          {
            title: "Novo cliente",
            to: { name: "NewCliente" },
            show: permissoes.clientes.create,
          },
        ],
      },
      {
        action: mdiMapMarker,
        title: "Moradas",
        active: false,
        //Só mostro com o viewMorada e o create porque a morada pode ser criada com o cliente e não quero vejam este menu
        show: permissoes.moradas.viewLista || (permissoes.moradas.viewMorada && permissoes.moradas.create),
        items: [
          {
            title: "Lista de moradas",
            to: { name: "ListaMoradas" },
            show: permissoes.moradas.viewLista,
          },
          {
            title: "Nova morada",
            to: { name: "NewMorada" },
            show: permissoes.moradas.create,
          },
        ],
      },
      {
        action: mdiBadgeAccountHorizontalOutline,
        title: "Utilizadores",
        active: false,
        show: permissoes.utilizadores.viewLista || permissoes.utilizadores.create,
        items: [
          {
            title: "Visão global",
            to: { name: "UtilizadorOverview" },
            show: permissoes.utilizadores.viewOverview,
          },
          {
            title: "Lista de utilizadores",
            to: { name: "ListaUtilizadores" },
            show: permissoes.utilizadores.viewLista,
          },
          {
            title: "Novo utilizador",
            to: { name: "NewUtilizador" },
            show: permissoes.utilizadores.create,
          },
        ],
      },
      {
        action: mdiHandshakeOutline,
        title: "Parceiros",
        active: false,
        show: permissoes.parceiros.viewLista || permissoes.parceiros.create,
        items: [
          {
            title: "Lista de parceiros",
            to: { name: "ListaParceiros" },
            show: permissoes.parceiros.viewLista,
          },
          {
            title: "Novo parceiro",
            to: { name: "NewParceiro" },
            show: permissoes.parceiros.create,
          },
        ],
      },
      {
        action: mdiCar,
        title: "Veículos",
        active: false,
        show: permissoes.veiculos.viewLista || permissoes.veiculos.create,
        items: [
          {
            title: "Lista de veículos",
            to: { name: "ListaVeiculos" },
            show: permissoes.veiculos.viewLista,
          },
          {
            title: "Novo veículo",
            to: { name: "EditVeiculoPlain" },
            show: permissoes.veiculos.create,
          },
        ],
      },
      {
        action: mdiChartBoxOutline,
        title: "Relatórios",
        active: false,
        show: permissoes.relatorios.viewRelatorioVendas,
        items: [
          {
            title: "Vendas",
            to: { name: "RelatorioVendas" },
            show: permissoes.relatorios.viewRelatorioVendas,
          },
          {
            title: "Créditos",
            to: { name: "RelatorioCreditos" },
            show: permissoes.relatorios.viewRelatorioCreditos,
          },
        ],
      },
      {
        action: mdiLockOpenOutline,
        title: "Permissões",
        active: false,
        to: { name: "ListaGrupos" },
        show: permissoes.grupos.viewLista,
      },
      {
        action: mdiShieldCrownOutline,
        title: "Admin dashboard",
        active: false,
        to: { name: "ViewAdmin" },
        show: permissoes.admin.viewDashboard,
      },
    ];

    const res = itemsMenu.filter((item) => item.show);
    res.forEach((item) => {
      if (item.items) item.items = (item.items as any).filter((item: any) => item.show);
    });
    return res;
  }

  async logout() {
    this.isLogingout = true;
    await logoutAndClear();
  }
}

import { covertTimestamp, IfirestoreObject, Imeta } from "@/common/services/IfirestoreObject";
import { Geolocation } from "@capacitor/geolocation";

export class Position implements IfirestoreObject {
  id: string = null;

  latitude: number = null;
  longitude: number = null;
  accuracy: number = null;
  altitude: number = null;
  altitudeAccuracy: number = null;
  heading: number = null;
  speed: number = null;
  timestamp: Date = null;

  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  setData(json: any): void {
    this.latitude = json.latitude ?? null;
    this.longitude = json.longitude ?? null;
    this.accuracy = json.accuracy ?? null;
    this.altitude = json.altitude ?? null;
    this.altitudeAccuracy = json.altitudeAccuracy ?? null;
    this.heading = json.heading ?? null;
    this.speed = json.speed ?? null;
    this.timestamp = json.timestamp ? covertTimestamp(json.timestamp) : null;

    this.meta = {
      createdAt: json.meta?.createdAt ? json.meta.createdAt.toDate() : null,
      createdBy: json.meta?.createdBy ?? { uid: null, nome: null },
    };
  }

  getLiteralCoords() {
    if (!this.latitude || !this.longitude) return false;
    return { lat: this.latitude, lng: this.longitude };
  }

  /**
   * Minimiza o objecto de forma a guardar no firebase.firestore
   */
  minimized() {
    const res: any = {
      id: this.id,

      latitude: this.latitude,
      longitude: this.longitude,
      accuracy: this.accuracy,
      altitude: this.altitude,
      altitudeAccuracy: this.altitudeAccuracy,
      heading: this.heading,
      speed: this.speed,
      timestamp: this.timestamp,

      meta: this.meta,
    };

    return res;
  }

  /**
   * Minimiza o objecto / retorna só a info a duplicar noutro objecto na Firestore
   */
  minimizedMini() {
    return {
      id: this.id,
      latitude: this.latitude,
      longitude: this.longitude,
      accuracy: this.accuracy,
      altitude: this.altitude,
      altitudeAccuracy: this.altitudeAccuracy,
      heading: this.heading,
      speed: this.speed,
      timestamp: this.timestamp,
    };
  }

  /**
   * Get a posição actual do dispositivo
   */
  async getCurrentLocation() {
    const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
    this.latitude = position?.coords?.latitude ?? null;
    this.longitude = position?.coords?.longitude ?? null;
    this.accuracy = position?.coords?.accuracy ?? null;
    this.altitude = position?.coords?.altitude ?? null;
    this.altitudeAccuracy = position?.coords?.altitudeAccuracy ?? null;
    this.heading = position?.coords?.heading ?? null;
    this.speed = position?.coords?.speed ?? null;
    this.timestamp = position?.timestamp ? new Date(position.timestamp) : null;
    return this;
  }
}

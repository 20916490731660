import Vue from "vue";
import App from "@/common//views/App.vue";
import router from "@/common/router/router";
import store from "@/common/store/store";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";

import GmapVue from "gmap-vue";
import { onAuthStateChanged } from "firebase/auth";
import {
  auth,
  initFirestoreStuff,
  inicializaNotifications,
  initAppAndAuth,
  initPresenceSensor,
} from "./common/services/firebase";
import moment from "moment";
import initData from "./common/services/initData";

Vue.config.productionTip = false;

//Inicializa o google charts
//Vue.use(VueGoogleCharts);

//Inicialização do google maps
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places,geometry",
  },
});

//Filtros comuns
Vue.filter("duration", (millis: number) => {
  const mDuration = moment.duration(millis);
  let resString = "";
  if (mDuration.days() > 0) resString += `${mDuration.days()}d, `;
  if (mDuration.hours() > 0 || resString !== "") resString += `${mDuration.hours()}h e `;
  resString += `${mDuration.minutes()} mins`;
  return resString;
});
Vue.filter("fromNow", (value: Date | string) => (value ? moment(value).fromNow() : null));
Vue.filter("dateShort", (value: Date | string) => (value ? moment(value).format("DD/MM/YYYY") : null));
Vue.filter("timeShort", (value: Date | string) => (value ? moment(value).format("HH:mm") : null));
Vue.filter("dateTimeLong", (value: Date | string) => (value ? moment(value).format("DD/MM/YYYY [às] HH:mm") : null));
Vue.filter("dateTimeShort", (value: Date | string) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : null));
Vue.filter("dateTimeShortWOSecs", (value: Date | string) => (value ? moment(value).format("DD/MM/YYYY HH:mm") : null));
Vue.filter("dateTimeExtenso", (value: Date | string) =>
  value ? moment(value).format("DD [de] MMMM [às] HH:mm") : null
);
Vue.filter(
  "dataRange",
  (arr: (Date | string)[]) => `${moment(arr[0]).format("DD/MM/YYYY")} - ${moment(arr[1]).format("DD/MM/YYYY")}`
);
Vue.filter("condicaoPagamentoAcronimo", (value: string) => (value === "Pronto pagamento" ? "PP" : "CRD"));

//Só crio uma nova instancia da app quando há alguma mudança no utilizador logado.
//Se não efectuasse este check, no reload da pagina toda a vue instance era recriada e ia sempre para a página de login
let app: Vue;
initAppAndAuth();

onAuthStateChanged(auth, async () => {
  if (!app) {
    const authUser = auth.currentUser;
    if (authUser) {
      await initFirestoreStuff();
      await store.dispatch("utilizadorStore/setUtilizadorLoggedIn", authUser);

      //Tenho de atualizar as claims, isto gera uma nova token, provavelmente não será a melhor ideia.
      auth.currentUser.getIdToken(true);

      //Get e inicializa utilizadores/parceiros/veiculos, etc...
      initData();

      //Set a token de notificações
      inicializaNotifications();

      //Init presence sensor
      initPresenceSensor();
    }

    moment.locale("pt-pt");
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err);
  console.error(vm);
  console.error(info);
  console.log({ err, vm, info });
  //logEvent(analytics, "vue_exeption", { err, vm, info });
};

const ListaClientes = () =>
  import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ListaClientes.vue");
const EditClientePlain = () =>
  import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/EditClientePlain.vue");
const ViewCliente = () => import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ViewCliente.vue");
const ClienteInfo = () => import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ClienteInfo.vue");
const ClientePagamentos = () =>
  import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ClientePagamentos.vue");
const ClienteConsultas = () =>
  import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ClienteConsultas.vue");
const ClienteServicos = () =>
  import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ClienteServicos.vue");
const ClienteContaCorrente = () =>
  import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ClienteContaCorrente.vue");
const ClienteHistorico = () =>
  import(/* webpackChunkName: "chunk-clientes" */ "@/modules/clientes/views/ClienteHistorico.vue");

export const clientesRoutes = [
  {
    path: "/clientes",
    name: "ListaClientes",
    component: ListaClientes,
    meta: {
      equiresAuth: true,
      requires: ["clientes.viewLista"],
    },
  },
  {
    path: "/clientes/novo",
    name: "NewCliente",
    component: EditClientePlain,
    meta: {
      requiresAuth: true,
      requires: ["clientes.create"],
    },
  },
  {
    path: "/clientes/:id",
    name: "ViewCliente",
    redirect: { name: "ClienteInfo" },
    component: ViewCliente,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["clientes.viewCliente"],
    },
    children: [
      {
        path: "informacao",
        name: "ClienteInfo",
        component: ClienteInfo,
        meta: {
          appBarTitle: "Cliente",
          requiresAuth: true,
          requires: ["clientes.viewCliente"],
        },
      },
      {
        path: "servicos",
        name: "ClienteServicos",
        component: ClienteServicos,
        meta: {
          appBarTitle: "Cliente",
          requiresAuth: true,
          requires: ["clientes.viewServicos"],
        },
      },
      {
        path: "consultas",
        name: "ClienteConsultas",
        component: ClienteConsultas,
        meta: {
          appBarTitle: "Cliente",
          requiresAuth: true,
          requires: ["clientes.viewConsultas"],
        },
      },
      {
        path: "pagamentos",
        name: "ClientePagamentos",
        component: ClientePagamentos,
        meta: {
          appBarTitle: "Cliente",
          requiresAuth: true,
          requires: ["clientes.viewPagamentos"],
        },
      },
      {
        path: "conta",
        name: "ClienteContaCorrente",
        component: ClienteContaCorrente,
        meta: {
          appBarTitle: "Cliente",
          requiresAuth: true,
          requires: ["clientes.viewContaCorrente"],
        },
      },
      {
        path: "historico",
        name: "ClienteHistorico",
        component: ClienteHistorico,
        meta: {
          appBarTitle: "Cliente",
          requiresAuth: true,
          requires: ["clientes.viewHistorico"],
        },
      },
    ],
  },
];

const ViewRelatorioVendas = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/ViewRelatorioVendas.vue");
const RelatorioVendasClientes = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/RelatorioVendasClientes.vue");
const RelatorioVendasMotorista = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/RelatorioVendasMotoristas.vue");
const RelatorioVendasVeiculos = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/RelatorioVendasVeiculos.vue");

const ViewRelatorioCreditos = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/ViewRelatorioCreditos.vue");
const RelatorioCreditosSemConsultas = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/RelatorioCreditosSemConsultas.vue");
const RelatorioCreditosBalanco = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/RelatorioCreditosBalanco.vue");
const RelatorioCreditosConsultas = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/RelatorioCreditosConsultas.vue");
const RelatorioCreditosPagamentos = () =>
  import(/* webpackChunkName: "chunk-relatorios" */ "@/modules/relatorios/views/RelatorioCreditosPagamentos.vue");

//========== VÉICULOS ==========\\
export const relatoriosRoutes = [
  {
    path: "/relatorios/vendas",
    name: "RelatorioVendas",
    redirect: { name: "RelatorioVendasClientes", query: { sort: "0", dir: "desc" } },
    component: ViewRelatorioVendas,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["relatorios.viewRelatorioVendas"],
    },
    children: [
      {
        path: "clientes",
        name: "RelatorioVendasClientes",
        component: RelatorioVendasClientes,
        meta: {
          appBarTitle: "Relatório vendas",
          requiresAuth: true,
          requires: ["relatorios.viewRelatorioVendas"],
        },
      },
      {
        path: "motoristas",
        name: "RelatorioVendasMotoristas",
        component: RelatorioVendasMotorista,
        meta: {
          appBarTitle: "Relatório vendas",
          requiresAuth: true,
          requires: ["relatorios.viewRelatorioVendas"],
        },
      },
      {
        path: "veiculos",
        name: "RelatorioVendasVeiculos",
        component: RelatorioVendasVeiculos,
        meta: {
          appBarTitle: "Relatório vendas",
          requiresAuth: true,
          requires: ["relatorios.viewRelatorioVendas"],
        },
      },
    ],
  },
  {
    path: "/relatorios/creditos",
    name: "RelatorioCreditos",
    redirect: { name: "RelatorioCreditosBalanco", query: { sort: "0", dir: "asc" } },
    component: ViewRelatorioCreditos,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["relatorios.viewRelatorioVendas"],
    },
    children: [
      {
        path: "balanco",
        name: "RelatorioCreditosBalanco",
        component: RelatorioCreditosBalanco,
        meta: {
          appBarTitle: "Relatório créditos",
          requiresAuth: true,
          requires: ["relatorios.viewRelatorioCreditos"],
        },
      },
      {
        path: "semConsulta",
        name: "RelatorioCreditosSemConsultas",
        component: RelatorioCreditosSemConsultas,
        meta: {
          appBarTitle: "Relatório créditos",
          requiresAuth: true,
          requires: ["relatorios.viewRelatorioCreditos"],
        },
      },
      {
        path: "consultas",
        name: "RelatorioCreditosConsultas",
        component: RelatorioCreditosConsultas,
        meta: {
          appBarTitle: "Relatório créditos",
          requiresAuth: true,
          requires: ["relatorios.viewRelatorioVendas"],
        },
      },
      {
        path: "pagamentos",
        name: "RelatorioCreditosPagamentos",
        component: RelatorioCreditosPagamentos,
        meta: {
          appBarTitle: "Relatório créditos",
          requiresAuth: true,
          requires: ["relatorios.viewRelatorioVendas"],
        },
      },
    ],
  },
];

const EditMoradaFull = () =>
  import(/* webpackChunkName: "chunk-moradas" */ "@/modules/moradas/views/EditMoradaPlain.vue");
const ListaMoradas = () => import(/* webpackChunkName: "chunk-moradas" */ "@/modules/moradas/views/ListaMoradas.vue");
const ViewMorada = () => import(/* webpackChunkName: "chunk-moradas" */ "@/modules/moradas/views/ViewMorada.vue");
const MoradaInfo = () => import(/* webpackChunkName: "chunk-moradas" */ "@/modules/moradas/views/MoradaInfo.vue");
const MoradaHistorico = () =>
  import(/* webpackChunkName: "chunk-moradas" */ "@/modules/moradas/views/MoradaHistorico.vue");

export const moradasRoutes = [
  {
    path: "/moradas",
    name: "ListaMoradas",
    component: ListaMoradas,
    meta: {
      requiresAuth: true,
      requires: ["moradas.viewLista"],
    },
  },
  {
    path: "/moradas/novo",
    name: "NewMorada",
    component: EditMoradaFull,
    meta: {
      requiresAuth: true,
      requires: ["moradas.create"],
    },
  },
  {
    path: "/moradas/:id",
    name: "ViewMorada",
    redirect: { name: "MoradaInfo" },
    component: ViewMorada,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["moradas.viewMorada"],
    },
    children: [
      {
        path: "informacao",
        name: "MoradaInfo",
        component: MoradaInfo,
        meta: {
          appBarTitle: "Morada",
          requiresAuth: true,
          requires: ["moradas.viewMorada"],
        },
      },
      {
        path: "historico",
        name: "MoradaHistorico",
        component: MoradaHistorico,
        meta: {
          appBarTitle: "Morada",
          requiresAuth: true,
          requires: ["moradas.viewHistorico"],
        },
      },
    ],
  },
];

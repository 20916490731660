import { registerPlugin } from "@capacitor/core";
import type { PluginListenerHandle } from "@capacitor/core";

export interface CapacitorCustomPushNotificationPlugin {
  /**
   * Listens for recived notifications.
   */
  addListener(
    eventName: "notificationReceived",
    listenerFunc: (data: any) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Removes all listeners
   */
  removeAllListeners(): Promise<void>;
}

const CapacitorCustomPushNotification = registerPlugin<CapacitorCustomPushNotificationPlugin>(
  "CapacitorCustomPushNotification"
);

export { CapacitorCustomPushNotification };

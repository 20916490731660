import HistoricoItem from "@/common/components/historico/HistoricoItem";
import { getMeta } from "@/common/services/IfirestoreObject";
import Pagamento from "@/modules/creditos/types/Pagamento";
import {
  doc,
  getDocs,
  query,
  collection,
  Query,
  DocumentReference,
  writeBatch,
  orderBy,
  increment,
} from "firebase/firestore";

import { db } from "@/common/services/firebase";
import {
  CLIENTE_COLLECTION,
  PAGAMENTO_COLLECTION,
  HISTORICO_COLLECTION,
  PAGAMENTO_LVL1,
  CLIENTE_LVL2,
} from "@/common/defs/collectionNames";
import DBOperations from "@/common/services/DBOperations";
import { dbOperations as clientesbBOperations } from "@/modules/clientes/store/clienteStore";

export const dbOperations = new DBOperations("Pagamento", {
  metaCollection: PAGAMENTO_LVL1,
  lvl1Name: PAGAMENTO_LVL1,
});

const state = {};
const getters = {};
const mutations = {};

interface IGetPayload {
  query: Query | DocumentReference;
  ref?: { item: Pagamento } | Pagamento[];
}

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Pagamento } | { item: Pagamento[] } | Pagamento[];
}

const actions = {
  /**
   * Get de uma só vez os documentos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsBase(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsBase(payload.ref, payload.query);
  },

  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListener(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerLvl1(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref, payload.query, "lvl1");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, payload: { idCliente: string; idPagamento: string }) {
    const res: HistoricoItem[] = [];
    const docBaseCliente = doc(db, CLIENTE_COLLECTION, payload.idCliente);
    const q = collection(docBaseCliente, PAGAMENTO_COLLECTION, payload.idPagamento, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  async saveNewPagamento(state: any, pagamento: Pagamento) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
    const batch = writeBatch(db);

    //Set Meta
    pagamento.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const pagamentoMini = pagamento.minimized();
    const base = { info: pagamentoMini.info, status: pagamentoMini.status };
    const lvl1 = { meta: pagamentoMini.meta, status: pagamentoMini.status, data: pagamentoMini.info.data };

    //Set o doc
    const clienteId = pagamento.info.cliente.id;
    const docBaseCliente = doc(db, CLIENTE_COLLECTION, clienteId);
    const docPagamento = doc(collection(docBaseCliente, PAGAMENTO_COLLECTION));
    batch.set(docPagamento, base);
    batch.set(doc(docPagamento, PAGAMENTO_LVL1, docPagamento.id), lvl1);

    //Update contagem do cliente
    const locCliente = doc(db, CLIENTE_COLLECTION, clienteId, CLIENTE_LVL2, clienteId);
    batch.update(locCliente, {
      "conta.balanco": increment(pagamento.info.valor * -1),
    });

    //Set histórico no pagamento
    const historicoItemPag = new HistoricoItem();
    historicoItemPag.setCreated();
    historicoItemPag.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    historicoItemPag.setNotification({
      id: docPagamento.id,
      title: `Pagamento do ${pagamento.info.cliente.info.nome} criado`,
      textFields: [{ text: `de ${pagamento.info.valor.toFixed(2)}€` }],
      link: `/pagamentos/${docPagamento.id}/?cId=/${clienteId}`,
    });
    batch.set(doc(collection(docPagamento, HISTORICO_COLLECTION)), historicoItemPag.minimized());

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docPagamento.id;
  },

  /**
   * Update ao dados do pagamento na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { pagamento: Pagamento; historico: Pagamento }) {
    const batch = dbOperations
      .setOp(payload.pagamento)
      .update({ base: ["info.valor", "info.metodo", "info.nome", "info.ref", "info.data", "info.utilizador"] })
      .log(payload.historico, {
        title: `Pagamento do ${payload.historico.info.cliente.info.nome} modificado`,
        link: `/pagamentos/${payload.pagamento.id}/?cId=/${payload.pagamento.info.cliente.id}`,
      })
      .setMeta()
      .getBatch();

    //Update clientes
    if (payload.pagamento.info.valor !== payload.historico.info.valor) {
      clientesbBOperations.setOp(payload.pagamento.info.cliente, batch).updateWithValues({
        lvl2: { "conta.balanco": increment((payload.pagamento.info.valor - payload.historico.info.valor) * -1) },
      });
    }
    batch.commit();
  },

  /**
   * Update observações
   */
  async updateObservacaoDB(_: any, payload: { pagamento: Pagamento; historico: Pagamento }) {
    dbOperations
      .setOp(payload.pagamento)
      .update({ base: ["info.observacao"] })
      .log(payload.historico, {
        title: `Pagamento do ${payload.historico.info.cliente.info.nome} modificado`,
        link: `/pagamentos/${payload.pagamento.id}/?cId=/${payload.pagamento.info.cliente.id}`,
      })
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateStatusDB(_: any, payload: { pagamento: Pagamento; historico: Pagamento }) {
    const batch = dbOperations
      .setOp(payload.pagamento)
      .update({ base: ["status.locked", "status.active"] })
      .log(payload.historico)
      // .log(payload.historico, {
      //   title: `Pagamento do ${payload.historico.info.cliente.info.nome} modificado`,
      //   link: `/pagamentos/${payload.pagamento.id}/?cId=/${payload.pagamento.info.cliente.id}`,
      // })
      .update({ lvl1: ["status.locked", "status.active"] })
      .setMeta()
      .getBatch();

    //Update clientes
    if (payload.pagamento.status.active !== payload.historico.status.active) {
      clientesbBOperations.setOp(payload.pagamento.info.cliente, batch).updateWithValues({
        lvl2: { "conta.balanco": increment(payload.pagamento.info.valor * (payload.pagamento.status.active ? -1 : 1)) },
      });
    }
    batch.commit();
  },

  /**
   * Elimina permanentemente a veiculo na firebase.firestore
   */
  async deletePagamentoDB(state: any, pagamento: Pagamento) {
    const docBase = pagamento.getdocRef();
    const batch = writeBatch(db);
    batch.delete(docBase);
    batch.delete(doc(docBase, PAGAMENTO_LVL1, pagamento.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

import { ActionContext } from "vuex";
import { db } from "@/common/services/firebase";
import {
  getDocs,
  query,
  doc,
  collection,
  Query,
  DocumentReference,
  writeBatch,
  orderBy,
  increment,
} from "firebase/firestore";

import {
  CLIENTE_COLLECTION,
  CONSULTA_COLLECTION,
  CONSULTA_LVL1,
  HISTORICO_COLLECTION,
  SERVICO_COLLECTION,
  CLIENTE_LVL2,
} from "@/common/defs/collectionNames";
import { getMeta } from "@/common/services/IfirestoreObject";
import Consulta from "../types/Consulta";

import HistoricoItem from "@/common/components/historico/HistoricoItem";
import DBOperations from "@/common/services/DBOperations";
import { dbOperations as pagamentosDbOperations } from "./pagamentoStore";
import { dbOperations as clientesDbOperations } from "@/modules/clientes/store/clienteStore";
import { dbOperations as servicosDbOperations } from "@/modules/servicos/store/servicoStore";
import Pagamento from "../types/Pagamento";

const state = {};
const getters = {};
const mutations = {};

export const dbOperations = new DBOperations("Consulta", {
  metaCollection: CONSULTA_LVL1,
  lvl1Name: CONSULTA_LVL1,
});

interface IGetPayload {
  query: Query | DocumentReference;
  ref?: { item: Consulta } | Consulta[];
}

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Consulta } | { item: Consulta[] } | Consulta[];
}

const actions = {
  /**
   * Get de uma só vez os documentos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsBase(context: ActionContext<any, any>, payload: IGetPayload) {
    return await dbOperations.getItemsBase(payload.ref, payload.query);
  },

  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListener(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref ?? context.state.consultas, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os.
   * Se o arg ref existir, guarda os items na ref seja um item ou um array de items, caso contrário guarda na store
   */
  async getListenerLvl1(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref ?? context.state.consultas, payload.query, "lvl1");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, payload: { idCliente: string; idConsulta: string }) {
    const res: HistoricoItem[] = [];
    const docBaseCliente = doc(db, CLIENTE_COLLECTION, payload.idCliente);
    const q = collection(docBaseCliente, CONSULTA_COLLECTION, payload.idConsulta, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  async saveNewConsulta(state: any, consulta: Consulta) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
    const batch = writeBatch(db);

    //Set Meta
    consulta.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const consultaMini = consulta.minimized();
    const base = { info: consultaMini.info, status: consultaMini.status };
    const lvl1 = { meta: consultaMini.meta, status: consultaMini.status, data: consultaMini.info.data };

    //Set o doc
    const clienteId = consulta.info.cliente.id;
    const docBaseCliente = doc(db, CLIENTE_COLLECTION, clienteId);
    const docConsulta = doc(collection(docBaseCliente, CONSULTA_COLLECTION));
    batch.set(docConsulta, base);
    batch.set(doc(docConsulta, CONSULTA_LVL1, docConsulta.id), lvl1);

    //Update Serviços e contagem do cliente
    const locCliente = doc(db, CLIENTE_COLLECTION, clienteId, CLIENTE_LVL2, clienteId);
    batch.update(locCliente, { "conta.balanco": increment(consulta.valorTotal) });

    consulta.info.servicosAssociados.forEach((servico) => {
      const loc = doc(db, SERVICO_COLLECTION, servico.id);
      batch.update(loc, { "status.consultaId": docConsulta.id, "status.locked": true });
    });

    //Set histórico na consulta
    const historicoItemPag = new HistoricoItem();
    historicoItemPag.setCreated();
    historicoItemPag.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    batch.set(doc(collection(docConsulta, HISTORICO_COLLECTION)), historicoItemPag.minimized());

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docConsulta.id;
  },

  /**
   * Update ao dados da consulta na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { consulta: Consulta; historico: Consulta }) {
    dbOperations
      .setOp(payload.consulta)
      .update({ base: ["info.data", "info.periodoInicio", "info.periodoFim", "info.morada"] })
      .log(payload.historico)
      .update({ lvl1: [{ data: "info.data" }] })
      .setMeta()
      .commit();
  },

  /**
   * Update observações
   */
  async updateObservacaoDB(_: any, payload: { consulta: Consulta; historico: Consulta }) {
    dbOperations
      .setOp(payload.consulta)
      .update({ base: ["info.observacao"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update servicosAssociados
   */
  async updateServicosAssociadosDB(_: any, payload: { consulta: Consulta; historico: Consulta }) {
    dbOperations
      .setOp(payload.consulta)
      .update({ base: ["info.servicosAssociados"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update pagamentos associados
   */
  async updatePagamentosAssociadosDB(
    _: any,
    payload: { consulta: Consulta; historico: Consulta; pagamento: Pagamento; valor: number }
  ) {
    const batch = dbOperations
      .setOp(payload.consulta)
      .updateWithValues({
        base: { [`info.pagamentosAssociados.${payload.pagamento.id}`]: increment(payload.valor) },
      })
      .logCustom("updatePagamentos", {
        idCliente: payload.consulta.info.cliente.id,
        idPagamento: payload.pagamento.id,
        valor: payload.valor.toFixed(2),
      })
      .setMeta()
      .getBatch();

    //Update Pagamento
    payload.pagamento.status.locked = true;
    pagamentosDbOperations
      .setOp(payload.pagamento, batch)
      .updateWithValues({
        base: { [`info.consultasAssociadas.${payload.consulta.id}`]: increment(payload.valor) },
      })
      .logCustom("updateConsultas", {
        idCliente: payload.consulta.info.cliente.id,
        idConsulta: payload.consulta.id,
        valor: payload.valor.toFixed(2),
      })
      .update({ base: ["status.locked"], lvl1: ["status.locked"] })
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateHasPdfDB(_: any, payload: { consulta: Consulta }) {
    dbOperations
      .setOp(payload.consulta)
      .update({ base: ["info.hasPdf"] })
      .logLog("PDF gerado")
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  updateStatusDB(_: any, payload: { consulta: Consulta; historico: Consulta }) {
    const batch = dbOperations
      .setOp(payload.consulta)
      .update({ base: ["status.active"] })
      .log(payload.historico)
      .update({ lvl1: ["status.active"] })
      .setMeta()
      .getBatch();

    //Update clientes
    clientesDbOperations.setOp(payload.consulta.info.cliente, batch).updateWithValues({
      lvl2: { "conta.balanco": increment(payload.consulta.valorTotal * (payload.consulta.status.active ? 1 : -1)) },
    });

    //Update serviços
    payload.consulta.info.servicosAssociados.forEach((servico) => {
      const isActive = payload.consulta.status.active;
      servicosDbOperations.setOp(servico, batch).updateWithValues({
        base: { "status.consultaId": isActive ? payload.consulta.id : null, "status.locked": isActive ? true : false },
      });
    });

    batch.commit();
  },

  /**
   * Elimina permanentemente a consulta na firebase.firestore
   */
  async deleteConsultaDB(state: any, consulta: Consulta) {
    const docBase = consulta.getdocRef();
    const batch = writeBatch(db);
    batch.delete(docBase);
    batch.delete(doc(docBase, CONSULTA_LVL1, consulta.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

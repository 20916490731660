import { GRUPO_COLLECTION } from "@/common/defs/collectionNames";
import { db } from "@/common/services/firebase";
import { covertTimestamp } from "@/common/services/IfirestoreObject";
import { IfirestoreObject, Imeta } from "@/common/services/IfirestoreObject";
import { doc, DocumentReference } from "firebase/firestore";
import { merge } from "lodash";

export default class Grupo implements IfirestoreObject {
  id?: string;

  info: {
    nome: string;
    descricao: string;
  } = { nome: null, descricao: null };

  permissoes = {
    grupos: {
      accessActive: false,
      accessInactive: false,
      accessLvl1: false,
      viewGrupo: false,
      viewLista: false,
      viewUtilizadores: false,
      viewHistorico: false,
      create: false,
      edit: false,
      deactivate: false,
      delete: false,
    },

    moradas: {
      accessActive: false,
      accessInactive: false,
      accessLvl1: false,
      viewMorada: false,
      viewLista: false,
      viewHistorico: false,
      create: false,
      edit: false,
      deactivate: false,
      delete: false,
    },

    utilizadores: {
      accessActive: false,
      accessInactive: false,
      accessLvl1: false,
      accessLvl2: false,
      viewUtilizador: false,
      viewAvatar: false, //para o storage
      viewOverview: false,
      viewLista: false,
      viewDefinicoes: false,
      removeDevices: false,
      viewServicos: false,
      viewRegister: false,
      viewStats: false,
      viewHistorico: false,
      create: false,
      edit: false,
      deactivate: false,
      delete: false,
    },

    parceiros: {
      accessActive: false,
      accessInactive: false,
      accessLvl1: false,
      viewParceiro: false,
      viewLista: false,
      viewAvatar: false, //para o storage
      viewServicos: false,
      viewHistorico: false,
      create: false,
      edit: false,
      deactivate: false,
      delete: false,
    },

    veiculos: {
      accessActive: false,
      accessInactive: false,
      accessLvl1: false,
      accessLvl2: false,
      viewVeiculo: false,
      viewCalendar: false,
      viewLista: false,
      viewHistorico: false,
      create: false,
      edit: false,
      deactivate: false,
      delete: false,
    },

    relatorios: {
      viewRelatorioVendas: false,
      viewRelatorioCreditos: false,
      gerarRelatorioVendas: false,
      gerarRelatorioCreditos: false,
    },

    consultas: {
      accessActive: false,
      accessInactive: false,
      accessLvl1: false,
      viewConsulta: false,
      viewServicos: false,
      viewPagamentos: false,
      viewHistorico: false,
      create: false,
      edit: false,
      gerarPdf: false,
      deactivate: false,
      delete: false,
    },

    pagamentos: {
      accessOwn: false,
      accessOthers: false,
      accessInactive: false,
      accessLvl1: false,
      viewPagamento: false,
      viewConsultas: false,
      viewHistorico: false,
      lock: false,
      create: false,
      createDateLimited: false,
      edit: false,
      deactivate: false,
      delete: false,
    },

    servicos: {
      accessOwn: false,
      accessOthers: false,
      accessInactive: false,
      accessLvl1: false,
      accessLvl2: false,
      accessStats: false,
      viewServico: false,
      viewExtendedExpenses: false,
      viewDocumentos: false,
      viewHistorico: false,
      viewAgenda: false,
      viewLista: false,
      share: false,
      sendToDriver: false,
      cancel: false,
      lock: false,
      create: false,
      createTimeLimited: false,
      edit: false,
      editState: false,
      viewMotoristasDesativadosEdit: false,
      deactivate: false,
      delete: false,
    },

    clientes: {
      accessActive: false,
      accessInactive: false,
      accessLvl1: false,
      accessLvl2: false,
      viewCliente: false,
      viewDadosCorrespondencia: false,
      viewContactos: false,
      viewServicos: false,
      viewConsultas: false,
      viewPagamentos: false,
      viewContaCorrente: false,
      viewHistorico: false,
      viewLista: false,
      create: false,
      edit: false,
      deactivate: false,
      delete: false,
    },

    turnos: {
      accessOwn: false,
      accessOthers: false,
      accessInactive: false,
      accessLvl1: false,
      accessStats: false,
      viewTurno: false,
      viewHistorico: false,
      viewLista: false,
      viewClosingTill: false,
      viewTimeline: false,
      lock: false,
      create: false,
      createClosingTill: false,
      createClosingTillOthers: false,
      edit: false,
      editDates: false,
      addServices: false,
      cancelClosingTill: false,
      deactivate: false,
      delete: false,
    },

    admin: {
      viewDashboard: false,
      viewLogs: false,
    },
  };

  status: {
    active: boolean;
  } = { active: true };

  //=============== Lvl1 ======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  setData(json: Partial<Grupo>) {
    this.info = {
      nome: json.info.nome,
      descricao: json.info.descricao,
    };
    merge(this.permissoes, json.permissoes);

    this.status = {
      active: json.status.active,
    };
  }

  setLvl1(json: any) {
    this.meta = {
      createdAt: covertTimestamp(json.meta.createdAt),
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      info: this.info,
      permissoes: this.permissoes,
      status: this.status,
      meta: this.meta,
    };
    return res;
  }

  getdocRef(): DocumentReference {
    return doc(db, GRUPO_COLLECTION, this.id);
  }
}

export interface IGrupoLista {
  titulo: string;
  model: string;
  items: IPermissaoLista[];
}

export interface IPermissaoLista {
  regra: string;
  model: string;
  serverEnforced?: boolean;
  deps?: { true?: string[]; false?: string[] };
}

export const LISTA_PERMISSOES: IGrupoLista[] = [
  //Grupos
  //Se na lista true de alguma premissão aparecer "grupos_viewLista", então "grupos_viewLista" tem de ter essa permissão na lista false e toda a sua lista false;
  {
    titulo: "Grupos",
    model: "grupos",
    items: [
      {
        regra: "Acesso aos grupos ativos",
        model: "grupos.accessActive",
        serverEnforced: true,
        deps: {
          false: [
            "grupos.accessInactive",
            "grupos.accessLvl1",
            "grupos.viewGrupo",
            "grupos.viewLista",
            "grupos.viewHistorico",
            "grupos.create",
            "grupos.edit",
            "grupos.deactivate",
            "grupos.delete",
          ],
        },
      },
      {
        regra: "Acesso aos grupos inativos",
        model: "grupos.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["grupos.accessActive"],
          false: ["grupos.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "grupos.accessLvl1",
        deps: {
          true: ["grupos.accessActive"],
        },
      },
      {
        regra: "Visualizar grupo",
        model: "grupos.viewGrupo",
        deps: {
          true: ["grupos.accessActive"],
          false: [
            "grupos.viewLista",
            "grupos.viewHistorico",
            "grupos.create",
            "grupos.edit",
            "grupos.deactivate",
            "grupos.delete",
          ],
        },
      },
      {
        regra: "Visualizar lista",
        model: "grupos.viewLista",
        deps: {
          true: ["grupos.viewGrupo"],
        },
      },
      {
        regra: "Visualizar os utilizadores do grupo",
        serverEnforced: true,
        model: "grupos.viewUtilizadores",
        deps: {
          true: ["grupos.viewGrupo"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "grupos.viewHistorico",
        deps: {
          true: ["grupos.viewGrupo"],
        },
      },
      {
        regra: "Criar",
        model: "grupos.create",
        serverEnforced: true,
        deps: {
          true: ["grupos.viewGrupo"],
        },
      },
      {
        regra: "Editar",
        model: "grupos.edit",
        serverEnforced: true,
        deps: {
          true: ["grupos.viewGrupo"],
          false: ["grupos.deactivate", "grupos.delete"],
        },
      },
      {
        regra: "Desativar",
        model: "grupos.deactivate",
        serverEnforced: true,
        deps: {
          true: ["grupos.accessInactive", "grupos.edit"],
          false: ["grupos.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "grupos.delete",
        serverEnforced: true,
        deps: {
          true: ["grupos.deactivate"],
        },
      },
    ],
  },
  //Utilizadores
  {
    titulo: "Utilizadores",
    model: "utilizadores",
    items: [
      {
        regra: "Acesso aos utilizadores ativos",
        model: "utilizadores.accessActive",
        serverEnforced: true,
        deps: {
          false: [
            "utilizadores.accessInactive",
            "utilizadores.accessLvl1",
            "utilizadores.accessLvl2",
            "utilizadores.viewAvatar",
            "utilizadores.viewLista",
            "utilizadores.viewDefinicoes",
            "utilizadores.viewHistorico",
            "utilizadores.create",
            "utilizadores.edit",
            "utilizadores.deactivate",
            "utilizadores.delete",
          ],
        },
      },
      {
        regra: "Acesso aos utilizadores inativos",
        model: "utilizadores.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["utilizadores.accessActive"],
          false: ["utilizadores.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "utilizadores.accessLvl1",
      },
      {
        regra: "Acesso a info nível 2",
        serverEnforced: true,
        model: "utilizadores.accessLvl2",
        deps: {
          true: ["utilizadores.accessActive"],
        },
      },
      {
        regra: "Visualizar utilizador",
        model: "utilizadores.viewUtilizador",
        deps: {
          false: [
            "utilizadores.viewAvatar",
            "utilizadores.viewLista",
            "utilizadores.viewDefinicoes",
            "utilizadores.viewHistorico",
            "utilizadores.create",
            "utilizadores.edit",
            "utilizadores.deactivate",
            "utilizadores.delete",
          ],
        },
      },
      {
        regra: "Visualizar o avatar",
        model: "utilizadores.viewAvatar",
        serverEnforced: true,
        deps: {
          true: ["utilizadores.viewUtilizador"],
        },
      },
      {
        regra: "Visualizar visão global",
        model: "utilizadores.viewOverview",
        deps: {
          true: ["utilizadores.viewUtilizador"],
        },
      },
      {
        regra: "Visualizar lista",
        model: "utilizadores.viewLista",
        deps: {
          true: ["utilizadores.viewUtilizador"],
        },
      },
      {
        regra: "Visualizar as definições",
        serverEnforced: true,
        model: "utilizadores.viewDefinicoes",
        deps: {
          true: ["utilizadores.viewUtilizador"],
        },
      },
      {
        regra: "Remover dispositivos associados",
        serverEnforced: true,
        model: "utilizadores.removeDevices",
        deps: {
          true: ["utilizadores.viewDefinicoes"],
        },
      },
      {
        regra: "Visualizar o separador dos serviços",
        serverEnforced: true,
        model: "utilizadores.viewServicos",
        deps: {
          true: ["utilizadores.viewUtilizador", "servicos.viewLista"],
        },
      },
      {
        regra: "Visualizar o separador da caixa",
        serverEnforced: true,
        model: "utilizadores.viewRegister",
        deps: {
          true: ["utilizadores.viewUtilizador", "servicos.viewLista"],
        },
      },
      {
        regra: "Visualizar o separador resumo",
        serverEnforced: true,
        model: "utilizadores.viewStats",
        deps: {
          true: ["utilizadores.viewUtilizador", "servicos.viewLista"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "utilizadores.viewHistorico",
        deps: {
          true: ["utilizadores.viewUtilizador"],
        },
      },
      {
        regra: "Criar",
        model: "utilizadores.create",
        serverEnforced: true,
        deps: {
          true: ["utilizadores.viewUtilizador", "utilizadores.viewAvatar"],
        },
      },
      {
        regra: "Editar",
        model: "utilizadores.edit",
        serverEnforced: true,
        deps: {
          true: ["utilizadores.viewUtilizador", "utilizadores.viewAvatar"],
          false: ["utilizadores.deactivate", "utilizadores.delete"],
        },
      },
      {
        regra: "Desativar",
        model: "utilizadores.deactivate",
        serverEnforced: true,
        deps: {
          true: ["utilizadores.accessInactive", "utilizadores.edit"],
          false: ["utilizadores.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "utilizadores.delete",
        serverEnforced: true,
        deps: {
          true: ["utilizadores.deactivate"],
        },
      },
    ],
  },
  //Parceiros
  {
    titulo: "Parceiros",
    model: "parceiros",
    items: [
      {
        regra: "Acesso aos parceiros ativos",
        model: "parceiros.accessActive",
        serverEnforced: true,
        deps: {
          false: [
            "parceiros.accessInactive",
            "parceiros.accessLvl1",
            "parceiros.viewParceiro",
            "parceiros.viewAvatar",
            "parceiros.viewLista",
            "parceiros.viewHistorico",
            "parceiros.create",
            "parceiros.edit",
            "parceiros.deactivate",
            "parceiros.delete",
          ],
        },
      },
      {
        regra: "Acesso aos parceiros inativos",
        model: "parceiros.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["parceiros.accessActive"],
          false: ["parceiros.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "parceiros.accessLvl1",
        deps: {
          true: ["parceiros.accessActive"],
        },
      },
      {
        regra: "Visualizar parceiro",
        model: "parceiros.viewParceiro",
        deps: {
          true: ["parceiros.accessActive"],
          false: [
            "parceiros.viewAvatar",
            "parceiros.viewLista",
            "parceiros.viewHistorico",
            "parceiros.create",
            "parceiros.edit",
            "parceiros.deactivate",
            "parceiros.delete",
          ],
        },
      },
      {
        regra: "Visualizar o avatar",
        model: "parceiros.viewAvatar",
        serverEnforced: true,
        deps: {
          true: ["parceiros.viewParceiro"],
        },
      },
      {
        regra: "Visualizar lista",
        model: "parceiros.viewLista",
        deps: {
          true: ["parceiros.viewParceiro"],
        },
      },
      {
        regra: "Visualizar o separador dos serviços",
        serverEnforced: true,
        model: "parceiros.viewServicos",
        deps: {
          true: ["parceiros.viewUtilizador", "servicos.viewLista"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "parceiros.viewHistorico",
        deps: {
          true: ["parceiros.viewParceiro"],
        },
      },
      {
        regra: "Criar",
        model: "parceiros.create",
        serverEnforced: true,
        deps: {
          true: ["parceiros.viewParceiro", "parceiros.viewAvatar"],
        },
      },
      {
        regra: "Editar",
        model: "parceiros.edit",
        serverEnforced: true,
        deps: {
          true: ["parceiros.viewParceiro", "parceiros.viewAvatar"],
          false: ["parceiros.deactivate", "parceiros.delete"],
        },
      },
      {
        regra: "Desativar",
        model: "parceiros.deactivate",
        serverEnforced: true,
        deps: {
          true: ["parceiros.accessInactive", "parceiros.edit"],
          false: ["parceiros.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "parceiros.delete",
        serverEnforced: true,
        deps: {
          true: ["parceiros.deactivate"],
        },
      },
    ],
  },
  //Veículos
  {
    titulo: "Veiculos",
    model: "veiculos",
    items: [
      {
        regra: "Acesso aos veículos ativos",
        model: "veiculos.accessActive",
        serverEnforced: true,
        deps: {
          false: [
            "veiculos.accessInactive",
            "veiculos.accessLvl1",
            "veiculos.accessLvl2",
            "veiculos.viewVeiculo",
            "veiculos.viewLista",
            "veiculos.viewHistorico",
            "veiculos.create",
            "veiculos.edit",
            "veiculos.deactivate",
            "veiculos.delete",
          ],
        },
      },
      {
        regra: "Acesso aos veículos inativos",
        model: "veiculos.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["veiculos.accessActive"],
          false: ["veiculos.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "veiculos.accessLvl1",
        deps: {
          true: ["veiculos.accessActive"],
        },
      },
      {
        regra: "Acesso a info nível 2",
        serverEnforced: true,
        model: "veiculos.accessLvl2",
        deps: {
          true: ["veiculos.accessActive"],
        },
      },
      {
        regra: "Visualizar veículo",
        model: "veiculos.viewVeiculo",
        deps: {
          true: ["veiculos.accessActive"],
          false: [
            "veiculos.viewLista",
            "veiculos.viewHistorico",
            "veiculos.create",
            "veiculos.edit",
            "veiculos.deactivate",
            "veiculos.delete",
          ],
        },
      },
      {
        regra: "Visualizar calendario",
        model: "veiculos.viewCalendar",
        deps: {
          true: ["veiculos.viewVeiculo"],
        },
      },
      {
        regra: "Visualizar lista",
        model: "veiculos.viewLista",
        deps: {
          true: ["veiculos.viewVeiculo"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "veiculos.viewHistorico",
        deps: {
          true: ["veiculos.viewVeiculo"],
        },
      },
      {
        regra: "Criar",
        model: "veiculos.create",
        serverEnforced: true,
        deps: {
          true: ["veiculos.viewVeiculo"],
        },
      },
      {
        regra: "Editar",
        model: "veiculos.edit",
        serverEnforced: true,
        deps: {
          true: ["veiculos.viewVeiculo"],
          false: ["veiculos.deactivate", "veiculos.delete"],
        },
      },
      {
        regra: "Desativar",
        model: "veiculos.deactivate",
        serverEnforced: true,
        deps: {
          true: ["veiculos.accessInactive", "veiculos.edit"],
          false: ["veiculos.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "veiculos.delete",
        serverEnforced: true,
        deps: {
          true: ["veiculos.deactivate"],
        },
      },
    ],
  },
  //Moradas
  {
    titulo: "Moradas",
    model: "moradas",
    items: [
      {
        regra: "Acesso as moradas ativas",
        model: "moradas.accessActive",
        serverEnforced: true,
        deps: {
          false: [
            "moradas.accessInactive",
            "moradas.accessLvl1",
            "moradas.viewMorada",
            "moradas.viewLista",
            "moradas.viewHistorico",
            "moradas.create",
            "moradas.edit",
            "moradas.deactivate",
            "moradas.delete",
            "servicos.edit",
            "servicos.create",
          ],
        },
      },
      {
        regra: "Acesso as moradas inativas",
        model: "moradas.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["moradas.accessActive"],
          false: ["moradas.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "moradas.accessLvl1",
        deps: {
          true: ["moradas.accessActive"],
        },
      },
      {
        regra: "Visualizar morada",
        model: "moradas.viewMorada",
        deps: {
          true: ["moradas.accessActive"],
          false: ["moradas.viewLista", "moradas.viewHistorico", "moradas.edit", "moradas.deactivate", "moradas.delete"],
        },
      },
      {
        regra: "Visualizar lista",
        model: "moradas.viewLista",
        deps: {
          true: ["moradas.viewMorada"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "moradas.viewHistorico",
        deps: {
          true: ["moradas.viewMorada"],
        },
      },
      {
        regra: "Criar",
        model: "moradas.create",
        serverEnforced: true,
        deps: {
          //Pode ser criada quando o utilizador cria o cliente
          false: ["clientes.create"],
        },
      },
      {
        regra: "Editar",
        model: "moradas.edit",
        serverEnforced: true,
        deps: {
          true: ["moradas.viewMorada"],
          false: ["moradas.deactivate", "moradas.delete"],
        },
      },
      {
        regra: "Desativar",
        model: "moradas.deactivate",
        serverEnforced: true,
        deps: {
          true: ["moradas.accessInactive", "moradas.edit"],
          false: ["moradas.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "moradas.delete",
        serverEnforced: true,
        deps: {
          true: ["moradas.deactivate"],
        },
      },
    ],
  },
  //Clientes
  {
    titulo: "Clientes",
    model: "clientes",
    items: [
      {
        regra: "Acesso aos clientes ativos",
        model: "clientes.accessActive",
        serverEnforced: true,
        deps: {
          false: [
            "clientes.accessInactive",
            "clientes.accessLvl1",
            "clientes.accessLvl2",
            "clientes.viewCliente",
            "clientes.viewPagamentos",
            "clientes.viewContaCorrente",
            "clientes.viewHistorico",
            "clientes.viewLista",
            "clientes.create",
            "clientes.edit",
            "clientes.deactivate",
            "clientes.delete",
            "servicos.edit",
            "servicos.create",
          ],
        },
      },
      {
        regra: "Acesso aos clientes inativos",
        model: "clientes.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["clientes.accessActive"],
          false: ["clientes.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "clientes.accessLvl1",
        deps: {
          true: ["clientes.accessActive"],
        },
      },
      {
        regra: "Acesso a info nível 2",
        serverEnforced: true,
        model: "clientes.accessLvl2",
        deps: {
          true: ["clientes.accessActive"],
        },
      },
      {
        regra: "Visualizar cliente",
        model: "clientes.viewCliente",
        deps: {
          true: ["clientes.accessActive"],
          false: [
            "clientes.viewPagamentos",
            "clientes.viewContaCorrente",
            "clientes.viewHistorico",
            "clientes.viewLista",
            "clientes.edit",
            "clientes.deactivate",
            "clientes.delete",
          ],
        },
      },
      {
        regra: "Visualizar as dados de correspondência",
        serverEnforced: false,
        model: "clientes.viewDadosCorrespondencia",
        deps: {
          true: ["clientes.viewCliente"],
        },
      },
      {
        regra: "Visualizar os contactos do cliente",
        serverEnforced: false,
        model: "clientes.viewContactos",
        deps: {
          true: ["clientes.viewCliente"],
        },
      },
      {
        regra: "Visualizar os serviços",
        serverEnforced: false,
        model: "clientes.viewServicos",
        deps: {
          true: [
            "clientes.viewCliente",
            "servicos.viewServico",
            "servicos.accessOthers",
            "servicos.accessLvl1",
            "servicos.viewServico",
          ],
        },
      },
      {
        regra: "Visualizar as consultas",
        serverEnforced: false,
        model: "clientes.viewConsultas",
        deps: {
          true: ["clientes.viewCliente", "consultas.viewConsulta"],
        },
      },
      {
        regra: "Visualizar os pagamentos",
        serverEnforced: false,
        model: "clientes.viewPagamentos",
        deps: {
          true: ["clientes.viewCliente", "pagamentos.viewPagamento"],
        },
      },
      {
        regra: "Visualizar a conta-corrente",
        serverEnforced: true,
        model: "clientes.viewContaCorrente",
        deps: {
          true: ["clientes.viewCliente"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "clientes.viewHistorico",
        deps: {
          true: ["clientes.viewCliente"],
        },
      },
      {
        regra: "Visualizar lista",
        model: "clientes.viewLista",
        deps: {
          true: ["clientes.viewCliente"],
        },
      },
      {
        regra: "Criar",
        model: "clientes.create",
        serverEnforced: true,
        deps: {
          //Pode ser criada quando o utilizador cria serviços
          true: ["moradas.create"],
        },
      },
      {
        regra: "Editar",
        model: "clientes.edit",
        serverEnforced: true,
        deps: {
          true: ["clientes.viewCliente"],
          false: ["clientes.deactivate", "clientes.delete"],
        },
      },
      {
        regra: "Desativar",
        model: "clientes.deactivate",
        serverEnforced: true,
        deps: {
          true: ["clientes.accessInactive", "clientes.edit"],
          false: ["clientes.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "clientes.delete",
        serverEnforced: true,
        deps: {
          true: ["clientes.deactivate"],
        },
      },
    ],
  },

  //Consultas
  {
    titulo: "Consultas",
    model: "consultas",
    items: [
      {
        regra: "Acesso as consultas ativas",
        model: "consultas.accessActive",
        serverEnforced: true,
        deps: {
          false: [
            "consultas.accessInactive",
            "consultas.accessLvl1",
            "consultas.viewConsulta",
            "consultas.viewHistorico",
            "consultas.create",
            "consultas.edit",
            "consultas.deactivate",
            "consultas.delete",
          ],
        },
      },
      {
        regra: "Acesso as consultas inativas",
        model: "consultas.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["consultas.accessActive"],
          false: ["consultas.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "consultas.accessLvl1",
        deps: {
          true: ["consultas.accessActive"],
        },
      },
      {
        regra: "Visualizar consulta",
        model: "consultas.viewConsulta",
        deps: {
          true: ["consultas.accessActive"],
          false: [
            "consultas.viewHistorico",
            "consultas.create",
            "consultas.edit",
            "consultas.deactivate",
            "consultas.delete",
          ],
        },
      },
      {
        regra: "Visualizar os servicos associados",
        model: "consultas.viewServicos",
        deps: {
          true: ["consultas.viewConsulta"],
        },
      },
      {
        regra: "Visualizar os pagamentos associados",
        model: "consultas.viewPagamentos",
        deps: {
          true: ["consultas.viewConsulta"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "consultas.viewHistorico",
        deps: {
          true: ["consultas.viewConsulta"],
        },
      },
      {
        regra: "Criar",
        model: "consultas.create",
        serverEnforced: true,
        deps: {
          true: ["consultas.viewConsulta"],
        },
      },
      {
        regra: "Gerar pdf",
        model: "consultas.gerarPdf",
        serverEnforced: true,
        deps: {
          true: ["consultas.create"],
        },
      },
      {
        regra: "Editar",
        model: "consultas.edit",
        serverEnforced: true,
        deps: {
          true: ["consultas.viewConsulta"],
          false: ["consultas.deactivate", "consultas.delete"],
        },
      },
      {
        regra: "Desativar",
        model: "consultas.deactivate",
        serverEnforced: true,
        deps: {
          true: ["consultas.accessInactive", "consultas.edit"],
          false: ["consultas.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "consultas.delete",
        serverEnforced: true,
        deps: {
          true: ["consultas.deactivate"],
        },
      },
    ],
  },

  //Pagamentos
  {
    titulo: "Pagamentos",
    model: "pagamentos",
    items: [
      {
        regra: "Acesso aos próprios pagamentos",
        model: "pagamentos.accessOwn",
        serverEnforced: true,
        deps: {
          false: [
            "pagamentos.accessOthers",
            "pagamentos.accessInactive",
            "pagamentos.accessLvl1",
            "pagamentos.viewPagamento",
            "pagamentos.viewHistorico",
            "pagamentos.lock",
            "pagamentos.create",
            "pagamentos.edit",
            "pagamentos.deactivate",
            "pagamentos.delete",
          ],
        },
      },
      {
        regra: "Acesso aos pagamentos dos outros",
        model: "pagamentos.accessOthers",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.accessOwn"],
        },
      },
      {
        regra: "Acesso as pagamentos inativos",
        model: "pagamentos.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.accessOthers"],
          false: ["pagamentos.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        serverEnforced: true,
        model: "pagamentos.accessLvl1",
        deps: {
          true: ["pagamentos.accessOwn"],
        },
      },
      {
        regra: "Visualizar pagamento",
        model: "pagamentos.viewPagamento",
        deps: {
          true: ["pagamentos.accessOwn"],
          false: [
            "pagamentos.viewHistorico",
            "pagamentos.lock",
            "pagamentos.create",
            "pagamentos.edit",
            "pagamentos.deactivate",
            "pagamentos.delete",
          ],
        },
      },
      {
        regra: "Visualizar as consultas associadas",
        model: "pagamentos.viewConsultas",
        deps: {
          true: ["pagamentos.viewPagamento", "consultas.viewConsulta"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "pagamentos.viewHistorico",
        deps: {
          true: ["pagamentos.viewPagamento"],
        },
      },
      {
        regra: "Criar num determinado período",
        model: "pagamentos.createTimeLimited",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.viewPagamento"],
        },
      },
      {
        regra: "Criar",
        model: "pagamentos.create",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.viewPagamento"],
        },
      },
      {
        regra: "Editar",
        model: "pagamentos.edit",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.viewPagamento"],
          false: ["pagamentos.deactivate", "pagamentos.delete"],
        },
      },
      {
        regra: "Bloquear",
        model: "pagamentos.lock",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.edit"],
        },
      },
      {
        regra: "Desativar",
        model: "pagamentos.deactivate",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.accessInactive", "pagamentos.edit"],
          false: ["pagamentos.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "pagamentos.delete",
        serverEnforced: true,
        deps: {
          true: ["pagamentos.deactivate"],
        },
      },
    ],
  },

  //Serviços
  {
    titulo: "Servicos",
    model: "servicos",
    items: [
      {
        regra: "Acesso aos próprios serviços",
        model: "servicos.accessOwn",
        serverEnforced: true,
        deps: {
          false: [
            "servicos.accessOthers",
            "servicos.accessInactive",
            "servicos.accessLvl1",
            "servicos.accessLvl2",
            "servicos.viewServico",
            "servicos.viewHistorico",
            "servicos.viewLista",
            "servicos.share",
            "servicos.sendToDriver",
            "servicos.cancel",
            "servicos.lock",
            "servicos.create",
            "servicos.edit",
            "servicos.deactivate",
            "servicos.delete",
          ],
        },
      },
      {
        regra: "Acesso aos serviços dos outros",
        model: "servicos.accessOthers",
        serverEnforced: true,
        deps: {
          true: ["servicos.accessOwn", "utilizadores.accessActive", "parceiros.accessActive"],
        },
      },
      {
        regra: "Acesso aos serviços inativos",
        model: "servicos.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["servicos.accessOwn"],
          false: ["servicos.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        model: "servicos.accessLvl1",
        serverEnforced: true,
        deps: {
          true: ["servicos.accessActive"],
        },
      },
      {
        regra: "Acesso a info nível 2",
        model: "servicos.accessLvl2",
        serverEnforced: true,
        deps: {
          true: ["servicos.accessActive"],
        },
      },
      {
        regra: "Acesso as estatísticas",
        model: "servicos.accessStats",
        serverEnforced: true,
        deps: {
          true: ["servicos.accessActive"],
        },
      },
      {
        regra: "Visualizar serviço",
        model: "servicos.viewServico",
        serverEnforced: true,
        deps: {
          true: ["servicos.accessActive"],
          false: [
            "servicos.viewAgenda",
            "servicos.viewLista",
            "servicos.viewHistorico",
            "servicos.share",
            "servicos.sendToDriver",
            "servicos.cancel",
            "servicos.lock",
            "servicos.create",
            "servicos.edit",
            "servicos.deactivate",
            "servicos.delete",
          ],
        },
      },
      {
        regra: "Visualizar as despesas estendidas",
        serverEnforced: true,
        model: "servicos.viewExtendedExpenses",
        deps: {
          true: ["servicos.viewServico"],
        },
      },

      {
        regra: "Visualizar os documentos",
        serverEnforced: true,
        model: "servicos.viewDocumentos",
        deps: {
          true: ["servicos.viewServico"],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "servicos.viewHistorico",
        deps: {
          true: ["servicos.viewServico"],
        },
      },
      {
        regra: "Visualizar agenda",
        model: "servicos.viewAgenda",
        deps: {
          true: ["servicos.viewServico"],
        },
      },
      {
        regra: "Visualizar lista",
        model: "servicos.viewLista",
        deps: {
          true: ["servicos.viewServico"],
        },
      },
      {
        regra: "Criar",
        model: "servicos.create",
        serverEnforced: true,
        deps: {
          true: ["servicos.viewServico", "clientes.accessActive", "moradas.accessActive"],
        },
      },
      {
        regra: "Criar num determinado período",
        model: "servicos.createTimeLimited",
        serverEnforced: true,
        deps: {
          true: ["servicos.viewServico"],
        },
      },
      {
        regra: "Editar",
        model: "servicos.edit",
        serverEnforced: true,
        deps: {
          true: ["servicos.viewServico", "clientes.accessActive", "moradas.accessActive"],
          false: ["servicos.deactivate", "servicos.delete", "servicos.editState"],
        },
      },
      {
        regra: "Editar estado manualmente",
        model: "servicos.editState",
        serverEnforced: true,
        deps: {
          true: ["servicos.edit", "clientes.accessActive", "moradas.accessActive"],
        },
      },
      {
        regra: "Visualizar motoristas desativados na edição",
        model: "servicos.viewMotoristasDesativadosEdit",
        deps: {
          true: ["servicos.viewServico", "utilizadores.accessInactive", "parceiros.accessInactive"],
        },
      },
      {
        regra: "Share",
        model: "servicos.share",
        deps: {
          true: ["servicos.viewServico"],
        },
      },
      {
        regra: "Enviar ao motorista",
        model: "servicos.sendToDriver",
        deps: {
          true: ["servicos.viewServico"],
        },
      },
      {
        regra: "Cancelar",
        model: "servicos.cancel",
        serverEnforced: true,
        deps: {
          true: ["servicos.edit"],
        },
      },
      {
        regra: "Bloquear",
        model: "servicos.lock",
        serverEnforced: true,
        deps: {
          true: ["servicos.edit"],
        },
      },
      {
        regra: "Desativar",
        model: "servicos.deactivate",
        serverEnforced: true,
        deps: {
          true: ["servicos.accessInactive", "servicos.edit"],
          false: ["servicos.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "servicos.delete",
        serverEnforced: true,
        deps: {
          true: ["servicos.deactivate"],
        },
      },
    ],
  },

  //Turnos
  {
    titulo: "Turnos",
    model: "turnos",
    items: [
      {
        regra: "Acesso aos próprios turnos",
        model: "turnos.accessOwn",
        serverEnforced: true,
        deps: {
          false: [
            "turnos.accessOthers",
            "turnos.accessInactive",
            "turnos.accessLvl1",
            "turnos.accessLvl2",
            "turnos.viewTurno",
            "turnos.viewHistorico",
            "turnos.viewLista",
            "turnos.lock",
            "turnos.create",
            "turnos.edit",
            "turnos.deactivate",
            "turnos.delete",
          ],
        },
      },
      {
        regra: "Acesso aos turnos dos outros",
        model: "turnos.accessOthers",
        serverEnforced: true,
        deps: {
          true: ["turnos.accessOwn", "utilizadores.accessActive", "parceiros.accessActive"],
        },
      },
      {
        regra: "Acesso aos turnos inativos",
        model: "turnos.accessInactive",
        serverEnforced: true,
        deps: {
          true: ["turnos.accessOwn"],
          false: ["turnos.deactivate"],
        },
      },
      {
        regra: "Acesso a info nível 1",
        model: "turnos.accessLvl1",
        serverEnforced: true,
        deps: {
          true: ["turnos.accessActive"],
        },
      },
      {
        regra: "Acesso as estatísticas",
        model: "turnos.accessStats",
        serverEnforced: true,
        deps: {
          true: ["turnos.accessActive"],
        },
      },
      {
        regra: "Visualizar o turno",
        model: "turnos.viewTurno",
        serverEnforced: true,
        deps: {
          true: ["turnos.accessActive"],
          false: [
            "turnos.viewLista",
            "turnos.viewHistorico",
            "turnos.lock",
            "turnos.create",
            "turnos.edit",
            "turnos.deactivate",
            "turnos.delete",
          ],
        },
      },
      {
        regra: "Visualizar o histórico",
        serverEnforced: true,
        model: "turnos.viewHistorico",
        deps: {
          true: ["turnos.viewTurno"],
        },
      },
      {
        regra: "Visualizar lista",
        model: "turnos.viewLista",
        deps: {
          true: ["turnos.viewTurno"],
        },
      },
      {
        regra: "Visualizar a linha temporal",
        serverEnforced: true,
        model: "turnos.viewTimeline",
        deps: {
          true: ["turnos.viewTurno"],
        },
      },
      {
        regra: "Criar",
        model: "turnos.create",
        serverEnforced: true,
        deps: {
          true: ["turnos.viewTurno"],
          false: ["turnos.create"],
        },
      },
      {
        regra: "Visualizar folhas de caixa",
        model: "turnos.viewClosingTill",
        serverEnforced: true,
        deps: {
          true: ["turnos.accessActive"],
          false: ["turnos.createClosingTill"],
        },
      },
      {
        regra: "Criar folhas de caixa",
        model: "turnos.createClosingTill",
        serverEnforced: true,
        deps: {
          true: ["turnos.viewClosingTill"],
        },
      },
      {
        regra: "Criar folhas de caixa de outros",
        model: "turnos.createClosingTillOthers",
        serverEnforced: true,
        deps: {
          true: ["turnos.viewClosingTill"],
        },
      },
      {
        regra: "Editar",
        model: "turnos.edit",
        serverEnforced: true,
        deps: {
          true: ["turnos.viewTurno"],
          false: ["turnos.editDates", "turnos.deactivate", "turnos.delete"],
        },
      },
      {
        regra: "Editar datas",
        model: "turnos.editDates",
        serverEnforced: false,
        deps: {
          true: ["turnos.edit"],
        },
      },
      {
        regra: "Adicionar/Remover serviços",
        model: "turnos.addServices",
        serverEnforced: true,
        deps: {
          true: ["turnos.edit"],
        },
      },
      {
        regra: "Bloquear",
        model: "turnos.lock",
        serverEnforced: true,
        deps: {
          true: ["turnos.edit"],
        },
      },
      {
        regra: "Cancelar a folha de caixa",
        model: "turnos.cancelClosingTill",
        serverEnforced: true,
        deps: {
          true: ["turnos.edit"],
        },
      },
      {
        regra: "Desativar",
        model: "turnos.deactivate",
        serverEnforced: true,
        deps: {
          true: ["turnos.accessInactive", "turnos.edit"],
          false: ["turnos.delete"],
        },
      },
      {
        regra: "Eliminar",
        model: "turnos.delete",
        serverEnforced: true,
        deps: {
          true: ["turnos.deactivate"],
        },
      },
    ],
  },

  //Admin
  {
    titulo: "Admin dashboard",
    model: "admin",
    items: [
      {
        regra: "Acesso ao dashboard",
        model: "admin.viewDashboard",
        serverEnforced: true,
        deps: {
          true: [
            "servicos.viewServico",
            "grupos.viewGrupo",
            "moradas.viewMorada",
            "utilizadores.viewUtilizador",
            "parceiros.viewParceiro",
            "consultas.viewConsulta",
            "pagamentos.viewPagamento",
            "clientes.viewCliente",
          ],
        },
      },
      {
        regra: "Acesso aos logs",
        model: "admin.viewLogs",
        serverEnforced: true,
        deps: {
          true: ["admin.viewDashboard"],
        },
      },
    ],
  },

  //Relatorios
  {
    titulo: "Relatórios",
    model: "relatorios",
    items: [
      {
        regra: "Visualizar o relatório de vendas",
        model: "relatorios.viewRelatorioVendas",
        serverEnforced: true,
        deps: {
          true: [
            "servicos.viewServico",
            "grupos.viewGrupo",
            "moradas.viewMorada",
            "utilizadores.viewUtilizador",
            "parceiros.viewParceiro",
            "consultas.viewConsulta",
            "pagamentos.viewPagamento",
            "clientes.viewCliente",
          ],
        },
      },
      {
        regra: "Visualizar o relatório de créditos",
        model: "relatorios.viewRelatorioCreditos",
        serverEnforced: true,
        deps: {
          true: [
            "servicos.viewServico",
            "grupos.viewGrupo",
            "moradas.viewMorada",
            "utilizadores.viewUtilizador",
            "parceiros.viewParceiro",
            "consultas.viewConsulta",
            "pagamentos.viewPagamento",
            "clientes.viewCliente",
          ],
        },
      },
      {
        regra: "Gerar pfds dos relatório de vendas",
        model: "relatorios.gerarRelatorioVendas",
        serverEnforced: true,
        deps: {
          true: ["relatorios.viewRelatorioVendas"],
        },
      },
      {
        regra: "Gerar pfds dos relatório de créditos",
        model: "relatorios.gerarRelatorioCreditos",
        serverEnforced: true,
        deps: {
          true: ["relatorios.viewRelatorioCreditos"],
        },
      },
    ],
  },
];

const Grupos = () => import(/* webpackChunkName: "chunk-grupos" */ "@/modules/grupos/views/ListaGrupos.vue");
const EditGrupoPlain = () => import(/* webpackChunkName: "chunk-grupos" */ "@/modules/grupos/views/EditGrupoPlain.vue");
const ViewGrupo = () => import(/* webpackChunkName: "chunk-grupos" */ "@/modules/grupos/views/ViewGrupo.vue");
const GrupoInfo = () => import(/* webpackChunkName: "chunk-grupos" */ "@/modules/grupos/views/GrupoInfo.vue");
const GrupoUtilizadores = () =>
  import(/* webpackChunkName: "chunk-grupos" */ "@/modules/grupos/views/GrupoUtilizadores.vue");
const GrupoHistorico = () => import(/* webpackChunkName: "chunk-grupos" */ "@/modules/grupos/views/GrupoHistorico.vue");

export const gruposRoutes = [
  {
    path: "/grupos",
    name: "ListaGrupos",
    component: Grupos,
    meta: {
      requiresAuth: true,
      requires: ["grupos.viewLista"],
    },
  },
  {
    path: "/grupos/novo",
    name: "EditGrupoPlain",
    component: EditGrupoPlain,
    meta: {
      requiresAuth: true,
      requires: ["grupos.create"],
    },
  },
  {
    path: "/grupos/:id",
    name: "ViewGrupo",
    redirect: { name: "GrupoInfo" },
    component: ViewGrupo,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["grupos.viewGrupo"],
    },
    children: [
      {
        path: "informacao",
        name: "GrupoInfo",
        component: GrupoInfo,
        meta: {
          appBarTitle: "Permissões",
          requiresAuth: true,
          requires: ["grupos.viewGrupo"],
        },
      },
      {
        path: "utilizadores",
        name: "GrupoUtilizadores",
        component: GrupoUtilizadores,
        meta: {
          appBarTitle: "Permissões",
          requiresAuth: true,
          requires: ["grupos.viewUtilizadores"],
        },
      },
      {
        path: "historico",
        name: "GrupoHistorico",
        component: GrupoHistorico,
        meta: {
          appBarTitle: "Permissões",
          requiresAuth: true,
          requires: ["grupos.viewHistorico"],
        },
      },
    ],
  },
];

const TurnoMain = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/TurnoMain.vue");
const ListaTurnos = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ListaTurnos.vue");
const ViewTurno = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ViewTurno.vue");
const TurnoInfo = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/TurnoInfo.vue");
const ShiftServices = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ShiftServices.vue");
const TurnoStats = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/TurnoStats.vue");
const TurnoTimeline = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/TurnoTimeline.vue");
const TurnoHistorico = () => import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/TurnoHistorico.vue");
const EditClosingTillPlain = () =>
  import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/EditClosingTillPlain.vue");
const ClosingTillList = () =>
  import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ClosingTillList.vue");
const ViewClosingTill = () =>
  import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ViewClosingTill.vue");
const ClosingTillInfo = () =>
  import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ClosingTillInfo.vue");
const ClosingTillStats = () =>
  import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ClosingTillStats.vue");
const ClosingTillHistorico = () =>
  import(/* webpackChunkName: "chunk-turnos" */ "@/modules/turnos/views/ClosingTillHistorico.vue");

export const turnosRoutes = [
  {
    path: "/turnoativo",
    name: "TurnoMain",
    component: TurnoMain,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["turnos.viewTurno"],
    },
  },
  {
    path: "/turnos",
    name: "ListaTurnos",
    component: ListaTurnos,
    meta: {
      requiresAuth: true,
      requires: ["turnos.viewLista"],
    },
  },
  {
    path: "/closingtill/new",
    name: "NewClosingTill",
    component: EditClosingTillPlain,
    meta: {
      requiresAuth: true,
      requires: ["turnos.createClosingTill"],
    },
  },
  {
    path: "/closingtills",
    name: "ClosingTillList",
    component: ClosingTillList,
    meta: {
      requiresAuth: true,
      requires: ["turnos.createClosingTill"],
    },
  },
  {
    path: "/turnos/:id",
    name: "ViewTurno",
    redirect: { name: "TurnoInfo" },
    component: ViewTurno,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["turnos.viewTurno"],
    },
    children: [
      {
        path: "informacao",
        name: "TurnoInfo",
        component: TurnoInfo,
        meta: {
          appBarTitle: "Turno",
          requiresAuth: true,
          requires: ["turnos.viewTurno"],
        },
      },
      {
        path: "services",
        name: "ShiftServices",
        component: ShiftServices,
        meta: {
          appBarTitle: "Turno",
          requiresAuth: true,
          requires: ["turnos.viewTurno"],
        },
      },
      {
        path: "stats",
        name: "TurnoStats",
        component: TurnoStats,
        meta: {
          appBarTitle: "Turno",
          requiresAuth: true,
          requires: ["turnos.accessStats"],
        },
      },
      {
        path: "timeline",
        name: "TurnoTimeline",
        component: TurnoTimeline,
        meta: {
          appBarTitle: "Turno",
          requiresAuth: true,
          requires: ["turnos.viewTimeline", "turnos.accessStats"],
        },
      },
      {
        path: "historico",
        name: "TurnoHistorico",
        component: TurnoHistorico,
        meta: {
          appBarTitle: "Turno",
          requiresAuth: true,
          requires: ["turnos.viewHistorico"],
        },
      },
    ],
  },
  {
    path: "/closingtill/:id",
    name: "ViewClosingTill",
    redirect: { name: "ClosingTillInfo" },
    component: ViewClosingTill,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["turnos.viewClosingTill"],
    },
    children: [
      {
        path: "informacao",
        name: "ClosingTillInfo",
        component: ClosingTillInfo,
        meta: {
          appBarTitle: "Folha de caixa",
          requiresAuth: true,
          requires: ["turnos.viewClosingTill"],
        },
      },
      {
        path: "stats",
        name: "ClosingTillStats",
        component: ClosingTillStats,
        meta: {
          appBarTitle: "Folha de caixa",
          requiresAuth: true,
          requires: ["turnos.accessStats"],
        },
      },
      {
        path: "historico",
        name: "ClosingTillHistorico",
        component: ClosingTillHistorico,
        meta: {
          appBarTitle: "Folha de caixa",
          requiresAuth: true,
          requires: ["turnos.viewHistorico"],
        },
      },
    ],
  },
];

import Servico from "@/modules/servicos/types/Servico";
import moment from "moment";
import { cloneDeep } from "lodash";
import { Position } from "@/modules/turnos/types/Position";
import Activity from "./Activity";
import { covertTimestamp } from "@/common/services/IfirestoreObject";

export const shiftEventType = [
  "START_SHIFT",
  "TAXI_SQUARE",
  "START_SERVICE",
  "PASSENGER_PICKUP",
  "END_SERVICE",
  "END_SHIFT",
] as const;
export type ShiftEventType = (typeof shiftEventType)[number];

export default class ShiftEvent {
  type: ShiftEventType = null;
  startDate: Date = null;
  endDate: Date = null;
  position: Position = null;
  taxiSquareEvent: "ENTER" | "LEAVE" = null;
  serviceId: string = null;
  service: Servico = null; //Local

  activities: Activity[] = [];
  isMapInfoWindowOpened = false; //Local
  mapLineColor = "#344955"; //Local

  constructor(type: ShiftEventType, initData?: any) {
    this.type = type;

    if (initData) {
      this.type = initData.type;
      this.startDate = covertTimestamp(initData.startDate);
      this.endDate = covertTimestamp(initData.endDate);
      this.position = new Position(null, initData.position);
      this.taxiSquareEvent = initData.taxiSquareEvent ?? null;
      this.serviceId = initData.serviceId ?? null;

      if (initData.activities) {
        for (const activityData of initData.activities) this.activities.push(new Activity(activityData));
      }
    }
  }

  get id() {
    return this.type + this.startDate.toISOString();
  }

  get duration() {
    if (this.endDate && this.startDate) return this.endDate.getTime() - this.startDate.getTime();
    else return null;
  }

  get durationFormated() {
    if (!this.duration) return "00";
    return this.formatDuration(this.duration);
  }

  get stats() {
    let drivingDistance = 0;
    let drivingDuration = 0;
    let otherDuration = 0;

    for (const activity of this.activities) {
      switch (activity.activityType) {
        case "DRIVING":
          drivingDuration += activity.duration;
          drivingDistance += activity.calculateDistance();
          break;
        default:
          otherDuration += activity.duration;
      }
    }

    return {
      drivingDuration: this.formatDuration(drivingDuration),
      drivingDistance: this.formatDistance(drivingDistance),
      otherDuration: this.formatDuration(otherDuration),
    };
  }

  async setCurrentDataAndPosition() {
    this.startDate = new Date();
    this.position = await new Position().getCurrentLocation();
  }

  setActivities(shiftActivities: Activity[]) {
    const mEventStartTime = this.startDate.getTime();
    const mEventEndTime = this.endDate.getTime();
    //Filter the activities by date
    for (const activity of shiftActivities) {
      const activityStartTime = activity.startDate.getTime();
      const activityEndTime = activity.endDate.getTime();

      const isActivityStartedBetweenDates = activityStartTime >= mEventStartTime && activityStartTime <= mEventEndTime;
      const isActivityEndedBetweenDates = activityEndTime >= mEventStartTime && activityEndTime <= mEventEndTime;
      const isActivityCoversDates = activityStartTime <= mEventStartTime && activityEndTime >= mEventEndTime;

      if (isActivityStartedBetweenDates || isActivityEndedBetweenDates || isActivityCoversDates) {
        this.activities.push(cloneDeep(activity));
      }
    }

    //set the correct time of the first and last activity to match the shift event
    const firstActivity = this.activities[0];
    const lastActivity = this.activities[this.activities.length - 1];
    if (firstActivity) firstActivity.startDate = new Date(this.startDate.getTime());
    if (lastActivity) lastActivity.endDate = new Date(this.endDate.getTime());

    const TWO_MINUTES = 1000 * 60 * 2;
    if (firstActivity && firstActivity.activityType === "STILL" && firstActivity.duration < TWO_MINUTES) {
      //Removes the still activity that is under 2 mins
      this.activities.shift();
      //Does the proccess again for the correct time
      const firstActivity = this.activities[0];
      if (firstActivity) firstActivity.startDate = new Date(this.startDate.getTime());
    }
    if (lastActivity && lastActivity.activityType === "STILL" && lastActivity.duration < TWO_MINUTES) {
      //Removes the still activity that is under 2 mins
      this.activities.pop();
      //Does the proccess again for the correct time
      const lastActivity = this.activities[this.activities.length - 1];
      if (lastActivity) lastActivity.endDate = new Date(this.endDate.getTime());
    }

    return this;
  }

  setPositions(positions: Position[]) {
    //Get the positions
    for (let i = 0; i < this.activities.length; i++) {
      const activity = this.activities[i];
      activity.positions = [];
      activity.setPositions(positions);
    }
  }

  formatDuration(duration: number) {
    if (!duration || duration <= 0) return null;

    const SIXTY_SECONDS = 60000;
    const SIXTY_MINUTES = 3600000;
    let durationString = "";
    if (duration > SIXTY_MINUTES) durationString = moment.utc(duration).format("H [hora e] m [mins]");
    else if (duration > SIXTY_SECONDS) durationString = moment.utc(duration).format("m [mins]");
    else durationString = moment.utc(duration).format("s [segundos]");
    return durationString;
  }

  formatDistance(kms: number) {
    if (!kms || kms === 0) return null;
    if (kms > 1) {
      return `${kms.toFixed(2)} kms`;
    } else {
      return `${(kms * 1000).toFixed(0)} m`;
    }
  }

  getInfoString(index: number) {
    let res = "";

    //Set the index of the event to show in the info window
    res += `(${index + 1}) `;

    switch (this.type) {
      case "START_SHIFT":
        res += `Início do turno às ${moment(this.startDate).format("HH:mm:ss")}`;
        break;
      case "END_SHIFT":
        res += `Terminou o turno às ${moment(this.startDate).format("HH:mm:ss")}`;
        break;
      case "START_SERVICE":
        res += `Início do serviço às ${moment(this.startDate).format("HH:mm:ss")}`;
        if (this.service) res += `<br> ${this.service?.info.tipo}`;
        break;
      case "END_SERVICE":
        res += `Fim do serviço às ${moment(this.startDate).format("HH:mm:ss")}`;
        if (this.service) res += `<br> ${this.service?.info.tipo}`;
        break;
      case "PASSENGER_PICKUP":
        res += `Recolha do cliente às ${moment(this.startDate).format("HH:mm:ss")}`;
        break;
      default:
      case "TAXI_SQUARE":
        if (this.taxiSquareEvent === "ENTER") res += `Chegada a praça às ${moment(this.startDate).format("HH:mm:ss")}`;
        else res += `Saída da praça às ${moment(this.startDate).format("HH:mm:ss")}`;
        break;
    }

    return res;
  }

  /**
   * Minimizes the object to store it in firestore
   */
  minimized() {
    const res: any = {
      type: this.type,
      startDate: this.startDate,
      position: this.position.minimizedMini(),
      taxiSquareEvent: this.taxiSquareEvent,
      serviceId: this.serviceId,
    };
    return res;
  }
}

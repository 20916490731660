import { isEmpty } from "lodash";
import { db } from "@/common/services/firebase";
import { DocumentReference, doc } from "firebase/firestore";

import { CLIENTE_COLLECTION, CONSULTA_COLLECTION } from "@/common/defs/collectionNames";
import { IfirestoreObject, Imeta, covertTimestamp } from "@/common/services/IfirestoreObject";
import Cliente from "@/modules/clientes/types/Cliente";
import Servico from "@/modules/servicos/types/Servico";
import Morada from "@/modules/moradas/types/Morada";

interface InfoConsulta {
  data: Date;
  periodoInicio: Date;
  periodoFim: Date;
  cliente: Cliente;
  morada: Morada;
  servicosAssociados: Servico[];
  pagamentosAssociados: { [idPagamento: string]: number };
  hasPdf: boolean;
  observacao: string;
}

export default class Consulta implements IfirestoreObject {
  id?: string;

  //=============== BASE ======================\\
  info: InfoConsulta = {
    data: null,
    periodoInicio: null,
    periodoFim: null,
    cliente: null,
    morada: null,
    servicosAssociados: [],
    pagamentosAssociados: {},
    hasPdf: false,
    observacao: null,
  };

  status: {
    active: boolean;
  } = { active: true };

  //=============== LvL 1======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  setData(json: any): void {
    this.info = {
      data: covertTimestamp(json.info.data),
      periodoInicio: covertTimestamp(json.info.periodoInicio),
      periodoFim: covertTimestamp(json.info.periodoFim),
      cliente: null,
      morada: null,
      servicosAssociados: [],
      pagamentosAssociados: json.info.pagamentosAssociados ? json.info.pagamentosAssociados : {},
      hasPdf: json.info.hasPdf,
      observacao: json.info.observacao,
    };

    if (json.info.cliente) this.info.cliente = new Cliente(json.info.cliente.id, json.info.cliente);
    if (json.info.morada) this.info.morada = new Morada(json.info.morada.id, json.info.morada);

    const arrayServicos: Servico[] = json.info.servicosAssociados;
    if (arrayServicos) {
      arrayServicos.forEach((servico) => {
        const newServico = new Servico(servico.id, servico);
        newServico.setLvl1(servico);
        this.info.servicosAssociados.push(newServico);
      });
    }

    this.status = json.status;
  }

  setLvl1(json: any) {
    if (json.meta) {
      this.meta = {
        createdAt: json.meta.createdAt ? json.meta.createdAt.toDate() : null,
        createdBy: json.meta.createdBy,
      };
    }
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      id: this.id,
      info: {
        data: this.info.data,
        periodoInicio: this.info.periodoInicio,
        periodoFim: this.info.periodoFim,
        cliente: this.info.cliente ? this.info.cliente.minimizedMini() : null,
        morada: this.info.morada ? this.info.morada.minimizedMini() : null,
        servicosAssociados: this.info.servicosAssociados.map((servico) => servico.minimized()),
        pagamentosAssociados: this.info.pagamentosAssociados,
        hasPdf: this.info.hasPdf,
        observacao: this.info.observacao,
      },
      status: this.status,
      meta: this.meta,
    };
    return res;
  }

  /**
   * Helper - Get a localização do documento na firestore
   */
  getdocRef(): DocumentReference {
    const docBaseCliente = doc(db, CLIENTE_COLLECTION, this.info.cliente.id);
    return doc(docBaseCliente, CONSULTA_COLLECTION, this.id);
  }

  get numeroServicos() {
    return this.info.servicosAssociados.length;
  }

  get valorTotal() {
    return Number(this.info.servicosAssociados.reduce((acc, servico) => acc + servico.info.valor, 0).toFixed(2));
  }

  get valorPago() {
    if (isEmpty(this.info.pagamentosAssociados)) return 0;
    else
      return Number(
        Object.values(this.info.pagamentosAssociados)
          .reduce((a, b) => a + b)
          .toFixed(2)
      );
  }

  get valorEmFalta() {
    return Number((this.valorTotal - this.valorPago).toFixed(2));
  }
}

import { httpsCallable, HttpsCallableOptions } from "firebase/functions";
import { Position } from "./Position";
import { UTILIZADOR_COLLECTION, TURNO_COLLECTION } from "./../../../common/defs/collectionNames";
import Veiculo from "@/modules/veiculos/types/Veiculo";
import Utilizador from "@/modules/utilizadores/types/Utilizador";
import { IfirestoreObject, covertTimestamp, Imeta } from "@/common/services/IfirestoreObject";
import { doc, DocumentReference } from "firebase/firestore";
import { db, functions } from "@/common/services/firebase";
import Activity from "./Activity";
import ShiftEvent from "./ShiftEvent";

interface InfoTurno {
  estado: "Aberto" | "Fechado" | "Em serviço" | "Na Praça";
  dataInicio: Date;
  dataFim: Date;
  startPosition: Position;
  endPosition: Position;
  kmsVeiculoIniciais: number;
  kmsVeiculoFinais: number;
  bandeiradaInicial: number;
  bandeiradaFinal: number;
  motorista: Utilizador;
  veiculo: Veiculo;
}

export interface iShiftStats {
  services: {
    internal: {
      number: number;
      value: number;
    };
    taxiSquare: {
      number: number;
      value: number;
    };
    taxiCentral: {
      number: number;
      value: number;
    };
    others: {
      number: number;
      value: number;
    };
    total: {
      number: number;
      value: number;
    };
    tolls: number;
    payment: {
      credit: {
        number: number;
        value: number;
      };
      pp: {
        number: number;
        value: number;
      };
      cash: {
        number: number;
        value: number;
      };
      card: {
        number: number;
        value: number;
      };
    };
  };
  distance: {
    total: number;
    onService: number;
    outOfService: number;
  };
  duration: {
    driving: number;
    waiting: number;
    onService: number;
    onTaxiSquare: number;
  };
}

export default class Turno implements IfirestoreObject {
  id?: string;

  //=============== BASE ======================\\
  info: InfoTurno = {
    estado: "Aberto",
    dataInicio: null,
    dataFim: null,
    startPosition: null,
    endPosition: null,
    kmsVeiculoIniciais: null,
    kmsVeiculoFinais: null,
    bandeiradaInicial: null,
    bandeiradaFinal: null,
    motorista: null,
    veiculo: null,
  };

  expenses: {
    realTolls: number;
  } = { realTolls: null };

  shiftEvents: ShiftEvent[] = [];
  activities: Activity[] = [];

  observacao: string = null;

  status: {
    locked: boolean;
    active: boolean;
  } = { locked: false, active: true };

  //=============== LvL 1======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============= Stats =================\\
  //Only read, all write operations are done on the server
  stats: iShiftStats = null;
  processedShiftsEvents: ShiftEvent[] = null;

  //Getters
  get kms() {
    return this.info.kmsVeiculoFinais - this.info.kmsVeiculoIniciais;
  }

  get bandeirada() {
    if (this.info.bandeiradaFinal === null || this.info.bandeiradaInicial === null) return 0;
    return this.info.bandeiradaFinal - this.info.bandeiradaInicial;
  }

  get duration() {
    if (this.info.dataFim !== null) return this.info.dataFim.getTime() - this.info.dataInicio.getTime();
    else return new Date().getTime() - this.info.dataInicio.getTime();
  }

  get friendlyName() {
    return {
      "info.estado": { name: "Estado", changed: `O turno foi alterado para ${this.info.estado}` },
    };
  }

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  setData(json?: any) {
    this.info = {
      estado: json.info.estado,
      dataInicio: covertTimestamp(json.info.dataInicio),
      dataFim: covertTimestamp(json.info.dataFim),
      startPosition: json.info.startPosition ? new Position(null, json.info.startPosition) : null,
      endPosition: json.info.endPosition ? new Position(null, json.info.endPosition) : null,
      kmsVeiculoIniciais: json.info.kmsVeiculoIniciais,
      kmsVeiculoFinais: json.info.kmsVeiculoFinais,
      bandeiradaInicial: json.info.bandeiradaInicial,
      bandeiradaFinal: json.info.bandeiradaFinal,
      motorista: null,
      veiculo: null,
    };

    if (json.expenses) this.expenses = json.expenses;

    if (json.info.motorista) this.info.motorista = new Utilizador(json.info.motorista.id, json.info.motorista);
    if (json.info.veiculo) {
      this.info.veiculo = new Veiculo(json.info.veiculo.id, json.info.veiculo);
      this.info.veiculo.setLvl1(json.info.veiculo);
    }

    this.shiftEvents = [];
    if (json.shiftEvents)
      for (const eventoJson of json.shiftEvents) this.shiftEvents.push(new ShiftEvent(null, eventoJson));
    this.activities = [];
    if (json.activities) for (const activityJson of json.activities) this.activities.push(new Activity(activityJson));

    this.observacao = json.observacao ?? null;

    if (json.status) this.status = json.status;

    //The stats from the miniMinimized is inicialized here if saved
    if (json.stats) this.stats = json.stats;
  }

  setLvl1(json: any) {
    this.meta = {
      createdAt: json.meta.createdAt ? json.meta.createdAt.toDate() : null,
      createdBy: json.meta.createdBy,
    };
  }

  setStats(json: any) {
    if (!json) return;

    this.stats = json.stats;
    if (json.events) {
      this.processedShiftsEvents = [];
      for (const shiftEvent of json.events) this.processedShiftsEvents.push(new ShiftEvent(null, shiftEvent));
    }
  }

  async generateShiftStats() {
    const options: HttpsCallableOptions = { timeout: 530 * 1000 }; // slightly less than the 540 seconds BE timeout
    const calculateShiftStats = httpsCallable(functions, "calculateShiftStats", options);
    await calculateShiftStats({ shiftId: this.id, userId: this.info.motorista.id });
  }

  /**
   * Minimiza o objecto de forma a guardar no firebase.firestore
   */
  minimized() {
    const res: any = {
      id: this.id,

      info: {
        estado: this.info.estado,
        dataInicio: this.info.dataInicio,
        dataFim: this.info.dataFim,
        startPosition: this.info.startPosition?.minimizedMini() ?? null,
        endPosition: this.info.endPosition ? this.info.endPosition.minimizedMini() : null,
        kmsVeiculoIniciais: this.info.kmsVeiculoIniciais,
        kmsVeiculoFinais: this.info.kmsVeiculoFinais,
        bandeiradaInicial: this.info.bandeiradaInicial,
        bandeiradaFinal: this.info.bandeiradaFinal,
        motorista: this.info.motorista ? this.info.motorista.minimizedMini() : null,
        veiculo: this.info.veiculo ? this.info.veiculo.minimizedMini() : null,
      },
      expenses: this.expenses,
      shiftEvents: this.shiftEvents.map((event) => event.minimized()),
      activities: this.activities.map((activity) => activity.minimized()),
      observacao: this.observacao,
      status: this.status,
      meta: this.meta,
    };

    return res;
  }

  minimizedMini() {
    const res: any = {
      id: this.id,

      info: {
        estado: this.info.estado,
        dataInicio: this.info.dataInicio,
        dataFim: this.info.dataFim,
        kmsVeiculoIniciais: this.info.kmsVeiculoIniciais,
        kmsVeiculoFinais: this.info.kmsVeiculoFinais,
        bandeiradaInicial: this.info.bandeiradaInicial,
        bandeiradaFinal: this.info.bandeiradaFinal,
        motorista: this.info.motorista ? this.info.motorista.minimizedMini() : null,
        veiculo: this.info.veiculo ? this.info.veiculo.minimizedMini() : null,
      },

      stats: this.stats,
    };

    return res;
  }

  /**
   * Heldper - Get a localização do documento na firestore
   */
  getdocRef(): DocumentReference {
    const docBaseCliente = doc(db, UTILIZADOR_COLLECTION, this.info.motorista.id);
    return doc(docBaseCliente, TURNO_COLLECTION, this.id);
  }
}

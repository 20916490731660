import { functions } from "./../../../common/services/firebase";
import moment from "moment";
import { increment, writeBatch, doc, getDocs, query, collection } from "firebase/firestore";
import { db } from "@/common/services/firebase";

import {
  SERVICO_COLLECTION,
  CLIENTE_COLLECTION,
  HISTORICO_COLLECTION,
  MORADA_COLLECTION,
  SERVICO_LVL1,
  SERVICO_LVL2,
} from "@/common/defs/collectionNames";

import Servico from "@/modules/servicos/types/Servico";
import Cliente from "@/modules/clientes/types/Cliente";
import Morada from "@/modules/moradas/types/Morada";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import DadoFaturacao from "@/modules/clientes/types/DadoFaturacao";
import { getMeta } from "@/common/services/IfirestoreObject";
import { get } from "lodash";
import Utilizador from "@/modules/utilizadores/types/Utilizador";
import Parceiro from "@/modules/parceiros/types/Parceiro";
import { dbOperations as ServicoBbOperations } from "./servicoStore";
import Turno from "@/modules/turnos/types/Turno";
import { httpsCallable, HttpsCallableOptions } from "firebase/functions";

interface State {
  servico: Servico;

  listaTempClientes: Cliente[];
  listaTempDadosFaturacao: DadoFaturacao[];
  listaTempMoradas: Morada[];
}

const state: State = {
  servico: new Servico(),

  listaTempClientes: [],
  listaTempDadosFaturacao: [],
  listaTempMoradas: [],
};

//Helper com as operações da firestore
const dbOperations = ServicoBbOperations;

const getters = {
  servico(state: State) {
    return state.servico;
  },

  listaTempClientes(state: State) {
    return state.listaTempClientes;
  },
  listaTempDadosFaturacao(state: State) {
    return state.listaTempDadosFaturacao;
  },
  listaTempMoradas(state: State) {
    return state.listaTempMoradas;
  },
};

const mutations = {
  resetServico(state: State) {
    state.servico = new Servico();
    state.listaTempClientes = [];
    state.listaTempDadosFaturacao = [];
    state.listaTempMoradas = [];
  },

  addClienteTemp(state: State, cliente: Cliente) {
    state.listaTempClientes.push(cliente);
  },
  addDadoFaturacaoTemp(state: State, dadoFaturacao: DadoFaturacao) {
    state.listaTempDadosFaturacao.push(dadoFaturacao);
  },
  addMoradaTemp(state: State, morada: Morada) {
    state.listaTempMoradas.push(morada);
  },
  clearTemps(state: State) {
    state.listaTempClientes = [];
    state.listaTempDadosFaturacao = [];
    state.listaTempMoradas = [];
  },
};

const actions = {
  //* Guarda um novo serviço na firebase.firestore
  async saveNewServico(state: any, servico: Servico) {
    const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];

    const clienteResponsavel = servico.info.clienteResponsavel;
    const batch = writeBatch(db);

    //Save clientes e moradas temporárias
    await Promise.all([saveClientes(state, servico), saveMoradas(state, servico.info.origem, servico.info.destino)]);

    //Set Meta
    servico.meta = getMeta(utilizadorLogedIn);
    //minimiza dados a guardar na DB
    const servicoMini = servico.minimized();

    //Divide o serviço nos docs
    const base = {
      info: servicoMini.info,
      status: servicoMini.status,

      motoristaId: servicoMini.motoristaId,
    };
    //Ponho a data de forma a poder filtar a group query por datas
    const lvl1 = {
      infoMoto: servicoMini.infoMoto,
      observacoes: servicoMini.observacoes,

      data: servicoMini.info.data,
      status: servicoMini.status,
    };
    const lvl2 = {
      meta: servicoMini.meta,

      motoristaId: servicoMini.motoristaId,
      data: servicoMini.info.data,
      status: servicoMini.status,
    };

    //Set o doc
    const docBase = doc(collection(db, SERVICO_COLLECTION));
    batch.set(docBase, base);
    batch.set(doc(docBase, SERVICO_LVL1, docBase.id), lvl1);
    batch.set(doc(docBase, SERVICO_LVL2, docBase.id), lvl2);

    //Set histórico
    const historicoItem = new HistoricoItem();
    historicoItem.setCreated();
    historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
    historicoItem.setNotification({
      id: docBase.id,
      title: `Novo serviço`,
      textFields: [{ text: `${moment(servico.info.data).calendar()}` }],
      link: `/servicos/${docBase.id}`,
    });
    batch.set(doc(collection(docBase, HISTORICO_COLLECTION)), historicoItem.minimized());

    //==== Update counts ====\\
    const idClienteResponsavel = get(clienteResponsavel, "id");
    await Promise.all([
      updateCountClienteResp(clienteResponsavel, batch, true),
      updateCountMorada(servico.info.origem, idClienteResponsavel, batch, true),
      updateCountMorada(servico.info.destino, idClienteResponsavel, batch, true),
    ]);

    //Eu não espero porque é sempre bem sucedido, mesmo offline.
    //Quando está offline nunca resolve a promessa, até ficar online
    batch.commit();
    return docBase.id;
  },

  /**
   * Update a data na firebase.firestore
   */
  updateDataDB(_: any, payload: { servico: Servico; historico: Servico }) {
    dbOperations
      .setOp(payload.servico)
      .update({ base: ["info.data"] })
      .log(payload.historico, {
        title: `Serviço modificado`,
        textFields: [
          {
            field: "info.data",
            text: `O serviço de ${moment(payload.historico.info.data).calendar()} passou para ${moment(
              payload.servico.info.data
            ).calendar()}`,
          },
        ],
        link: `/servicos/${payload.servico.id}`,
        textReplacement: `${moment(payload.servico.info.data).calendar()}`,
      })
      .update({ lvl1: [{ data: "info.data" }], lvl2: [{ data: "info.data" }], stats: [{ data: "info.data" }] })
      .setMeta()
      .commit();
  },

  /**
   * Update o cliente na firebase.firestore
   */
  async updateClienteDB(state: any, payload: { servico: Servico; historico: Servico }) {
    //Guarda os cliente se for necessário e por ref coloca os IDs nos novos clientes.
    await saveClientes(state, payload.servico);

    const batch = dbOperations
      .setOp(payload.servico)
      .update({ base: ["info.clienteResponsavel", "info.dadoFaturacao"] })
      .setMeta()
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .getBatch();

    // Update counts do uso dos clientes
    const clienteResponsavelHistorico = payload.historico.info.clienteResponsavel;
    await Promise.all([updateCountClienteRespEdit(payload.servico, clienteResponsavelHistorico, batch)]);
    batch.commit();
  },

  /**
   * Update o passageiro na firestore
   */
  updatePassageiroDB(_: any, payload: { servico: Servico; historico: Servico }) {
    const fieldsToBeUpdated = {
      base: [
        "info.passageiro.nome",
        "info.passageiro.contacto",
        "info.nPax",
        "info.numeroOvos",
        "info.numeroCadeirasTipo2",
        "info.numeroCadeirasTipo3",
      ],
    };
    dbOperations
      .setOp(payload.servico)
      .update(fieldsToBeUpdated)
      .setMeta()
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .commit();
  },

  /**
   * Update a viagem na firebase.firestore
   */
  async updateViagemDB(state: any, payload: { servico: Servico; historico: Servico }) {
    const origem = payload.servico.info.origem;
    const destino = payload.servico.info.destino;

    //Guarda as moradas se for necessário e por ref coloca os IDs nas novas moradas.
    await saveMoradas(state, origem, destino);

    const batch = dbOperations
      .setOp(payload.servico)
      .update({ base: ["info.tipoDeVeiculo", "info.voo", "info.origem", "info.destino"] })
      .setMeta()
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .getBatch();

    //==== Update counts ====\\
    const idClienteResponsavel = get(payload.servico.info.clienteResponsavel, "id");
    await Promise.all([
      updateCountMoradaEdit(origem, payload.historico.info.origem, idClienteResponsavel, batch),
      updateCountMoradaEdit(destino, payload.historico.info.destino, idClienteResponsavel, batch),
    ]);

    batch.commit();
  },

  /**
   * Update o pagamento na firebase.firestore
   */
  async updatePagamentoDB(_: any, payload: { servico: Servico; historico: Servico }) {
    const batch = dbOperations
      .setOp(payload.servico)
      .update({ base: ["info.valor", "info.condicaoPagamento", "info.metodoPagamento"] })
      .setMeta()
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .getBatch();

    await batch.commit();

    if (payload.servico.status.turnoId && payload.servico.motoristaId) {
      const options: HttpsCallableOptions = { timeout: 530 * 1000 }; // slightly less than the 540 seconds BE timeout
      const calculateShiftStats = httpsCallable(functions, "calculateShiftStats", options);
      calculateShiftStats({ shiftId: payload.servico.status.turnoId, userId: payload.servico.motoristaId });
    }
  },

  /**
   * Update despesas na firebase.firestore
   */
  async updateDespesasDB(_: any, payload: { servico: Servico; historico: Servico }) {
    const batch = dbOperations
      .setOp(payload.servico)
      .update({ lvl1: ["despesas.portagens", "despesas.partners", "despesas.commission"] })
      .setMeta()
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .getBatch();

    await batch.commit();

    if (payload.servico.status.turnoId && payload.servico.motoristaId) {
      const options: HttpsCallableOptions = { timeout: 530 * 1000 }; // slightly less than the 540 seconds BE timeout
      const calculateShiftStats = httpsCallable(functions, "calculateShiftStats", options);
      calculateShiftStats({ shiftId: payload.servico.status.turnoId, userId: payload.servico.motoristaId });
    }
  },

  /**
   * Update o descrição na firebase.firestore
   */
  updateDescricaoDB(_: any, payload: { servico: Servico; historico: Servico }) {
    dbOperations
      .setOp(payload.servico)
      .update({ base: ["info.descricao"] })
      .setMeta()
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .commit();
  },

  /**
   * Update o Motorista e veículo na firebase.firestore
   */
  updateMotoristaDB(_: any, payload: { servico: Servico; historico: Servico }) {
    if (payload.servico.infoMoto.motorista) {
      if (payload.servico.infoMoto.motorista instanceof Utilizador) payload.servico.infoMoto.isParceiro = false;
      else if (payload.servico.infoMoto.motorista instanceof Parceiro) payload.servico.infoMoto.isParceiro = true;
      else {
        console.log("Unable to determine driver type");
      }
    }

    const motoristaId = payload.servico.infoMoto.motorista ? payload.servico.infoMoto.motorista.id : null;
    dbOperations
      .setOp(payload.servico)
      .update({ lvl1: ["infoMoto.isParceiro", "infoMoto.motorista", "infoMoto.veiculo"] })
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .updateWithValues({
        base: { motoristaId: motoristaId },
        lvl2: { motoristaId: motoristaId },
        stats: { motoristaId: motoristaId },
      })
      .setMeta()
      .commit();
  },

  /**
   * Update o descrição na firebase.firestore
   */
  updateObservacaoDB(_: any, payload: { servico: Servico; historico: Servico }) {
    dbOperations
      .setOp(payload.servico)
      .update({ lvl1: ["observacoes"] })
      .setMeta()
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  async updateStatusDB(_: any, payload: { servico: Servico; historico: Servico }) {
    const batch = dbOperations
      .setOp(payload.servico)
      .update({ base: ["status.cancelado", "status.locked", "status.active"] })
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()} modificado`,
        link: `/servicos/${payload.servico.id}`,
      })
      .update({
        lvl1: ["status.cancelado", "status.locked", "status.active"],
        lvl2: ["status.cancelado", "status.locked", "status.active"],
        stats: ["status.cancelado", "status.locked", "status.active"],
      })
      .setMeta()
      .getBatch();

    await batch.commit();

    //only updates if canceled ou active changed
    const changed =
      payload.servico.status.active !== payload.historico.status.active ||
      payload.servico.status.cancelado !== payload.historico.status.cancelado;
    if (payload.servico.status.turnoId && payload.servico.motoristaId && changed) {
      const options: HttpsCallableOptions = { timeout: 530 * 1000 }; // slightly less than the 540 seconds BE timeout
      const calculateShiftStats = httpsCallable(functions, "calculateShiftStats", options);
      calculateShiftStats({ shiftId: payload.servico.status.turnoId, userId: payload.servico.motoristaId });
    }
  },

  /**
   * Update o situação na firebase.firestore
   */
  updateSituacaoDB(_: any, payload: { servico: Servico; historico: Servico }) {
    const batch = dbOperations
      .setOp(payload.servico)
      .update({ base: ["status.situacao"] })
      .log(payload.historico, {
        title: `Serviço ${moment(payload.historico.info.data).calendar()}`,
        link: `/servicos/${payload.servico.id}`,
      })
      .update({ lvl1: ["status.situacao"], lvl2: ["status.situacao"], stats: ["status.situacao"] })
      .setMeta()
      .getBatch();

    batch.commit();
  },

  /**
   * Update o shift na firebase.firestore
   */
  async updateShiftDB(state: any, payload: { shift: Turno; servico: Servico; historico: Servico }) {
    const batch = dbOperations
      .setOp(payload.servico)
      .update({ base: ["status.turnoId"] })
      .log(payload.historico)
      .update({ lvl1: ["status.turnoId"], lvl2: ["status.turnoId"], stats: ["status.turnoId"] })
      .setMeta()
      .getBatch();

    dbOperations.setOp(payload.shift, batch).logLog(
      `${payload.servico.status.turnoId ? " Foi adicionado" : "For removido"} o serviço das 
        ${moment(payload.historico.info.data).format("DD/MM/YYYY [às] HH:mm")} ao turno`
    );

    await batch.commit();

    //If a remove from the shift i still have to update it, the turnoId will be null
    const changed = payload.servico.status.turnoId !== payload.historico.status.turnoId;
    if (changed) {
      console.log("Recalculating");
      const turnoId = payload.servico.status.turnoId ?? payload.historico.status.turnoId;
      const userId = payload.servico.motoristaId ?? payload.historico.motoristaId;
      const options: HttpsCallableOptions = { timeout: 530 * 1000 }; // slightly less than the 540 seconds BE timeout
      const calculateShiftStats = httpsCallable(functions, "calculateShiftStats", options);
      calculateShiftStats({ shiftId: turnoId, userId: userId });
    }
  },

  /**
   * Elimina permanentemente a servico na firebase.firestore
   */
  async deleteServicoDB(state: any, servico: Servico) {
    const docBase = doc(db, SERVICO_COLLECTION, servico.id);
    const batch = writeBatch(db);

    batch.delete(docBase);
    batch.delete(doc(docBase, SERVICO_LVL1, servico.id));
    batch.delete(doc(docBase, SERVICO_LVL2, servico.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
    return docBase.id;
  },
};

/**
 * Guarda os clientes criados dentro do serviço caso seja necesssário.
 * Se guardar coloca os respectivos ids no clientes.
 */
async function saveClientes(state: any, servico: Servico) {
  try {
    const clienteResponsavel = servico.info.clienteResponsavel;
    const isNewDadoFaturacao =
      servico.info.dadoFaturacao &&
      clienteResponsavel.info.nome + clienteResponsavel.info.nif !== servico.info.dadoFaturacao.id && //é diferente do default dado de faturacao que é o proprio cliente
      !clienteResponsavel.dadosFaturacao.find((df) => df.id === servico.info.dadoFaturacao.id);

    //Se é um cliente novo
    if (clienteResponsavel && !clienteResponsavel.id) {
      //Se o dado de faturação é novo e está selecionado, coloca-o no cliente.
      if (isNewDadoFaturacao) clienteResponsavel.dadosFaturacao.push(servico.info.dadoFaturacao);
      const id = await state.dispatch("editClienteStore/saveNewCliente", clienteResponsavel, { root: true });
      clienteResponsavel.id = id;
    } else if (isNewDadoFaturacao) {
      //Dados que já exitem no cliente
      const dadosFaturacaoChanges = clienteResponsavel.dadosFaturacao.map((df) => {
        return { type: "unchanged", dadoFaturacao: df };
      });

      //Dado novo
      dadosFaturacaoChanges.push({ type: "new", dadoFaturacao: servico.info.dadoFaturacao });

      await state.dispatch(
        "editClienteStore/updateDadosFaturacaoDB",
        { cliente: clienteResponsavel, dadosFaturacaoChanges, historico: clienteResponsavel },
        { root: true }
      );
    }
  } catch (e) {
    throw "Erro a guardar o cliente do serviço";
  }
}

/**
 * Guarda as moradas criadas dentro do serviço caso seja necesssário.
 * Se guardar coloca os ids nas respectiveis moradas.
 */
async function saveMoradas(state: any, origem: Morada, destino: Morada) {
  if (origem && !origem.id && destino && !destino.id) {
    if (origem === destino) {
      const id = await state.dispatch("moradaStore/saveNewMorada", origem, { root: true });
      origem.id = destino.id = id;
    } else {
      const resultado = await Promise.all([
        state.dispatch("moradaStore/saveNewMorada", origem, { root: true }),
        state.dispatch("moradaStore/saveNewMorada", destino, { root: true }),
      ]);
      origem.id = resultado[0];
      destino.id = resultado[1];
    }
  } else if (origem && !origem.id) {
    const id = await state.dispatch("moradaStore/saveNewMorada", origem, { root: true });
    origem.id = id;
  } else if (destino && !destino.id) {
    const id = await state.dispatch("moradaStore/saveNewMorada", destino, { root: true });
    destino.id = id;
  }
}

/**
 * Actualiza se for necessário os stats (número de usos) do cliente responsavel
 */
async function updateCountClienteRespEdit(servico: Servico, clienteAntigo: Cliente, batch: any) {
  const cliente = servico.info.clienteResponsavel;
  if (cliente && clienteAntigo) {
    if (cliente.id !== clienteAntigo.id) {
      updateCountClienteResp(cliente, batch, true);
      updateCountClienteResp(clienteAntigo, batch, false);
    }
  } else if (cliente && !clienteAntigo) updateCountClienteResp(cliente, batch, true);
  else if (!cliente && clienteAntigo) updateCountClienteResp(clienteAntigo, batch, false);
}

async function updateCountClienteResp(cliente: Cliente, batch: any, add: boolean) {
  if (cliente) {
    const refClienteFac = doc(db, CLIENTE_COLLECTION, cliente.id);
    batch.update(refClienteFac, { "stats.nUsos": increment(add ? 1 : -1) });
  }
}

async function updateCountMoradaEdit(morada: Morada, moradaAntiga: Morada, idResp: string, batch: any) {
  if (morada && moradaAntiga) {
    if (morada.id !== moradaAntiga.id) {
      updateCountMorada(morada, idResp, batch, true);
      updateCountMorada(moradaAntiga, idResp, batch, false);
    }
  } else if (morada && !moradaAntiga) updateCountMorada(morada, idResp, batch, true);
  else if (!morada && moradaAntiga) updateCountMorada(moradaAntiga, idResp, batch, false);
}

async function updateCountMorada(morada: Morada, idResp: string, batch: any, add: boolean) {
  if (morada) {
    const changes: any = { "stats.nUsos": increment(add ? 1 : -1) };
    if (idResp) changes[`stats.idsFreq.${idResp}`] = increment(add ? 1 : -1);
    batch.update(doc(db, MORADA_COLLECTION, morada.id), changes);
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

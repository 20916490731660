import { covertTimestamp } from "@/common/services/IfirestoreObject";

export class Observacao {
  data: Date = null;
  texto: string = null;

  constructor(json?: any) {
    if (json) this.setData(json);
  }

  setData(json?: any) {
    this.data = covertTimestamp(json.data);
    this.texto = json.texto;
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    return {
      data: this.data,
      text: this.texto,
    };
  }
}

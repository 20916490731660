import { covertTimestamp } from "@/common/services/IfirestoreObject";
import moment from "moment";
import { Position } from "./Position";

const SIXTY_SECONDS = 60000;
const SIXTY_MINUTES = 3600000;

export const activityType = ["DRIVING", "RUNNING", "WALKING", "STILL"] as const;
export type ActivityType = (typeof activityType)[number];

export default class Activity {
  type = "ACTIVITY";
  activityType: ActivityType = null;
  transitionType: string = null;
  startDate: Date = null;
  endDate: Date = new Date();
  positions: Position[] = []; //Local
  isMapInfoWindowOpened = false;
  isCreatedByGps = false;

  constructor(initData?: any) {
    if (initData) {
      this.activityType = initData.activityType;
      this.transitionType = initData.transitionType;
      this.startDate = covertTimestamp(initData.startDate);
      this.endDate = initData.endDate ? initData.endDate.toDate() : new Date();
      this.isCreatedByGps = initData.isCreatedByGps ?? null;
    }
  }

  get id() {
    return this.activityType + this.startDate.toISOString() + this.isCreatedByGps;
  }

  get duration() {
    return this.endDate.getTime() - this.startDate.getTime();
  }

  setPositions(shiftPositions: Position[]) {
    let lastPositionIndex;
    for (let i = 0; i < shiftPositions.length; i++) {
      const position = shiftPositions[i];
      const positionTime = position.timestamp.getTime();
      if (
        positionTime >= this.startDate.getTime() &&
        positionTime <= this.endDate.getTime() &&
        position.accuracy < 20
      ) {
        this.positions.push(position);
        lastPositionIndex = i;
      }
    }

    if (lastPositionIndex && lastPositionIndex < shiftPositions.length - 1) {
      this.positions.push(shiftPositions[lastPositionIndex + 1]);
    }
  }

  getInfoString(eventIndex: number, activityIndex: number) {
    let durationString = "";
    if (this.duration > SIXTY_MINUTES) durationString = moment.utc(this.duration).format("H [hora e] m [mins]");
    else if (this.duration > SIXTY_SECONDS) durationString = moment.utc(this.duration).format("m [mins]");
    else durationString = moment.utc(this.duration).format("s [segundos]");

    return `(${eventIndex + 1} - ${activityIndex + 1}) ${moment(this.startDate).format(
      "[às] HH:mm:ss"
    )} <br> ${durationString}`;
  }

  getPathLiteral() {
    return this.positions.map((position) => position.getLiteralCoords());
  }

  /**
   * Gets the most accurate position, usefull when the user is still and we want the know the position
   */
  getMostAccuratePosition() {
    if (this.positions.length === 0) return null;

    const mostAccuratePosition = this.positions.reduce((prevPosition, currentPosition) => {
      return prevPosition.accuracy < currentPosition.accuracy ? prevPosition : currentPosition;
    });
    return mostAccuratePosition;
  }

  calculateDistance() {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    let distance = 0;

    for (let i = 0; i < this.positions.length - 1; i++) {
      const pos1 = this.positions[i];
      const pos2 = this.positions[i + 1];
      const a =
        0.5 -
        c((pos2.latitude - pos1.latitude) * p) / 2 +
        (c(pos1.latitude * p) * c(pos2.latitude * p) * (1 - c((pos2.longitude - pos1.longitude) * p))) / 2;
      distance += 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }
    return distance;
  }

  /**
   * Minimizes the object to store it in firestore
   */
  minimized() {
    const res: any = {
      activityType: this.activityType,
      transitionType: this.transitionType,
      startDate: this.startDate,
    };
    return res;
  }
}

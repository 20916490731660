import { db } from "@/common/services/firebase";
import { doc } from "firebase/firestore";
import { CLIENTE_COLLECTION } from "@/common/defs/collectionNames";
import Morada from "@/modules/moradas/types/Morada";
import Contacto from "./Contacto";
import { Observacao } from "./Observacao";
import { IfirestoreObject, Imeta, covertTimestamp } from "@/common/services/IfirestoreObject";
import { DocumentReference } from "firebase/firestore";
import DadoFaturacao from "./DadoFaturacao";

export const denominacoes = ["Sr.", "Sra.", "Dr.", "Dra.", "Empresa"] as const;
export const condicoesPagamento = ["Pronto pagamento", "5 dias", "15 dias", "30 dias"] as const;
export const idiomas = ["Português", "Inglês", "Espanhol", "Francês", "Alemão"];

type Denominacoes = (typeof denominacoes)[number];
type CondicoesPagamento = (typeof condicoesPagamento)[number];
type Idiomas = (typeof idiomas)[number];

interface IclienteInfo {
  denominacao: Denominacoes;
  nome: string;
  nif: string;
  aniversario: string;
  condicaoPagamento: CondicoesPagamento;
  idioma: Idiomas;
  vip: number;
}

export default class Cliente implements IfirestoreObject {
  id?: string;

  //===================== BASE =======================\\
  info: IclienteInfo = {
    denominacao: null,
    nome: null,
    nif: null,
    aniversario: null,
    condicaoPagamento: "Pronto pagamento",
    idioma: "Português",
    vip: 1,
  };

  dadosFaturacao: DadoFaturacao[] = [];
  moradas: Morada[] = [];
  contactos: Contacto[] = [];

  status: {
    active: boolean;
  } = { active: true };

  stats: {
    nUsos: number;
  } = {
    nUsos: 0,
  };

  //===================== LVL 1 =======================\\
  observacoes: Observacao[] = [];

  //===================== LVL 2 =======================\\
  conta: {
    balanco: number;
  } = { balanco: 0 };

  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  get friendlyName() {
    return {
      "info.denominacao": { name: "Denominação", changed: "A denominação foi alterada" },
      "info.nome": { name: "Nome", changed: "O nome foi alterado" },
      "info.nif": { name: "Nif", changed: "O nif foi alterado" },
      "info.aniversario": { name: "Aniversário", changed: "O data de aniversário foi alterada" },
      "info.condicaoPagamento": { name: "Condição de pagamento", changed: "O condição de pagamento foi alterada" },
      "info.idioma": { name: "Idioma", changed: "O idioma foi alterado" },
      "info.vip": { name: "VIP", changed: "O nível de vip foi alterado" },

      dadosFaturacao: { name: "Dados de faturação", changed: "Os dados de faturação foram alterados" },
      moradas: { name: "Moradas", changed: "As moradas foram alteradas" },
      contactos: { name: "Contactos", changed: "Os contactos foram alterados" },

      observacoes: { name: "Observações", changed: "As observações foram alteradas" },

      "status.active": {
        name: "Ativo",
        changed: `O Cliente foi ${this.status.active ? "ativado" : "desativado"}`,
      },
    };
  }

  /**
   * Retorna a morada de faturação que está nas moradas genéricas
   */
  get moradaFaturacaoBase() {
    if (this.moradas.length === 0) return null;
    return this.moradas.find((morada) => morada.correspondencia.isMoradaFaturacao);
  }

  setData(json: any) {
    this.info = {
      denominacao: json.info.denominacao,
      nome: json.info.nome,
      nif: json.info.nif,
      aniversario: json.info.aniversario,
      condicaoPagamento: json.info.condicaoPagamento,
      idioma: json.info.idioma,
      vip: json.info.vip,
    };

    this.status = json.status ? json.status : { active: true };
    this.stats = json.stats ? json.stats : { nUsos: 0 };

    //Quando é updated tenho de fazer reset ao array primeiro
    this.dadosFaturacao = [];
    this.moradas = [];
    this.contactos = [];

    //Quando é um array de objectos, tenho de procorrer o array e criar os objectos
    if (json.dadosFaturacao) {
      for (const dadosFaturacaoJson of json.dadosFaturacao)
        this.dadosFaturacao.push(new DadoFaturacao(dadosFaturacaoJson));
    }
    if (json.moradas) for (const moradaJson of json.moradas) this.moradas.push(new Morada(moradaJson.id, moradaJson));
    if (json.contactos) for (const conctactoJson of json.contactos) this.contactos.push(new Contacto(conctactoJson));
  }

  setLvl1(json: any) {
    for (const observacaoJson of json.observacoes) this.observacoes.push(new Observacao(observacaoJson));
  }

  setLvl2(json: any) {
    this.conta = json.conta ? json.conta : { balanco: 0 };
    this.meta = {
      createdAt: covertTimestamp(json.meta.createdAt),
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      //--------- Base ----------\\
      id: this.id,

      info: {
        denominacao: this.info.denominacao,
        nome: this.info.nome,
        nif: this.info.nif,
        aniversario: this.info.aniversario,
        condicaoPagamento: this.info.condicaoPagamento,
        idioma: this.info.idioma,
        vip: this.info.vip,
      },

      dadosFaturacao: this.dadosFaturacao.map((dadosFaturacao) => dadosFaturacao.minimized()),
      moradas: this.moradas.map((morada) => morada.minimizedMini()),
      contactos: this.contactos.map((contacto) => contacto.minimized()),

      status: this.status,
      stats: this.stats,

      //--------- Lvl1 ----------\\
      observacoes: this.observacoes.map((observacao) => observacao.minimized()),

      //--------- Lvl2 ----------\\
      conta: this.conta,
      meta: this.meta,
    };
    return res;
  }

  minimizedMini() {
    return {
      id: this.id,
      info: {
        denominacao: this.info.denominacao,
        nome: this.info.nome,
        nif: this.info.nif,
      },
    };
  }

  getdocRef(): DocumentReference {
    return doc(db, CLIENTE_COLLECTION, this.id);
  }
}

/**
 * Helper para validar o nif do cliente
 * @returns true se for válido ou a string "Nif inválido"
 */
export function validaNif(nif: string): boolean | string {
  if (nif && nif.length > 0 && /^[0-9]*$/.test(nif)) {
    // se só contém números faz a validação, é assim para não efetuar a validação no nifs estrangeiros que têm letras.
    if (nif.length !== 9) return "Nif inválido";
    let soma = 0;
    for (let i = 0; i < nif.length - 1; i++) {
      const escalar = 9 - i;
      const digito = parseInt(nif.charAt(i), 10);
      soma += digito * escalar;
    }

    const modulos = soma % 11;
    let controlo;
    if (modulos === 0 || modulos === 1) controlo = 0;
    else controlo = 11 - modulos;

    if (parseInt(nif.charAt(8), 10) === controlo) return true;
    else return "Nif inválido";
  } else {
    return true;
  }
}

/**
 * @returns as Iniciais do primeiro e último nome para por no avatar
 */
export function getLetrasAvatar(nome: string) {
  if (!nome) return "";
  const arrayNomes = nome.trim().split(" ");
  if (arrayNomes.length > 1) {
    return nome.slice(0, 1).toUpperCase() + arrayNomes.pop().slice(0, 1).toUpperCase();
  } else {
    return nome.slice(0, 1).toUpperCase();
  }
}

import { doc, WriteBatch, writeBatch, collection, DocumentData, DocumentReference } from "firebase/firestore";
import { get, isString, set } from "lodash";

import store from "../store/store";
import { db } from "@/common/services/firebase";
import { HISTORICO_COLLECTION } from "@/common/defs/collectionNames";
import { IfirestoreObject, getMeta } from "@/common/services/IfirestoreObject";
import HistoricoItem, { Inotification } from "@/common/components/historico/HistoricoItem";

// construct dict object that contains our mapping between strings and classes

export default class DbSetOperation {
  private object: IfirestoreObject;
  private collectionLvl1Name: string;
  private collectionLvl2Name: string;
  private collectionStatsName: string;
  private metaCollection: string;

  private docBaseRef: DocumentReference<DocumentData>;
  private batch: WriteBatch;

  private updateData: {
    base?: { [Key: string]: any };
    lvl1?: { [Key: string]: any };
    lvl2?: { [Key: string]: any };
    stats?: { [Key: string]: any };
  } = { base: {}, lvl1: {}, lvl2: {}, stats: {} };

  constructor(
    object: IfirestoreObject,
    batch?: WriteBatch,
    metaCollection?: string,
    collectionLvl1Name?: string,
    collectionLvl2Name?: string,
    collectionStatsName?: string
  ) {
    this.object = object;
    this.metaCollection = metaCollection;
    this.collectionLvl1Name = collectionLvl1Name;
    this.collectionLvl2Name = collectionLvl2Name;
    this.collectionStatsName = collectionStatsName;

    this.docBaseRef = this.object.getdocRef();
    this.batch = batch ?? writeBatch(db);
  }

  /**
   * Faz o update na firestore
   * @param fields os fields a guardar, procura no objecto os values respectivos dos fields fornecidos.
   * @param object em vez de procurar os values no objeto do contrutor procura neste
   */
  public update(fields: { [lvlName: string]: string[] | { [key: string]: string }[] }, object?: IfirestoreObject) {
    //Se o user quiser usar a informação de outro objecto usamos esse
    const objMini = object ? object.minimized() : this.object.minimized();
    this.updateData = { base: {}, lvl1: {}, lvl2: {}, stats: {} };

    if (fields.base) {
      this.updateData.base = this.getDataToUpdate(fields.base, objMini);
      this.batch.update(this.docBaseRef, this.updateData.base);
    }

    if (fields.lvl1) {
      this.updateData.lvl1 = this.getDataToUpdate(fields.lvl1, objMini);
      this.batch.update(doc(this.docBaseRef, this.collectionLvl1Name, this.docBaseRef.id), this.updateData.lvl1);
    }

    if (fields.lvl2) {
      this.updateData.lvl2 = this.getDataToUpdate(fields.lvl2, objMini);
      this.batch.update(doc(this.docBaseRef, this.collectionLvl2Name, this.docBaseRef.id), this.updateData.lvl2);
    }

    if (fields.stats) {
      //This documento may not exist, thats why a use set with merge
      this.updateData.stats = this.getDataToSet(fields.stats, objMini);
      this.batch.set(doc(this.docBaseRef, this.collectionStatsName, this.docBaseRef.id), this.updateData.stats, {
        merge: true,
      });
    }

    //Retorna o objeto para encadear
    return this;
  }

  /**
   * Faz o update na firestore, já recebe os valor prontos a guardar na DB
   * @param updateValues
   */
  public updateWithValues(updateValues: { [lvlName: string]: { [Key: string]: any } }) {
    if (updateValues.base) {
      this.updateData.base = updateValues.base;
      this.batch.update(this.docBaseRef, this.updateData.base);
    }

    if (updateValues.lvl1) {
      this.updateData.lvl1 = updateValues.lvl1;
      this.batch.update(doc(this.docBaseRef, this.collectionLvl1Name, this.docBaseRef.id), this.updateData.lvl1);
    }

    if (updateValues.lvl2) {
      this.updateData.lvl2 = updateValues.lvl2;
      this.batch.update(doc(this.docBaseRef, this.collectionLvl2Name, this.docBaseRef.id), this.updateData.lvl2);
    }

    //Retorna o objeto para encadear
    return this;
  }

  /**
   * Set meta no doc
   */
  public setMeta() {
    const loggedInUser = store.getters["utilizadorStore/getUtilizadorLoggedIn"];
    const updateData = { meta: getMeta(loggedInUser) };
    this.batch.update(doc(this.docBaseRef, this.metaCollection, this.docBaseRef.id), updateData);

    return this;
  }

  /**
   * Coloca as alterações no historico
   * Apenas coloca o log do ultimo update
   * @param old o onjecto com a informação antiga
   */
  public log(old: IfirestoreObject, notification?: Inotification) {
    const loggedInUser = store.getters["utilizadorStore/getUtilizadorLoggedIn"];

    const historicoItem = new HistoricoItem();
    historicoItem.setChanges(this.object.minimized(), old.minimized(), [
      ...Object.keys(this.updateData.base),
      ...Object.keys(this.updateData.lvl1),
      ...Object.keys(this.updateData.lvl2),
    ]);

    //Se não houver nudanças dá erro
    if (historicoItem.changes.length == 0) throw "No changes";

    if (notification) {
      notification.id = this.object.id;
      //Se a notificação não tiver text, cria o text padrão
      if (!notification.textFields) {
        const notificationFields = [];
        for (const change of historicoItem.changes) {
          const friendlyName = this.object.friendlyName[change.field];
          if (friendlyName?.changed) notificationFields.push({ field: change.field, text: friendlyName.changed });
        }
        notification.textFields = notificationFields;
      }

      historicoItem.setNotification(notification);
    }

    historicoItem.meta = getMeta(loggedInUser);

    this.batch.set(doc(collection(this.docBaseRef, HISTORICO_COLLECTION)), historicoItem.minimized());
    return this;
  }

  /**
   * Coloca as alterações no historico
   * @param title titulo do custom log
   * @param customData
   */
  public logCustom(title: string, customData: { [key: string]: any }) {
    const loggedInUser = store.getters["utilizadorStore/getUtilizadorLoggedIn"];

    const historicoItem = new HistoricoItem();
    historicoItem.setCustom(title, customData);
    historicoItem.meta = getMeta(loggedInUser);

    this.batch.set(doc(collection(this.docBaseRef, HISTORICO_COLLECTION)), historicoItem.minimized());
    return this;
  }

  /**
   * Coloca as alterações no historico
   * @param title titulo do log Log
   */
  public logLog(title: string, value?: string) {
    const loggedInUser = store.getters["utilizadorStore/getUtilizadorLoggedIn"];

    const historicoItem = new HistoricoItem();
    historicoItem.setLog(title, value);
    historicoItem.meta = getMeta(loggedInUser);

    this.batch.set(doc(collection(this.docBaseRef, HISTORICO_COLLECTION)), historicoItem.minimized());
    return this;
  }

  /**
   * Commit para a firestore
   */
  public getBatch() {
    return this.batch;
  }

  /**
   * Commit para a firestore
   */
  public async commit() {
    return await this.batch.commit();
  }

  /**
   * Get os fields do objecto que serão updated e retorna-os em forma de update array
   * @param fields lista de fields que serão updated
   * @param objMini o objecto com os dados
   * @returns "{[field do objecto]: valor que vai fazer overwrite}"
   */
  private getDataToUpdate(fields: string[] | { [key: string]: string }[], objMini: any) {
    const returnUpdateData: { [Key: string]: any } = {};

    for (const field of fields) {
      //Se o field for só uma string, get esse field no objecto e coloca no update com a mesma key
      if (isString(field)) {
        returnUpdateData[field] = get(objMini, field, null);
      }
      //Se o field for um objecto, get o value no objecto e coloca no update com uma key diferente
      else {
        const entrie = Object.entries(field)[0];
        returnUpdateData[entrie[0]] = get(objMini, entrie[1], null);
      }
    }
    return returnUpdateData;
  }

  /**
   * Get os fields do objecto que serão updated e retorna-os em forma de update array
   * @param fields lista de fields que serão updated
   * @param objMini o objecto com os dados
   * @returns "{[field do objecto]: valor que vai fazer overwrite}"
   */
  private getDataToSet(fields: string[] | { [key: string]: string }[], objMini: any) {
    const returnUpdateData: { [Key: string]: any } = {};

    for (const field of fields) {
      //Se o field for só uma string, get esse field no objecto e coloca no update com a mesma key
      if (isString(field)) {
        set(returnUpdateData, field, get(objMini, field, null));
      }
      //Se o field for um objecto, get o value no objecto e coloca no update com uma key diferente
      else {
        const entrie = Object.entries(field)[0];
        set(returnUpdateData, entrie[0], get(objMini, entrie[1], null));
      }
    }
    return returnUpdateData;
  }
}

const ViewAdmin = () => import(/* webpackChunkName: "chunk-admin" */ "@/modules/admin/views/ViewAdmin.vue");
const AdminDashboard = () => import(/* webpackChunkName: "chunk-admin" */ "@/modules/admin/views/AdminDashboard.vue");

//========== VÉICULOS ==========\\
export const adminRoutes = [
  {
    path: "/admin",
    name: "ViewAdmin",
    redirect: { name: "AdminDashboard" },
    component: ViewAdmin,
    meta: {
      requiresAuth: true,
      requires: ["admin.viewDashboard"],
    },
    children: [
      {
        path: "dashboard",
        name: "AdminDashboard",
        component: AdminDashboard,
        meta: {
          appBarTitle: "Admin",
          requiresAuth: true,
          requires: ["admin.viewDashboard"],
        },
      },
    ],
  },
];

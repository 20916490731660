import { db } from "@/common/services/firebase";
import { DocumentReference, doc } from "firebase/firestore";

import { CLIENTE_COLLECTION, PAGAMENTO_COLLECTION } from "@/common/defs/collectionNames";
import { IfirestoreObject, Imeta, covertTimestamp } from "@/common/services/IfirestoreObject";
import Cliente from "@/modules/clientes/types/Cliente";
import Utilizador from "@/modules/utilizadores/types/Utilizador";
import { isEmpty } from "lodash";

interface InfoPagamento {
  valor: number;
  consultasAssociadas: { [idConsulta: string]: number };
  metodo: "Numerário" | "Multibanco" | "MB Way" | "Transferência bancária";
  nome: string;
  ref: string;
  data: Date;
  cliente: Cliente;
  utilizador: Utilizador;
  observacao: string;
}

export default class Pagamento implements IfirestoreObject {
  id?: string;

  //=============== BASE ======================\\
  info: InfoPagamento = {
    valor: null,
    consultasAssociadas: {},
    metodo: null,
    nome: null,
    ref: null,
    data: null,
    cliente: null,
    utilizador: null,
    observacao: null,
  };

  status: {
    locked: boolean;
    active: boolean;
  } = { locked: false, active: true };

  //=============== LvL 1======================\\
  meta: Imeta = {
    createdAt: null,
    createdBy: { uid: null, nome: null },
  };

  //============================================ Métodos ====================================================\\
  constructor(id?: string, json?: any) {
    if (id) this.id = id;
    if (json) this.setData(json);
  }

  get friendlyName() {
    return {
      "info.valor": { name: "Valor", changed: "O valor foi alterado" },
      "info.consultasAssociadas": {
        name: "Consultas associadas",
        changed: "A lista de consultas associadas foi alterada",
      },
      "info.metodo": { name: "Método", changed: "O método foi alterado" },
      "info.nome": { name: "Nome", changed: "O nome foi alterado" },
      "info.ref": { name: "Referência", changed: "A referência foi alterada" },
      "info.data": { name: "Data", changed: "A data foi alterada" },
      "info.utilizador": { name: "Utilizador", changed: "O utilizador foi alterado" },

      "status.active": {
        name: "Ativo",
        changed: `O Pagamento foi ${this.status.active ? "ativado" : "desativado"}`,
      },
    };
  }

  get valorUtilizado(): number {
    if (isEmpty(this.info.consultasAssociadas)) return 0;
    else
      return Number(
        Object.values(this.info.consultasAssociadas)
          .reduce((a, b) => a + b)
          .toFixed(2)
      );
  }

  get valorDisponivel(): number {
    //Como isto faz a subtração com Floats dá um número infinito de decimais
    //Logo temo de truncar os decimais com o toFixed(2) que retorna uma string, que tb é convertida novamente num Number
    //https://stackoverflow.com/questions/14490496/how-to-multiply-in-javascript-problems-with-decimals
    return Number((this.info.valor - this.valorUtilizado).toFixed(2));
  }

  setData(json: any): void {
    this.info = {
      valor: json.info.valor,
      consultasAssociadas: json.info.consultasAssociadas ? json.info.consultasAssociadas : {},
      metodo: json.info.metodo,
      nome: json.info.nome,
      ref: json.info.ref,
      data: covertTimestamp(json.info.data),
      cliente: null,
      utilizador: null,
      observacao: json.info.observacao,
    };

    if (json.info.cliente) this.info.cliente = new Cliente(json.info.cliente.id, json.info.cliente);
    if (json.info.utilizador) this.info.utilizador = new Utilizador(json.info.utilizador.id, json.info.utilizador);

    if (json.status) this.status = json.status;
  }

  setLvl1(json: any) {
    this.meta = {
      createdAt: json.meta.createdAt ? json.meta.createdAt.toDate() : null,
      createdBy: json.meta.createdBy,
    };
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    const res = {
      id: this.id,
      info: {
        valor: this.info.valor,
        consultasAssociadas: this.info.consultasAssociadas,
        metodo: this.info.metodo,
        nome: this.info.nome,
        ref: this.info.ref,
        data: this.info.data,
        cliente: this.info.cliente ? this.info.cliente.minimizedMini() : null,
        utilizador: this.info.utilizador ? this.info.utilizador.minimizedMini() : null,
        observacao: this.info.observacao,
      },
      status: this.status,
      meta: this.meta,
    };
    return res;
  }

  minimizedMini() {
    const res = {
      id: this.id,
      info: {
        data: this.info.data,
        valor: this.info.valor,
        metodo: this.info.metodo,
        cliente: this.info.cliente ? this.info.cliente.minimizedMini() : null,
        utilizador: this.info.utilizador ? this.info.utilizador.minimizedMini() : null,
        consultasAssociadas: this.info.consultasAssociadas,
      },
    };
    return res;
  }

  /**
   * Heldper - Get a localização do documento na firestore
   */
  getdocRef(): DocumentReference {
    const docBaseCliente = doc(db, CLIENTE_COLLECTION, this.info.cliente.id);
    return doc(docBaseCliente, PAGAMENTO_COLLECTION, this.id);
  }
}

const EditVeiculoPlain = () =>
  import(/* webpackChunkName: "chunk-veiculos" */ "@/modules/veiculos/views/EditVeiculoPlain.vue");
const ListaVeiculos = () =>
  import(/* webpackChunkName: "chunk-veiculos" */ "@/modules/veiculos/views/ListaVeiculos.vue");
const ViewVeiculo = () => import(/* webpackChunkName: "chunk-veiculos" */ "@/modules/veiculos/views/ViewVeiculo.vue");
const VeiculoInfo = () => import(/* webpackChunkName: "chunk-veiculos" */ "@/modules/veiculos/views/VeiculoInfo.vue");
const VehicleCalendar = () =>
  import(/* webpackChunkName: "chunk-veiculos" */ "@/modules/veiculos/views/VehicleCalendar.vue");
const VeiculoHistorico = () =>
  import(/* webpackChunkName: "chunk-veiculos" */ "@/modules/veiculos/views/VeiculoHistorico.vue");

//========== VÉICULOS ==========\\
export const veiculosRoutes = [
  {
    path: "/veiculos",
    name: "ListaVeiculos",
    component: ListaVeiculos,
    meta: {
      requiresAuth: true,
      requires: ["veiculos.viewLista"],
    },
  },
  {
    path: "/veiculos/novo",
    name: "EditVeiculoPlain",
    component: EditVeiculoPlain,
    meta: {
      requiresAuth: true,
      requires: ["veiculos.create"],
    },
  },
  {
    path: "/veiculos/:id",
    name: "ViewVeiculo",
    redirect: { name: "VeiculoInfo" },
    component: ViewVeiculo,
    props: true,
    meta: {
      requiresAuth: true,
      requires: ["veiculos.viewVeiculo"],
    },
    children: [
      {
        path: "informacao",
        name: "VeiculoInfo",
        component: VeiculoInfo,
        meta: {
          appBarTitle: "Veículo",
          requiresAuth: true,
          requires: ["veiculos.viewVeiculo"],
        },
      },
      {
        path: "calendar",
        name: "VeiculoCalendar",
        component: VehicleCalendar,
        meta: {
          appBarTitle: "Veículo",
          requiresAuth: true,
          requires: ["veiculos.viewCalendar"],
        },
      },
      {
        path: "historico",
        name: "VeiculoHistorico",
        component: VeiculoHistorico,
        meta: {
          appBarTitle: "Veículo",
          requiresAuth: true,
          requires: ["veiculos.viewHistorico"],
        },
      },
    ],
  },
];

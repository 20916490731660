import Morada from "@/modules/moradas/types/Morada";

export default class DadoFaturacao {
  nome: string = null;
  nif: string = null;
  morada: Morada = null;
  observacao: string = null;

  constructor(json?: any) {
    if (json) this.setData(json);
  }

  //Como não tem ID gera este para poder ser selecionado nos dropdowns
  get id() {
    return this.nome + this.nif;
  }

  setData(json?: any) {
    this.nome = json.nome;
    this.nif = json.nif;
    this.morada = null;

    if (json.morada) this.morada = new Morada(json.morada.id, json.morada);
  }

  /**
   * Minimiza o objecto de forma a guardar no Firestore
   */
  minimized() {
    return {
      id: this.id,
      nome: this.nome,
      nif: this.nif,
      morada: this.morada ? this.morada.minimizedMini() : null,
      observacao: this.observacao,
    };
  }
}

import { PARCEIRO_COLLECTION, PARCEIRO_LVL1, HISTORICO_COLLECTION } from "@/common/defs/collectionNames";
import { Query, writeBatch, doc, getDocs, query, collection, orderBy, DocumentReference } from "firebase/firestore";
import { ref, uploadBytes, deleteObject } from "firebase/storage";
import Compressor from "compressorjs";

import { db, storage } from "@/common/services/firebase";
import Parceiro from "../types/Parceiro";
import { getMeta } from "@/common/services/IfirestoreObject";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import DBOperations from "@/common/services/DBOperations";
import { ActionContext } from "vuex";

export interface State {
  parceiros: Parceiro[];
}

const state: State = {
  parceiros: [],
};

const dbOperations = new DBOperations("Parceiro", {
  metaCollection: PARCEIRO_LVL1,
  lvl1Name: PARCEIRO_LVL1,
});

const getters = {
  //* Retorna array dos parceiros. ! NÃO É IMUTÁVEL !
  getParceiros(state: State) {
    return state.parceiros;
  },
  //* retorna uma DEEPCOPPY do utilizador com o ID solicitado.
  getParceiro: (state: State) => (id: string) => {
    return state.parceiros.find((parceiro: Parceiro) => parceiro.id === id);
  },
  //* Retorna array dos parceiros activos. ! NÃO É IMUTÁVEL !
  getParceirosActivos(state: State) {
    return state.parceiros.filter((value) => value.status.active);
  },
};

const mutations = {
  clearStore(state: State) {
    state.parceiros = [];
  },
};

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Parceiro } | { item: Parceiro[] } | Parceiro[];
}

const actions = {
  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerBase(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref ?? context.state.parceiros, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerLvl1(context: ActionContext<any, any>, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerLvl(payload.ref ?? context.state.parceiros, payload.query, "lvl1");
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  //* Guarda o parceiro na firebase.firestore
  async saveNewParceiro(state: any, parceiro: Parceiro) {
    let avatarRef = null;
    let uploadTask = null;

    try {
      const docBase = doc(collection(db, PARCEIRO_COLLECTION));

      //Reduz e upload a imagem do avatar
      if (parceiro.avatar) {
        const imgReduced = await compressImage(parceiro.avatar);
        //Se a imagem convertida for muito grande, throw um erro.
        if (imgReduced.size > 1 * 1000 * 1000) {
          throw `Avatar size is over 1MB, size:${imgReduced.size}`;
        }
        avatarRef = ref(storage, `parceiros/${docBase.id}/avatar`);
        uploadTask = await uploadBytes(avatarRef, imgReduced);
        parceiro.info.avatar = avatarRef.fullPath;
      }

      //Guarda o user na DB
      const batch = writeBatch(db);
      //Set Meta
      const utilizadorLogedIn = state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"];
      parceiro.meta = getMeta(utilizadorLogedIn);
      //minimiza dados a guardar na DB
      const parceiroMini = parceiro.minimized();

      const base = {
        info: parceiroMini.info,
        contactos: parceiroMini.contactos,
        status: parceiroMini.status,
      };
      const lvl1 = { meta: parceiroMini.meta, status: parceiroMini.status };

      //Set o doc
      batch.set(docBase, base);
      batch.set(doc(docBase, PARCEIRO_LVL1, docBase.id), lvl1);

      //Set histórico
      const historicoItem = new HistoricoItem();
      historicoItem.setCreated();
      historicoItem.meta = getMeta(state.rootGetters["utilizadorStore/getUtilizadorLoggedIn"]);
      batch.set(doc(collection(docBase, HISTORICO_COLLECTION)), historicoItem.minimized());

      //Eu não espero porque é sempre bem sucedido, mesmo offline.
      //Quando está offline nunca resolve a promessa, até ficar online
      batch.commit();
      return docBase.id;
    } catch (err) {
      console.log(err);
      //Se o avatar for uploaded, tem de ser eliminado.
      if (uploadTask) deleteObject(avatarRef);
      throw "Erro ao criar o parceiro";
    }
  },

  /**
   * Update o avatar do parceiro na firebase.firestore
   */
  async updateAvatarDB(_: any, payload: { parceiro: Parceiro; historico: Parceiro }) {
    //Reduz e upload a imagem do avatar
    const imgReduced = await compressImage(payload.parceiro.avatar);
    //Se a imagem convertida for muito grande, throw um erro.
    if (imgReduced.size > 1 * 1000 * 1000) {
      throw `Avatar size is over 1MB, size:${imgReduced.size}`;
    }
    const avatarRef = ref(storage, `parceiros/${payload.parceiro.id}/avatar`);
    await uploadBytes(avatarRef, imgReduced);
    payload.parceiro.info.avatar = avatarRef.fullPath;

    //Save path na DB
    dbOperations
      .setOp(payload.parceiro)
      .update({ base: ["info.avatar"] })
      .logLog("avatar")
      .setMeta()
      .commit();

    //Actualiza o parceiro com o novo avatar
    payload.parceiro.getAvatar();
  },

  /**
   * Update o nome do utilizador na firebase.firestore
   */
  async updateDadosDB(_: any, payload: { parceiro: Parceiro; historico: Parceiro }) {
    dbOperations
      .setOp(payload.parceiro)
      .update({ base: ["info.nome"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o nome do parceiro na firebase.firestore
   */
  async updateContactosDB(_: any, payload: { parceiro: Parceiro; historico: Parceiro }) {
    dbOperations
      .setOp(payload.parceiro)
      .update({ base: ["contactos.email", "contactos.telemovel"] })
      .log(payload.historico)
      .setMeta()
      .commit();
  },

  /**
   * Update o status na firebase.firestore
   */
  async updateStatusDB(_: any, payload: { parceiro: Parceiro; historico: Parceiro }) {
    dbOperations
      .setOp(payload.parceiro)
      .update({ base: ["status.active"] })
      .log(payload.historico)
      .update({ lvl1: ["status.active"] })
      .setMeta()
      .commit();
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, id: string) {
    const res: HistoricoItem[] = [];
    const q = collection(db, PARCEIRO_COLLECTION, id, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },

  /**
   * Elimina permanentemente o utilizador na firebase.firestore
   */
  async deleteParceiroDB(state: any, parceiro: Parceiro) {
    //Delete Avatar do user na storage
    deleteObject(ref(storage, `parceiros/${parceiro.id}/avatar`));

    //Delete parceiro na DB
    const docBase = doc(db, PARCEIRO_COLLECTION, parceiro.id);
    const batch = writeBatch(db);

    batch.delete(docBase);
    batch.delete(doc(docBase, PARCEIRO_LVL1, parceiro.id));

    const snapshotHistorico = await getDocs(query(collection(docBase, HISTORICO_COLLECTION)));
    snapshotHistorico.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();
  },
};

function compressImage(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxHeight: 512,
      maxWidth: 512,
      convertSize: 1 * 1000 * 1000,
      success: resolve,
      error: reject,
    });
  });
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

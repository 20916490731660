import Grupo from "@/modules/grupos/types/Grupo";
import {
  Query,
  collection,
  getDocs,
  query,
  orderBy,
  DocumentReference,
  where,
  collectionGroup,
} from "firebase/firestore";
import { cloneDeep } from "lodash";

import Cliente from "@/modules/clientes/types/Cliente";
import { db } from "@/common/services/firebase";
import DBOperations from "@/common/services/DBOperations";
import HistoricoItem from "@/common/components/historico/HistoricoItem";
import { CLIENTE_COLLECTION, HISTORICO_COLLECTION, CLIENTE_LVL1, CLIENTE_LVL2 } from "@/common/defs/collectionNames";

export interface State {
  clientes: Cliente[];
}

const state: State = {
  clientes: [],
};

export const dbOperations = new DBOperations("Cliente", {
  metaCollection: CLIENTE_LVL2,
  lvl1Name: CLIENTE_LVL1,
  lvl2Name: CLIENTE_LVL2,
});

const getters = {
  //* Retorna array dos clientes. ! NÃO É IMUTÁVEL !
  getClientes(state: State) {
    return state.clientes;
  },
  getClientesActivos(state: State) {
    return state.clientes.filter((cliente) => cliente.status.active);
  },
  getClienteByNif: (state: State) => (nif: string) => {
    return cloneDeep(state.clientes.find((cliente: Cliente) => cliente.info.nif === nif));
  },
  //* retorna uma DEEPCOPPY do utilizador com o ID solicitado.
  getCliente: (state: State) => (id: string) => {
    return cloneDeep(state.clientes.find((cliente: Cliente) => cliente.id === id));
  },
  isClienteDuplicado: (state: State) => (cliente: Cliente) => {
    if (cliente.info.nif) {
      const clienteFound = state.clientes.find((cli) => cli.info.nif === cliente.info.nif);
      if (clienteFound && clienteFound.id !== cliente.id) return true;
      else return false;
    } else {
      return false;
    }
  },
};

const mutations = {
  clearStore(state: State) {
    state.clientes = [];
  },
};

interface IGetPayload {
  query: Query | DocumentReference;
  ref?: { item: Cliente } | Cliente[];
}

interface IListenerPayload {
  query: Query | DocumentReference;
  ref?: { item: Cliente } | { item: Cliente[] } | Cliente[];
}

async function processLvlListener(context: any, payload: IListenerPayload, lvl: string) {
  const listener = await dbOperations.getListenerLvl(payload.ref ?? context.state.clientes, payload.query, lvl);
  context.commit("addListener", listener, { root: true });
  return listener;
}

const actions = {
  async loadClientes(context: any) {
    if (context.state.clientes.length === 0) {
      const grupo: Grupo = context.rootGetters["utilizadorStore/getGrupoUtilizadorLoggedIn"];

      const optionsBase = [];
      if (!grupo.permissoes.clientes.accessInactive) optionsBase.push(where("status.active", "==", true));
      const q = query(collection(db, CLIENTE_COLLECTION), ...optionsBase);
      const listenerBase = await context.dispatch("getListenerBase", { query: q });

      const querysLvls = [];
      if (grupo.permissoes.clientes.accessLvl1) {
        const optionsLvl = [];
        if (!grupo.permissoes.clientes.accessInactive) optionsLvl.push(where("status.active", "==", true));
        querysLvls.push(
          context.dispatch("getListenerLvl1", { query: query(collectionGroup(db, CLIENTE_LVL1), ...optionsLvl) })
        );
      }

      if (grupo.permissoes.clientes.accessLvl2) {
        const optionsLvl = [];
        if (!grupo.permissoes.clientes.accessInactive) optionsLvl.push(where("status.active", "==", true));
        querysLvls.push(
          context.dispatch("getListenerLvl2", { query: query(collectionGroup(db, CLIENTE_LVL2), ...optionsLvl) })
        );
      }
      const res = await Promise.all(querysLvls);
      context.commit("addListener", listenerBase, { root: true });
      context.commit("addListener", res[0], { root: true });
      context.commit("addListener", res[1], { root: true });
    }
  },

  /**
   * Get de uma só vez os documentos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsBase(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsBase(payload.ref, payload.query);
  },

  /**
   * Get de uma só vez os LVL1 dos documetos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsLvl1(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsLvl(payload.ref, payload.query, "lvl1");
  },

  /**
   * Get de uma só vez os LVL2 dos documetos da base de dados, NÃO fica atento a mudanças
   */
  async getDocsLvl2(context: any, payload: IGetPayload) {
    return await dbOperations.getItemsLvl(payload.ref, payload.query, "lvl2");
  },

  /**
   * Get um listener para a query, fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerBase(context: any, payload: IListenerPayload) {
    const listener = await dbOperations.getListenerBase(payload.ref ?? context.state.clientes, payload.query);
    context.commit("addListener", listener, { root: true });
    return listener;
  },

  /**
   * Get um listener para a query (GroupCollection), fica atento as mudanças dos items na base de dados e guarda-os na store.
   */
  async getListenerLvl1(context: any, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl1");
  },

  async getListenerLvl2(context: any, payload: IListenerPayload) {
    return await processLvlListener(context, payload, "lvl2");
  },

  /**
   * get o historico do item que está no firestore
   */
  async getHistorico(state: any, id: string) {
    const res: HistoricoItem[] = [];
    const q = collection(db, CLIENTE_COLLECTION, id, HISTORICO_COLLECTION);
    const historico = await getDocs(query(q, orderBy("meta.createdAt", "desc")));
    historico.forEach((doc: any) => {
      res.push(new HistoricoItem(doc.id, doc.data()));
    });
    return res;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

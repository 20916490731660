import { getDocs, getDoc, WriteBatch } from "firebase/firestore";
import { IfirestoreObject } from "@/common/services/IfirestoreObject";
import Cliente from "@/modules/clientes/types/Cliente";
import Grupo from "@/modules/grupos/types/Grupo";
import Morada from "@/modules/moradas/types/Morada";
import Parceiro from "@/modules/parceiros/types/Parceiro";
import Servico from "@/modules/servicos/types/Servico";
import Utilizador from "@/modules/utilizadores/types/Utilizador";
import Veiculo from "@/modules/veiculos/types/Veiculo";
import Pagamento from "@/modules/creditos/types/Pagamento";
import Consulta from "@/modules/creditos/types/Consulta";
import Turno from "@/modules/turnos/types/Turno";
import {
  Query,
  QuerySnapshot,
  DocumentSnapshot,
  DocumentReference,
  onSnapshot,
  FirestoreError,
  Unsubscribe,
} from "firebase/firestore";
import DbSetOperation from "./DbSetOperation";
import ClosingTill from "@/modules/turnos/types/ClosingTill";

// construct dict object that contains our mapping between strings and classes
const classesMap: { [Key: string]: any } = {
  Servico: Servico,
  Utilizador: Utilizador,
  Cliente: Cliente,
  Consulta: Consulta,
  Pagamento: Pagamento,
  Veiculo: Veiculo,
  Parceiro: Parceiro,
  Morada: Morada,
  Grupo: Grupo,
  Turno: Turno,
  ClosingTill: ClosingTill,
};

export default class DBOperations {
  private classPrototype: any;
  private collectionLvl1Name: string;
  private collectionLvl2Name: string;
  private collectionStatsName: string;
  private metaCollection: string;

  constructor(
    className: string,
    names: { metaCollection: string; lvl1Name?: string; lvl2Name?: string; statsName?: string }
  ) {
    this.classPrototype = classesMap[className];
    this.metaCollection = names.metaCollection;
    this.collectionLvl1Name = names.lvl1Name;
    this.collectionLvl2Name = names.lvl2Name;
    this.collectionStatsName = names.statsName;
  }

  setOp(object: IfirestoreObject, batch?: WriteBatch) {
    return new DbSetOperation(
      object,
      batch,
      this.metaCollection,
      this.collectionLvl1Name,
      this.collectionLvl2Name,
      this.collectionStatsName
    );
  }

  // ====================================================================================================================

  /**
   * Get de uma só vez os documentos da base de dados
   * @param ref um array de items, onde guarda os items
   * @param query query a efectuar na base de dados
   * @returns Promise<Void> quando todos os documentos estiverem processados
   */
  async getItemsBase(ref: { item: IfirestoreObject } | IfirestoreObject[], query: Query | DocumentReference) {
    //Se for só um documento
    if (query instanceof DocumentReference) {
      const dbItem = await getDoc(query);
      const refOndeGuardar = ref as { item: IfirestoreObject };
      //Se o item ainda não existir, cria um
      if (!refOndeGuardar.item) refOndeGuardar.item = new this.classPrototype(dbItem.id);
      refOndeGuardar.item.setData(dbItem.data());
    } else {
      //Se for varios documentos recebe um array
      const dbItems = await getDocs(query);
      const refOndeGuardar = ref as IfirestoreObject[];
      dbItems.forEach((dbItem) => {
        const foundItem = refOndeGuardar.find((item: IfirestoreObject) => item.id === dbItem.id);
        //Se o documento já existir então apenas actualiza os dados, caso contrário cria um novo a faz push
        if (!foundItem) {
          this.addItem(refOndeGuardar, dbItem.id, dbItem.data());
        } else {
          foundItem.setData(dbItem.data());
        }
      });
    }
  }

  /**
   * Get de uma só vez os LVLS do documento na base de dados
   * @param ref um array de items, onde guarda os items
   * @param query query a efectuar na base de dados
   * @returns Promise<Void> quando todos os documentos estiverem processados
   */
  async getItemsLvl(
    ref: { item: IfirestoreObject } | IfirestoreObject[],
    query: Query | DocumentReference,
    lvlName: string
  ) {
    if (query instanceof DocumentReference) {
      const dbItem = await getDoc(query);
      const refOndeGuardar = ref as { item: IfirestoreObject };
      //Se o item ainda não existir, cria um
      if (!refOndeGuardar.item) refOndeGuardar.item = new this.classPrototype(dbItem.id);
      if (lvlName === "lvl1") refOndeGuardar.item.setLvl1(dbItem.data());
      else if (lvlName === "lvl2") refOndeGuardar.item.setLvl2(dbItem.data());
      else if (lvlName === "stats") refOndeGuardar.item.setStats(dbItem.data());
    } else {
      const dbItems = await getDocs(query);
      const refOndeGuardar = ref as IfirestoreObject[];
      dbItems.forEach((dbItem) => {
        const foundItem = refOndeGuardar.find((item: IfirestoreObject) => item.id === dbItem.id);
        if (!foundItem) {
          const newItem = new this.classPrototype(dbItem.id);
          if (lvlName === "lvl1") newItem.setLvl1(dbItem.data());
          else if (lvlName === "lvl2") newItem.setLvl2(dbItem.data());
          else if (lvlName === "stats") newItem.setStats(dbItem.data());
          refOndeGuardar.push(newItem);
        } else {
          if (lvlName === "lvl1") foundItem.setLvl1(dbItem.data());
          else if (lvlName === "lvl2") foundItem.setLvl2(dbItem.data());
          else if (lvlName === "stats") foundItem.setStats(dbItem.data());
        }
      });
    }
  }

  /**
   * Subscreve o listener às alteraçãos do documento base na base de dados
   * @param ref recebe uma ref a um item ou um array de items, onde guarda as alterações
   * @param query query a efectuar na base de dados
   * @returns Promise<Unsubscribe> quando todas as alterações forem efectuadas na primeira vez que o listener é subscrito
   */
  getListenerBase(
    ref: { item: IfirestoreObject } | { item: IfirestoreObject[] } | IfirestoreObject[],
    query: Query | DocumentReference
  ) {
    return new Promise<Unsubscribe>((resolve, reject) => {
      let listener: Unsubscribe;

      //Recebe uma lista de changes, por cada change, processa, e guarda no array que recebeu
      const handleChanges = (col: QuerySnapshot) => {
        //Se for so o array usa o array se for um array encapsulado num objecto retorna o item
        const refOndeGuardar = (ref as any).item ?? ref;
        this.processBaseChanges(col, refOndeGuardar as IfirestoreObject[]);
        resolve(listener);
      };

      //Só recebe um doc, e guarda-o na ref
      //Será chamado sempre que houver uma mudança no item na base de dados
      //Logo guarda o item numa ref para poder ser actualizado
      const handleChangesSingle = (doc: DocumentSnapshot) => {
        const refOndeGuardar = ref as { item: IfirestoreObject };
        if (!refOndeGuardar.item) refOndeGuardar.item = new this.classPrototype(doc.id);
        //Verifico se o doc existe porque quando é deleted isto é chamado
        if (doc.exists()) refOndeGuardar.item.setData(doc.data());
        resolve(listener);
      };

      //Handle error
      const handleError = (err: FirestoreError) => {
        //Reject does nothing... because handleChanges is always called before
        console.log(err.message);
        reject(err);
      };
      //Subscribe to changes
      if (query instanceof DocumentReference) {
        listener = onSnapshot(query, handleChangesSingle, handleError);
      } else {
        listener = onSnapshot(query, handleChanges, handleError);
      }
    });
  }

  /**
   * Subscreve o listener as alteraçãos dos LVLS do documento na base de dados
   * @param context contexto da vuex store
   * @param query GROUP query a efectuar na base de dados, get todos os Lvls de todas as subcoleções de uma só vez
   * @returns Promise<void> quando todas as alterações forem efectuadas na primeira vez que o listener é subscrito
   */
  getListenerLvl(
    ref: { item: IfirestoreObject } | { item: IfirestoreObject[] } | IfirestoreObject[],
    query: Query | DocumentReference,
    lvlName: string
  ) {
    return new Promise<Unsubscribe>((resolve, reject) => {
      let listener: Unsubscribe;
      //Recebe uma lista de changes, por cada change, processa, e guarda no array que recebeu
      const handleChanges = (col: QuerySnapshot) => {
        const refOndeGuardar = (ref as any).item ?? ref;
        col.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            if (lvlName === "lvl1")
              this.updateLvl1(refOndeGuardar as IfirestoreObject[], change.doc.id, change.doc.data());
            else if (lvlName === "lvl2")
              this.updateLvl2(refOndeGuardar as IfirestoreObject[], change.doc.id, change.doc.data());
            else if (lvlName === "stats") {
              this.updateStats(refOndeGuardar as IfirestoreObject[], change.doc.id, change.doc.data());
            }
          }
        });
        resolve(listener);
      };

      //Só recebe um doc, e guarda-o na ref
      //Será chamado sempre que houver uma mudança no item na base de dados
      //Logo guarda o item numa ref para poder se actualizado
      const handleChangesSingle = (doc: DocumentSnapshot) => {
        const refOndeGuardar = ref as { item: IfirestoreObject };
        if (!refOndeGuardar.item) refOndeGuardar.item = new this.classPrototype(doc.id);
        if (lvlName === "lvl1") refOndeGuardar.item.setLvl1(doc.data());
        else if (lvlName === "lvl2") refOndeGuardar.item.setLvl2(doc.data());
        else if (lvlName === "stats") refOndeGuardar.item.setStats(doc.data());
        resolve(listener);
      };

      const handleError = (err: FirestoreError) => {
        //Reject does nothing... because handleChanges is always called before
        console.error(err.message);
        reject(err);
      };
      //Subscribe to changes
      if (query instanceof DocumentReference) {
        listener = onSnapshot(query, handleChangesSingle, handleError);
      } else {
        listener = onSnapshot(query, handleChanges, handleError);
      }
    });
  }

  /**
   * Processa as alterações base dos items
   */
  private processBaseChanges(col: QuerySnapshot, items: IfirestoreObject[]) {
    col.docChanges().forEach((change) => {
      switch (change.type) {
        case "added":
          this.addItem(items, change.doc.id, change.doc.data());
          break;
        case "modified":
          this.updateBase(items, change.doc.id, change.doc.data());
          break;
        case "removed":
          this.removeItem(items, change.doc.id);
          break;
      }
    });
  }

  /**
   * Adiciona item a lista da store
   */
  private addItem(items: IfirestoreObject[], id: string, data: any) {
    const item = new this.classPrototype(id, data);
    items.push(item);
  }

  /**
   * Remove item a lista da store
   */
  private removeItem(items: IfirestoreObject[], id: string) {
    const itemIndex = items.findIndex((item) => item.id === id);
    items.splice(itemIndex, 1);
  }

  /**
   * Update a base do item na lista da store
   */
  private updateBase(items: IfirestoreObject[], id: string, data: any) {
    items.find((item: IfirestoreObject) => item.id === id).setData(data);
  }

  /**
   * Update o Lvl1 do item na lista da store
   */
  private updateLvl1(items: IfirestoreObject[], id: string, data: any) {
    const item = items.find((item: IfirestoreObject) => item.id === id);
    if (item) item.setLvl1(data);
  }

  /**
   * Update o Lvl2 do item na lista da store
   */
  private updateLvl2(items: IfirestoreObject[], id: string, data: any) {
    const item = items.find((item: IfirestoreObject) => item.id === id);
    if (item) item.setLvl2(data);
  }

  /**
   * Update o stats do item na lista da store
   */
  private updateStats(items: IfirestoreObject[], id: string, data: any) {
    const item = items.find((item: IfirestoreObject) => item.id === id);
    if (item) item.setStats(data);
  }
}
